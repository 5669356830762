@charset "UTF-8";
/*
  Gradients generated with http://www.colorzilla.com/gradient-editor/
  Box shadows generated with http://www.melanieceraso.com/psd-to-css3/
*/
/**
 * Bootstrap
 *
 */
/**
 * Colors
 *
 */
/* Colors from UX 4.0 */
/* @TODO Check validity. Might be a top-left to bottom-right gradient.  */
.color-action-primary {
  color: #FF9006; }

.bg-action-primary {
  background-color: #FF9006; }

.color-action-primary__light {
  color: #FFF1E4; }

.bg-action-primary__light {
  background-color: #FFF1E4; }

.color-action-secondary {
  color: #337AB7; }

.bg-action-secondary {
  background-color: #337AB7; }

.color-action-secondary__light {
  color: #F1F5FD; }

.bg-action-secondary__light {
  background-color: #F1F5FD; }

.color-body-text {
  color: #555555; }

.bg-body-text {
  background-color: #555555; }

.color-body-text__light {
  color: #999999; }

.bg-body-text__light {
  background-color: #999999; }

.color-body-background {
  color: #FFFDFD; }

.bg-body-background {
  background-color: #FFFDFD; }

.color-status-green {
  color: #00A572; }

.bg-status-green {
  background-color: #00A572; }

.color-status-green__light {
  color: #DEFFF5; }

.bg-status-green__light {
  background-color: #DEFFF5; }

.color-status-blue {
  color: #1EB9EC; }

.bg-status-blue {
  background-color: #1EB9EC; }

.color-status-blue__light {
  color: #CCF8FF; }

.bg-status-blue__light {
  background-color: #CCF8FF; }

.color-status-red {
  color: #FF5E58; }

.bg-status-red {
  background-color: #FF5E58; }

.color-status-red__light {
  color: #FFEDED; }

.bg-status-red__light {
  background-color: #FFEDED; }

.color-status-yellow {
  color: #FCAD00; }

.bg-status-yellow {
  background-color: #FCAD00; }

.color-status-yellow__light {
  color: #FFF4CE; }

.bg-status-yellow__light {
  background-color: #FFF4CE; }

.color-status-gray {
  color: #555555; }

.bg-status-gray {
  background-color: #555555; }

.color-status-gray__light {
  color: #EEEEEE; }

.bg-status-gray__light {
  background-color: #EEEEEE; }

.bg-body-background__gradient {
  background: linear-gradient(304.47deg, #62B2DC 23.83%, #337AB7 59.85%);
  /* Linear-gradient has to be used with 'background' property. */ }

/**
 * Elements
 *
 */
/*$header-height: $top-nav-height + $top-nav-border-width + $brand-wrapper-height; // @DEPRECATED Header V1*/
/**
 * components/_forms
 *
 */
/**
 * Login - membogo-auth-server
 *
 */
/**
 * z-index
 *
 */
/* Last z-index was 9997. March 13, 2018. */
/**
 * Typography
 *
 */
/* @DEPRECATED */
/* @DEPRECATED */
/* @TODO Only used in alert. To be removed. */
/**
 * Elements dependant to Typography.
 * @description These elements depend on variables related to typography. They must be declared after the latter.
 *
 */
.col-auto, .mgo-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left; }

.body {
  position: relative; }
  .app__header ~ .body {
    padding-top: 106px; }
  .app-header__admin ~ .body {
    padding-top: 50px; }

.row.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.row [class*='col-'] .has-help-right input,
.row [class*='col-'] .has-help-right select {
  width: auto; }

.col-auto {
  width: auto; }

.mgo-xs-12 {
  width: 100%; }

.general-padding {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.brand-logo {
  background: url(../images/yapla-logo-orange.svg) no-repeat center center;
  width: 108.56px;
  height: 34px;
  background-size: cover;
  display: inline-block;
  text-indent: -9999px;
  overflow: hidden; }
  .brand-logo:lang(en) {
    background-image: url(../images/membogo-logo-TM.svg); }

.o-layout-wrapper .o-layout-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: -13px;
  /* wrap protection */
  margin-left: -5px;
  margin-right: -5px; }
  .o-layout-wrapper .o-layout-row.o-layout-row--alignTop {
    align-items: flex-start; }
  .o-layout-wrapper .o-layout-row .o-layout-item {
    margin-top: 13px;
    padding-left: 5px;
    padding-right: 5px; }

.o-layout-item__form {
  max-width: calc(100% - 400px);
  /* @TODO à raffiner. mandat FMSQ. */
  flex-grow: 1; }
  .o-layout-item__form .col-xs-6 {
    /* @TODO à raffiner. mandat FMSQ. */
    width: 100%; }
    .o-layout-item__form .col-xs-6:last-child {
      margin-top: 30px; }
  .o-layout-item__form .form-control-static {
    /* @TODO à raffiner. mandat FMSQ. */
    min-height: 13px;
    line-height: 13px; }

.o-layout-item__list-group {
  margin-left: auto; }

@-ms-viewport {
  width: auto !important; }

@font-face {
  font-family: 'fontello_iconic';
  src: url(../fonts/fontello/fontello_iconic.eot?47393967);
  src: url(../fonts/fontello/fontello_iconic.eot?47393967#iefix) format("embedded-opentype"), url(../fonts/fontello/fontello_iconic.woff?47393967) format("woff"), url(../fonts/fontello/fontello_iconic.ttf?47393967) format("truetype"), url(../fonts/fontello/fontello_iconic.svg?47393967#fontello_iconic) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello_iconic';
    src: url('../font/fontello_iconic.svg?47393967#fontello_iconic') format('svg');
  }
}
*/
.iconic-icon:before, .btn.btn-edit::before, .btn-group.btn-group__primary > .btn-edit.dropdown-toggle::before, .btn.btn-settings::before, .btn-group.btn-group__primary > .btn-settings.dropdown-toggle::before, .dropzone .dz-preview .dz-remove:before {
  font-family: "fontello_iconic";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.iconic-icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .iconic-icon.icon-search:before {
    content: '\e800'; }
  .iconic-icon.icon-mail:before {
    content: '\e801'; }
  .iconic-icon.icon-heart:before {
    content: '\e802'; }
  .iconic-icon.icon-heart-empty:before {
    content: '\e803'; }
  .iconic-icon.icon-star:before {
    content: '\e804'; }
  .iconic-icon.icon-user:before {
    content: '\e805'; }
  .iconic-icon.icon-video:before {
    content: '\e806'; }
  .iconic-icon.icon-picture:before {
    content: '\e807'; }
  .iconic-icon.icon-camera:before {
    content: '\e808'; }
  .iconic-icon.icon-ok:before {
    content: '\e809'; }
  .iconic-icon.icon-ok-circle:before {
    content: '\e80a'; }
  .iconic-icon.icon-cancel:before {
    content: '\e80b'; }
  .iconic-icon.icon-cancel-circle:before {
    content: '\e80c'; }
  .iconic-icon.icon-plus:before {
    content: '\e80d'; }
  .iconic-icon.icon-plus-circle:before {
    content: '\e80e'; }
  .iconic-icon.icon-minus:before {
    content: '\e80f'; }
  .iconic-icon.icon-minus-circle:before {
    content: '\e810'; }
  .iconic-icon.icon-help:before {
    content: '\e811'; }
  .iconic-icon.icon-info:before {
    content: '\e812'; }
  .iconic-icon.icon-home:before {
    content: '\e813'; }
  .iconic-icon.icon-link:before {
    content: '\e814'; }
  .iconic-icon.icon-attach:before {
    content: '\e815'; }
  .iconic-icon.icon-lock:before {
    content: '\e816'; }
  .iconic-icon.icon-lock-empty:before {
    content: '\e817'; }
  .iconic-icon.icon-lock-open:before {
    content: '\e818'; }
  .iconic-icon.icon-lock-open-empty:before {
    content: '\e819'; }
  .iconic-icon.icon-pin:before {
    content: '\e81a'; }
  .iconic-icon.icon-eye:before {
    content: '\e81b'; }
  .iconic-icon.icon-tag:before {
    content: '\e81c'; }
  .iconic-icon.icon-tag-empty:before {
    content: '\e81d'; }
  .iconic-icon.icon-download:before {
    content: '\e81e'; }
  .iconic-icon.icon-upload:before {
    content: '\e81f'; }
  .iconic-icon.icon-download-cloud:before {
    content: '\e820'; }
  .iconic-icon.icon-upload-cloud:before {
    content: '\e821'; }
  .iconic-icon.icon-quote-left:before {
    content: '\e822'; }
  .iconic-icon.icon-quote-right:before {
    content: '\e823'; }
  .iconic-icon.icon-quote-left-alt:before {
    content: '\e824'; }
  .iconic-icon.icon-quote-right-alt:before {
    content: '\e825'; }
  .iconic-icon.icon-pencil:before {
    content: '\e826'; }
  .iconic-icon.icon-pencil-neg:before {
    content: '\e827'; }
  .iconic-icon.icon-pencil-alt:before {
    content: '\e828'; }
  .iconic-icon.icon-undo:before {
    content: '\e829'; }
  .iconic-icon.icon-comment:before {
    content: '\e82a'; }
  .iconic-icon.icon-comment-inv:before {
    content: '\e82b'; }
  .iconic-icon.icon-comment-alt:before {
    content: '\e82c'; }
  .iconic-icon.icon-comment-inv-alt:before {
    content: '\e82d'; }
  .iconic-icon.icon-comment-alt2:before {
    content: '\e82e'; }
  .iconic-icon.icon-comment-inv-alt2:before {
    content: '\e82f'; }
  .iconic-icon.icon-chat:before {
    content: '\e830'; }
  .iconic-icon.icon-chat-inv:before {
    content: '\e831'; }
  .iconic-icon.icon-location:before {
    content: '\e832'; }
  .iconic-icon.icon-location-inv:before {
    content: '\e833'; }
  .iconic-icon.icon-location-alt:before {
    content: '\e834'; }
  .iconic-icon.icon-compass:before {
    content: '\e835'; }
  .iconic-icon.icon-trash:before {
    content: '\e836'; }
  .iconic-icon.icon-trash-empty:before {
    content: '\e837'; }
  .iconic-icon.icon-doc:before {
    content: '\e838'; }
  .iconic-icon.icon-doc-inv:before {
    content: '\e839'; }
  .iconic-icon.icon-doc-alt:before {
    content: '\e83a'; }
  .iconic-icon.icon-doc-inv-alt:before {
    content: '\e83b'; }
  .iconic-icon.icon-article:before {
    content: '\e83c'; }
  .iconic-icon.icon-article-alt:before {
    content: '\e83d'; }
  .iconic-icon.icon-book-open:before {
    content: '\e83e'; }
  .iconic-icon.icon-folder:before {
    content: '\e83f'; }
  .iconic-icon.icon-folder-empty:before {
    content: '\e840'; }
  .iconic-icon.icon-box:before {
    content: '\e841'; }
  .iconic-icon.icon-rss:before {
    content: '\e842'; }
  .iconic-icon.icon-rss-alt:before {
    content: '\e843'; }
  .iconic-icon.icon-cog:before {
    content: '\e844'; }
  .iconic-icon.icon-wrench:before {
    content: '\e845'; }
  .iconic-icon.icon-share:before {
    content: '\e846'; }
  .iconic-icon.icon-calendar:before {
    content: '\e847'; }
  .iconic-icon.icon-calendar-inv:before {
    content: '\e848'; }
  .iconic-icon.icon-calendar-alt:before {
    content: '\e849'; }
  .iconic-icon.icon-mic:before {
    content: '\e84a'; }
  .iconic-icon.icon-volume-off:before {
    content: '\e84b'; }
  .iconic-icon.icon-volume-up:before {
    content: '\e84c'; }
  .iconic-icon.icon-headphones:before {
    content: '\e84d'; }
  .iconic-icon.icon-clock:before {
    content: '\e84e'; }
  .iconic-icon.icon-lamp:before {
    content: '\e84f'; }
  .iconic-icon.icon-block:before {
    content: '\e850'; }
  .iconic-icon.icon-resize-full:before {
    content: '\e851'; }
  .iconic-icon.icon-resize-full-alt:before {
    content: '\e852'; }
  .iconic-icon.icon-resize-small:before {
    content: '\e853'; }
  .iconic-icon.icon-resize-small-alt:before {
    content: '\e854'; }
  .iconic-icon.icon-resize-vertical:before {
    content: '\e855'; }
  .iconic-icon.icon-resize-horizontal:before {
    content: '\e856'; }
  .iconic-icon.icon-move:before {
    content: '\e857'; }
  .iconic-icon.icon-popup:before {
    content: '\e858'; }
  .iconic-icon.icon-down:before {
    content: '\e859'; }
  .iconic-icon.icon-left:before {
    content: '\e85a'; }
  .iconic-icon.icon-right:before {
    content: '\e85b'; }
  .iconic-icon.icon-up:before {
    content: '\e85c'; }
  .iconic-icon.icon-down-circle:before {
    content: '\e85d'; }
  .iconic-icon.icon-left-circle:before {
    content: '\e85e'; }
  .iconic-icon.icon-right-circle:before {
    content: '\e85f'; }
  .iconic-icon.icon-up-circle:before {
    content: '\e860'; }
  .iconic-icon.icon-cw:before {
    content: '\e861'; }
  .iconic-icon.icon-loop:before {
    content: '\e862'; }
  .iconic-icon.icon-loop-alt:before {
    content: '\e863'; }
  .iconic-icon.icon-exchange:before {
    content: '\e864'; }
  .iconic-icon.icon-split:before {
    content: '\e865'; }
  .iconic-icon.icon-arrow-curved:before {
    content: '\e866'; }
  .iconic-icon.icon-play:before {
    content: '\e867'; }
  .iconic-icon.icon-play-circle2:before {
    content: '\e868'; }
  .iconic-icon.icon-stop:before {
    content: '\e869'; }
  .iconic-icon.icon-pause:before {
    content: '\e86a'; }
  .iconic-icon.icon-to-start:before, .paginationControl .iconic-icon.first:before {
    content: '\e86b'; }
  .iconic-icon.icon-to-end:before, .paginationControl .iconic-icon.last:before {
    content: '\e86c'; }
  .iconic-icon.icon-eject:before {
    content: '\e86d'; }
  .iconic-icon.icon-target:before {
    content: '\e86e'; }
  .iconic-icon.icon-signal:before {
    content: '\e86f'; }
  .iconic-icon.icon-award:before {
    content: '\e870'; }
  .iconic-icon.icon-award-empty:before {
    content: '\e871'; }
  .iconic-icon.icon-list:before {
    content: '\e872'; }
  .iconic-icon.icon-list-nested:before {
    content: '\e873'; }
  .iconic-icon.icon-bat-empty:before {
    content: '\e874'; }
  .iconic-icon.icon-bat-half:before {
    content: '\e875'; }
  .iconic-icon.icon-bat-full:before {
    content: '\e876'; }
  .iconic-icon.icon-bat-charge:before {
    content: '\e877'; }
  .iconic-icon.icon-mobile:before {
    content: '\e878'; }
  .iconic-icon.icon-cd:before {
    content: '\e879'; }
  .iconic-icon.icon-equalizer:before {
    content: '\e87a'; }
  .iconic-icon.icon-cursor:before {
    content: '\e87b'; }
  .iconic-icon.icon-aperture:before {
    content: '\e87c'; }
  .iconic-icon.icon-aperture-alt:before {
    content: '\e87d'; }
  .iconic-icon.icon-steering-wheel:before {
    content: '\e87e'; }
  .iconic-icon.icon-book:before {
    content: '\e87f'; }
  .iconic-icon.icon-book-alt:before {
    content: '\e880'; }
  .iconic-icon.icon-brush:before {
    content: '\e881'; }
  .iconic-icon.icon-brush-alt:before {
    content: '\e882'; }
  .iconic-icon.icon-eyedropper:before {
    content: '\e883'; }
  .iconic-icon.icon-layers:before {
    content: '\e884'; }
  .iconic-icon.icon-layers-alt:before {
    content: '\e885'; }
  .iconic-icon.icon-sun:before {
    content: '\e886'; }
  .iconic-icon.icon-sun-inv:before {
    content: '\e887'; }
  .iconic-icon.icon-cloud:before {
    content: '\e888'; }
  .iconic-icon.icon-rain:before {
    content: '\e889'; }
  .iconic-icon.icon-flash:before {
    content: '\e88a'; }
  .iconic-icon.icon-moon:before {
    content: '\e88b'; }
  .iconic-icon.icon-moon-inv:before {
    content: '\e88c'; }
  .iconic-icon.icon-umbrella:before {
    content: '\e88d'; }
  .iconic-icon.icon-chart-bar:before {
    content: '\e88e'; }
  .iconic-icon.icon-chart-pie:before {
    content: '\e88f'; }
  .iconic-icon.icon-chart-pie-alt:before {
    content: '\e890'; }
  .iconic-icon.icon-key:before {
    content: '\e891'; }
  .iconic-icon.icon-key-inv:before {
    content: '\e892'; }
  .iconic-icon.icon-hash:before {
    content: '\e893'; }
  .iconic-icon.icon-at:before {
    content: '\e894'; }
  .iconic-icon.icon-pilcrow:before {
    content: '\e895'; }
  .iconic-icon.icon-dial:before {
    content: '\e896'; }

@font-face {
  font-family: 'fontello_fontawesome';
  src: url(../fonts/fontello/fontello_fontawesome.eot?87790064);
  src: url(../fonts/fontello/fontello_fontawesome.eot?87790064#iefix) format("embedded-opentype"), url(../fonts/fontello/fontello_fontawesome.woff?87790064) format("woff"), url(../fonts/fontello/fontello_fontawesome.ttf?87790064) format("truetype"), url(../fonts/fontello/fontello_fontawesome.svg?87790064#fontello_fontawesome) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello_fontawesome';
    src: url('../font/fontello_fontawesome.svg?31361936#fontello_fontawesome') format('svg');
  }
}
*/
.fontawesome-icon:before, .paginationControl .first:before,
.paginationControl .previous:before,
.paginationControl .next:before,
.paginationControl .last:before, .widget-shortcuts__action-start::after {
  font-family: "fontello_fontawesome";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.fontawesome-icon, .paginationControl .first,
.paginationControl .previous,
.paginationControl .next,
.paginationControl .last {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .fontawesome-icon.icon-glass:before, .paginationControl .icon-glass.first:before,
  .paginationControl .icon-glass.previous:before,
  .paginationControl .icon-glass.next:before,
  .paginationControl .icon-glass.last:before {
    content: '\e800'; }
  .fontawesome-icon.icon-music:before, .paginationControl .icon-music.first:before,
  .paginationControl .icon-music.previous:before,
  .paginationControl .icon-music.next:before,
  .paginationControl .icon-music.last:before {
    content: '\e801'; }
  .fontawesome-icon.icon-search:before, .paginationControl .icon-search.first:before,
  .paginationControl .icon-search.previous:before,
  .paginationControl .icon-search.next:before,
  .paginationControl .icon-search.last:before {
    content: '\e802'; }
  .fontawesome-icon.icon-mail:before, .paginationControl .icon-mail.first:before,
  .paginationControl .icon-mail.previous:before,
  .paginationControl .icon-mail.next:before,
  .paginationControl .icon-mail.last:before {
    content: '\e803'; }
  .fontawesome-icon.icon-mail-alt:before, .paginationControl .icon-mail-alt.first:before,
  .paginationControl .icon-mail-alt.previous:before,
  .paginationControl .icon-mail-alt.next:before,
  .paginationControl .icon-mail-alt.last:before {
    content: '\e804'; }
  .fontawesome-icon.icon-mail-squared:before, .paginationControl .icon-mail-squared.first:before,
  .paginationControl .icon-mail-squared.previous:before,
  .paginationControl .icon-mail-squared.next:before,
  .paginationControl .icon-mail-squared.last:before {
    content: '\e805'; }
  .fontawesome-icon.icon-heart:before, .paginationControl .icon-heart.first:before,
  .paginationControl .icon-heart.previous:before,
  .paginationControl .icon-heart.next:before,
  .paginationControl .icon-heart.last:before {
    content: '\e806'; }
  .fontawesome-icon.icon-heart-empty:before, .paginationControl .icon-heart-empty.first:before,
  .paginationControl .icon-heart-empty.previous:before,
  .paginationControl .icon-heart-empty.next:before,
  .paginationControl .icon-heart-empty.last:before {
    content: '\e807'; }
  .fontawesome-icon.icon-star:before, .paginationControl .icon-star.first:before,
  .paginationControl .icon-star.previous:before,
  .paginationControl .icon-star.next:before,
  .paginationControl .icon-star.last:before {
    content: '\e808'; }
  .fontawesome-icon.icon-star-empty:before, .paginationControl .icon-star-empty.first:before,
  .paginationControl .icon-star-empty.previous:before,
  .paginationControl .icon-star-empty.next:before,
  .paginationControl .icon-star-empty.last:before {
    content: '\e809'; }
  .fontawesome-icon.icon-star-half:before, .paginationControl .icon-star-half.first:before,
  .paginationControl .icon-star-half.previous:before,
  .paginationControl .icon-star-half.next:before,
  .paginationControl .icon-star-half.last:before {
    content: '\e80a'; }
  .fontawesome-icon.icon-star-half-alt:before, .paginationControl .icon-star-half-alt.first:before,
  .paginationControl .icon-star-half-alt.previous:before,
  .paginationControl .icon-star-half-alt.next:before,
  .paginationControl .icon-star-half-alt.last:before {
    content: '\e80b'; }
  .fontawesome-icon.icon-user:before, .paginationControl .icon-user.first:before,
  .paginationControl .icon-user.previous:before,
  .paginationControl .icon-user.next:before,
  .paginationControl .icon-user.last:before {
    content: '\e80c'; }
  .fontawesome-icon.icon-user-plus:before, .paginationControl .icon-user-plus.first:before,
  .paginationControl .icon-user-plus.previous:before,
  .paginationControl .icon-user-plus.next:before,
  .paginationControl .icon-user-plus.last:before {
    content: '\e80d'; }
  .fontawesome-icon.icon-video:before, .paginationControl .icon-video.first:before,
  .paginationControl .icon-video.previous:before,
  .paginationControl .icon-video.next:before,
  .paginationControl .icon-video.last:before {
    content: '\e80e'; }
  .fontawesome-icon.icon-videocam:before, .paginationControl .icon-videocam.first:before,
  .paginationControl .icon-videocam.previous:before,
  .paginationControl .icon-videocam.next:before,
  .paginationControl .icon-videocam.last:before {
    content: '\e80f'; }
  .fontawesome-icon.icon-picture:before, .paginationControl .icon-picture.first:before,
  .paginationControl .icon-picture.previous:before,
  .paginationControl .icon-picture.next:before,
  .paginationControl .icon-picture.last:before {
    content: '\e810'; }
  .fontawesome-icon.icon-camera:before, .paginationControl .icon-camera.first:before,
  .paginationControl .icon-camera.previous:before,
  .paginationControl .icon-camera.next:before,
  .paginationControl .icon-camera.last:before {
    content: '\e811'; }
  .fontawesome-icon.icon-camera-alt:before, .paginationControl .icon-camera-alt.first:before,
  .paginationControl .icon-camera-alt.previous:before,
  .paginationControl .icon-camera-alt.next:before,
  .paginationControl .icon-camera-alt.last:before {
    content: '\e812'; }
  .fontawesome-icon.icon-th-large:before, .paginationControl .icon-th-large.first:before,
  .paginationControl .icon-th-large.previous:before,
  .paginationControl .icon-th-large.next:before,
  .paginationControl .icon-th-large.last:before {
    content: '\e813'; }
  .fontawesome-icon.icon-th:before, .paginationControl .icon-th.first:before,
  .paginationControl .icon-th.previous:before,
  .paginationControl .icon-th.next:before,
  .paginationControl .icon-th.last:before {
    content: '\e814'; }
  .fontawesome-icon.icon-th-list:before, .paginationControl .icon-th-list.first:before,
  .paginationControl .icon-th-list.previous:before,
  .paginationControl .icon-th-list.next:before,
  .paginationControl .icon-th-list.last:before {
    content: '\e815'; }
  .fontawesome-icon.icon-ok:before, .paginationControl .icon-ok.first:before,
  .paginationControl .icon-ok.previous:before,
  .paginationControl .icon-ok.next:before,
  .paginationControl .icon-ok.last:before {
    content: '\e816'; }
  .fontawesome-icon.icon-ok-circled:before, .paginationControl .icon-ok-circled.first:before,
  .paginationControl .icon-ok-circled.previous:before,
  .paginationControl .icon-ok-circled.next:before,
  .paginationControl .icon-ok-circled.last:before {
    content: '\e817'; }
  .fontawesome-icon.icon-ok-circled2:before, .paginationControl .icon-ok-circled2.first:before,
  .paginationControl .icon-ok-circled2.previous:before,
  .paginationControl .icon-ok-circled2.next:before,
  .paginationControl .icon-ok-circled2.last:before {
    content: '\e818'; }
  .fontawesome-icon.icon-ok-squared:before, .paginationControl .icon-ok-squared.first:before,
  .paginationControl .icon-ok-squared.previous:before,
  .paginationControl .icon-ok-squared.next:before,
  .paginationControl .icon-ok-squared.last:before {
    content: '\e819'; }
  .fontawesome-icon.icon-cancel:before, .paginationControl .icon-cancel.first:before,
  .paginationControl .icon-cancel.previous:before,
  .paginationControl .icon-cancel.next:before,
  .paginationControl .icon-cancel.last:before {
    content: '\e81a'; }
  .fontawesome-icon.icon-cancel-circled:before, .paginationControl .icon-cancel-circled.first:before,
  .paginationControl .icon-cancel-circled.previous:before,
  .paginationControl .icon-cancel-circled.next:before,
  .paginationControl .icon-cancel-circled.last:before {
    content: '\e81b'; }
  .fontawesome-icon.icon-minus-circled:before, .paginationControl .icon-minus-circled.first:before,
  .paginationControl .icon-minus-circled.previous:before,
  .paginationControl .icon-minus-circled.next:before,
  .paginationControl .icon-minus-circled.last:before {
    content: '\e81c'; }
  .fontawesome-icon.icon-minus-squared:before, .paginationControl .icon-minus-squared.first:before,
  .paginationControl .icon-minus-squared.previous:before,
  .paginationControl .icon-minus-squared.next:before,
  .paginationControl .icon-minus-squared.last:before {
    content: '\e81d'; }
  .fontawesome-icon.icon-minus-squared-alt:before, .paginationControl .icon-minus-squared-alt.first:before,
  .paginationControl .icon-minus-squared-alt.previous:before,
  .paginationControl .icon-minus-squared-alt.next:before,
  .paginationControl .icon-minus-squared-alt.last:before {
    content: '\e81e'; }
  .fontawesome-icon.icon-help:before, .paginationControl .icon-help.first:before,
  .paginationControl .icon-help.previous:before,
  .paginationControl .icon-help.next:before,
  .paginationControl .icon-help.last:before {
    content: '\e81f'; }
  .fontawesome-icon.icon-help-circled:before, .paginationControl .icon-help-circled.first:before,
  .paginationControl .icon-help-circled.previous:before,
  .paginationControl .icon-help-circled.next:before,
  .paginationControl .icon-help-circled.last:before {
    content: '\e820'; }
  .fontawesome-icon.icon-info-circled:before, .paginationControl .icon-info-circled.first:before,
  .paginationControl .icon-info-circled.previous:before,
  .paginationControl .icon-info-circled.next:before,
  .paginationControl .icon-info-circled.last:before {
    content: '\e821'; }
  .fontawesome-icon.icon-info:before, .paginationControl .icon-info.first:before,
  .paginationControl .icon-info.previous:before,
  .paginationControl .icon-info.next:before,
  .paginationControl .icon-info.last:before {
    content: '\e822'; }
  .fontawesome-icon.icon-home:before, .paginationControl .icon-home.first:before,
  .paginationControl .icon-home.previous:before,
  .paginationControl .icon-home.next:before,
  .paginationControl .icon-home.last:before {
    content: '\e823'; }
  .fontawesome-icon.icon-link:before, .paginationControl .icon-link.first:before,
  .paginationControl .icon-link.previous:before,
  .paginationControl .icon-link.next:before,
  .paginationControl .icon-link.last:before {
    content: '\e824'; }
  .fontawesome-icon.icon-unlink:before, .paginationControl .icon-unlink.first:before,
  .paginationControl .icon-unlink.previous:before,
  .paginationControl .icon-unlink.next:before,
  .paginationControl .icon-unlink.last:before {
    content: '\e825'; }
  .fontawesome-icon.icon-link-ext:before, .paginationControl .icon-link-ext.first:before,
  .paginationControl .icon-link-ext.previous:before,
  .paginationControl .icon-link-ext.next:before,
  .paginationControl .icon-link-ext.last:before {
    content: '\e826'; }
  .fontawesome-icon.icon-link-ext-alt:before, .paginationControl .icon-link-ext-alt.first:before,
  .paginationControl .icon-link-ext-alt.previous:before,
  .paginationControl .icon-link-ext-alt.next:before,
  .paginationControl .icon-link-ext-alt.last:before {
    content: '\e827'; }
  .fontawesome-icon.icon-attach:before, .paginationControl .icon-attach.first:before,
  .paginationControl .icon-attach.previous:before,
  .paginationControl .icon-attach.next:before,
  .paginationControl .icon-attach.last:before {
    content: '\e828'; }
  .fontawesome-icon.icon-lock:before, .paginationControl .icon-lock.first:before,
  .paginationControl .icon-lock.previous:before,
  .paginationControl .icon-lock.next:before,
  .paginationControl .icon-lock.last:before {
    content: '\e829'; }
  .fontawesome-icon.icon-tags:before, .paginationControl .icon-tags.first:before,
  .paginationControl .icon-tags.previous:before,
  .paginationControl .icon-tags.next:before,
  .paginationControl .icon-tags.last:before {
    content: '\e82a'; }
  .fontawesome-icon.icon-bookmark:before, .paginationControl .icon-bookmark.first:before,
  .paginationControl .icon-bookmark.previous:before,
  .paginationControl .icon-bookmark.next:before,
  .paginationControl .icon-bookmark.last:before {
    content: '\e82b'; }
  .fontawesome-icon.icon-bookmark-empty:before, .paginationControl .icon-bookmark-empty.first:before,
  .paginationControl .icon-bookmark-empty.previous:before,
  .paginationControl .icon-bookmark-empty.next:before,
  .paginationControl .icon-bookmark-empty.last:before {
    content: '\e82c'; }
  .fontawesome-icon.icon-flag:before, .paginationControl .icon-flag.first:before,
  .paginationControl .icon-flag.previous:before,
  .paginationControl .icon-flag.next:before,
  .paginationControl .icon-flag.last:before {
    content: '\e82d'; }
  .fontawesome-icon.icon-flag-empty:before, .paginationControl .icon-flag-empty.first:before,
  .paginationControl .icon-flag-empty.previous:before,
  .paginationControl .icon-flag-empty.next:before,
  .paginationControl .icon-flag-empty.last:before {
    content: '\e82e'; }
  .fontawesome-icon.icon-flag-checkered:before, .paginationControl .icon-flag-checkered.first:before,
  .paginationControl .icon-flag-checkered.previous:before,
  .paginationControl .icon-flag-checkered.next:before,
  .paginationControl .icon-flag-checkered.last:before {
    content: '\e82f'; }
  .fontawesome-icon.icon-thumbs-up:before, .paginationControl .icon-thumbs-up.first:before,
  .paginationControl .icon-thumbs-up.previous:before,
  .paginationControl .icon-thumbs-up.next:before,
  .paginationControl .icon-thumbs-up.last:before {
    content: '\e830'; }
  .fontawesome-icon.icon-thumbs-down:before, .paginationControl .icon-thumbs-down.first:before,
  .paginationControl .icon-thumbs-down.previous:before,
  .paginationControl .icon-thumbs-down.next:before,
  .paginationControl .icon-thumbs-down.last:before {
    content: '\e831'; }
  .fontawesome-icon.icon-thumbs-up-alt:before, .paginationControl .icon-thumbs-up-alt.first:before,
  .paginationControl .icon-thumbs-up-alt.previous:before,
  .paginationControl .icon-thumbs-up-alt.next:before,
  .paginationControl .icon-thumbs-up-alt.last:before {
    content: '\e832'; }
  .fontawesome-icon.icon-thumbs-down-alt:before, .paginationControl .icon-thumbs-down-alt.first:before,
  .paginationControl .icon-thumbs-down-alt.previous:before,
  .paginationControl .icon-thumbs-down-alt.next:before,
  .paginationControl .icon-thumbs-down-alt.last:before {
    content: '\e833'; }
  .fontawesome-icon.icon-download:before, .paginationControl .icon-download.first:before,
  .paginationControl .icon-download.previous:before,
  .paginationControl .icon-download.next:before,
  .paginationControl .icon-download.last:before {
    content: '\e834'; }
  .fontawesome-icon.icon-upload:before, .paginationControl .icon-upload.first:before,
  .paginationControl .icon-upload.previous:before,
  .paginationControl .icon-upload.next:before,
  .paginationControl .icon-upload.last:before {
    content: '\e835'; }
  .fontawesome-icon.icon-download-cloud:before, .paginationControl .icon-download-cloud.first:before,
  .paginationControl .icon-download-cloud.previous:before,
  .paginationControl .icon-download-cloud.next:before,
  .paginationControl .icon-download-cloud.last:before {
    content: '\e836'; }
  .fontawesome-icon.icon-upload-cloud:before, .paginationControl .icon-upload-cloud.first:before,
  .paginationControl .icon-upload-cloud.previous:before,
  .paginationControl .icon-upload-cloud.next:before,
  .paginationControl .icon-upload-cloud.last:before {
    content: '\e837'; }
  .fontawesome-icon.icon-export:before, .paginationControl .icon-export.first:before,
  .paginationControl .icon-export.previous:before,
  .paginationControl .icon-export.next:before,
  .paginationControl .icon-export.last:before {
    content: '\e838'; }
  .fontawesome-icon.icon-export-alt:before, .paginationControl .icon-export-alt.first:before,
  .paginationControl .icon-export-alt.previous:before,
  .paginationControl .icon-export-alt.next:before,
  .paginationControl .icon-export-alt.last:before {
    content: '\e839'; }
  .fontawesome-icon.icon-share:before, .paginationControl .icon-share.first:before,
  .paginationControl .icon-share.previous:before,
  .paginationControl .icon-share.next:before,
  .paginationControl .icon-share.last:before {
    content: '\e83a'; }
  .fontawesome-icon.icon-share-squared:before, .paginationControl .icon-share-squared.first:before,
  .paginationControl .icon-share-squared.previous:before,
  .paginationControl .icon-share-squared.next:before,
  .paginationControl .icon-share-squared.last:before {
    content: '\e83b'; }
  .fontawesome-icon.icon-pencil:before, .paginationControl .icon-pencil.first:before,
  .paginationControl .icon-pencil.previous:before,
  .paginationControl .icon-pencil.next:before,
  .paginationControl .icon-pencil.last:before {
    content: '\e83c'; }
  .fontawesome-icon.icon-pencil-squared:before, .paginationControl .icon-pencil-squared.first:before,
  .paginationControl .icon-pencil-squared.previous:before,
  .paginationControl .icon-pencil-squared.next:before,
  .paginationControl .icon-pencil-squared.last:before {
    content: '\e83d'; }
  .fontawesome-icon.icon-edit:before, .paginationControl .icon-edit.first:before,
  .paginationControl .icon-edit.previous:before,
  .paginationControl .icon-edit.next:before,
  .paginationControl .icon-edit.last:before {
    content: '\e83e'; }
  .fontawesome-icon.icon-print:before, .paginationControl .icon-print.first:before,
  .paginationControl .icon-print.previous:before,
  .paginationControl .icon-print.next:before,
  .paginationControl .icon-print.last:before {
    content: '\e83f'; }
  .fontawesome-icon.icon-retweet:before, .paginationControl .icon-retweet.first:before,
  .paginationControl .icon-retweet.previous:before,
  .paginationControl .icon-retweet.next:before,
  .paginationControl .icon-retweet.last:before {
    content: '\e840'; }
  .fontawesome-icon.icon-keyboard:before, .paginationControl .icon-keyboard.first:before,
  .paginationControl .icon-keyboard.previous:before,
  .paginationControl .icon-keyboard.next:before,
  .paginationControl .icon-keyboard.last:before {
    content: '\e841'; }
  .fontawesome-icon.icon-gamepad:before, .paginationControl .icon-gamepad.first:before,
  .paginationControl .icon-gamepad.previous:before,
  .paginationControl .icon-gamepad.next:before,
  .paginationControl .icon-gamepad.last:before {
    content: '\e842'; }
  .fontawesome-icon.icon-comment:before, .paginationControl .icon-comment.first:before,
  .paginationControl .icon-comment.previous:before,
  .paginationControl .icon-comment.next:before,
  .paginationControl .icon-comment.last:before {
    content: '\e843'; }
  .fontawesome-icon.icon-chat:before, .paginationControl .icon-chat.first:before,
  .paginationControl .icon-chat.previous:before,
  .paginationControl .icon-chat.next:before,
  .paginationControl .icon-chat.last:before {
    content: '\e844'; }
  .fontawesome-icon.icon-comment-empty:before, .paginationControl .icon-comment-empty.first:before,
  .paginationControl .icon-comment-empty.previous:before,
  .paginationControl .icon-comment-empty.next:before,
  .paginationControl .icon-comment-empty.last:before {
    content: '\e845'; }
  .fontawesome-icon.icon-attention:before, .paginationControl .icon-attention.first:before,
  .paginationControl .icon-attention.previous:before,
  .paginationControl .icon-attention.next:before,
  .paginationControl .icon-attention.last:before {
    content: '\e846'; }
  .fontawesome-icon.icon-attention-circled:before, .paginationControl .icon-attention-circled.first:before,
  .paginationControl .icon-attention-circled.previous:before,
  .paginationControl .icon-attention-circled.next:before,
  .paginationControl .icon-attention-circled.last:before {
    content: '\e847'; }
  .fontawesome-icon.icon-location:before, .paginationControl .icon-location.first:before,
  .paginationControl .icon-location.previous:before,
  .paginationControl .icon-location.next:before,
  .paginationControl .icon-location.last:before {
    content: '\e848'; }
  .fontawesome-icon.icon-direction:before, .paginationControl .icon-direction.first:before,
  .paginationControl .icon-direction.previous:before,
  .paginationControl .icon-direction.next:before,
  .paginationControl .icon-direction.last:before {
    content: '\e849'; }
  .fontawesome-icon.icon-compass:before, .paginationControl .icon-compass.first:before,
  .paginationControl .icon-compass.previous:before,
  .paginationControl .icon-compass.next:before,
  .paginationControl .icon-compass.last:before {
    content: '\e84a'; }
  .fontawesome-icon.icon-trash:before, .paginationControl .icon-trash.first:before,
  .paginationControl .icon-trash.previous:before,
  .paginationControl .icon-trash.next:before,
  .paginationControl .icon-trash.last:before {
    content: '\e84b'; }
  .fontawesome-icon.icon-trash-empty:before, .paginationControl .icon-trash-empty.first:before,
  .paginationControl .icon-trash-empty.previous:before,
  .paginationControl .icon-trash-empty.next:before,
  .paginationControl .icon-trash-empty.last:before {
    content: '\e84c'; }
  .fontawesome-icon.icon-doc:before, .paginationControl .icon-doc.first:before,
  .paginationControl .icon-doc.previous:before,
  .paginationControl .icon-doc.next:before,
  .paginationControl .icon-doc.last:before {
    content: '\e84d'; }
  .fontawesome-icon.icon-docs:before, .paginationControl .icon-docs.first:before,
  .paginationControl .icon-docs.previous:before,
  .paginationControl .icon-docs.next:before,
  .paginationControl .icon-docs.last:before {
    content: '\e84e'; }
  .fontawesome-icon.icon-doc-text:before, .paginationControl .icon-doc-text.first:before,
  .paginationControl .icon-doc-text.previous:before,
  .paginationControl .icon-doc-text.next:before,
  .paginationControl .icon-doc-text.last:before {
    content: '\e84f'; }
  .fontawesome-icon.icon-doc-inv:before, .paginationControl .icon-doc-inv.first:before,
  .paginationControl .icon-doc-inv.previous:before,
  .paginationControl .icon-doc-inv.next:before,
  .paginationControl .icon-doc-inv.last:before {
    content: '\e850'; }
  .fontawesome-icon.icon-doc-text-inv:before, .paginationControl .icon-doc-text-inv.first:before,
  .paginationControl .icon-doc-text-inv.previous:before,
  .paginationControl .icon-doc-text-inv.next:before,
  .paginationControl .icon-doc-text-inv.last:before {
    content: '\e851'; }
  .fontawesome-icon.icon-file-pdf:before, .paginationControl .icon-file-pdf.first:before,
  .paginationControl .icon-file-pdf.previous:before,
  .paginationControl .icon-file-pdf.next:before,
  .paginationControl .icon-file-pdf.last:before {
    content: '\e852'; }
  .fontawesome-icon.icon-file-word:before, .paginationControl .icon-file-word.first:before,
  .paginationControl .icon-file-word.previous:before,
  .paginationControl .icon-file-word.next:before,
  .paginationControl .icon-file-word.last:before {
    content: '\e853'; }
  .fontawesome-icon.icon-file-code:before, .paginationControl .icon-file-code.first:before,
  .paginationControl .icon-file-code.previous:before,
  .paginationControl .icon-file-code.next:before,
  .paginationControl .icon-file-code.last:before {
    content: '\e854'; }
  .fontawesome-icon.icon-folder:before, .paginationControl .icon-folder.first:before,
  .paginationControl .icon-folder.previous:before,
  .paginationControl .icon-folder.next:before,
  .paginationControl .icon-folder.last:before {
    content: '\e855'; }
  .fontawesome-icon.icon-folder-open:before, .paginationControl .icon-folder-open.first:before,
  .paginationControl .icon-folder-open.previous:before,
  .paginationControl .icon-folder-open.next:before,
  .paginationControl .icon-folder-open.last:before {
    content: '\e856'; }
  .fontawesome-icon.icon-folder-empty:before, .paginationControl .icon-folder-empty.first:before,
  .paginationControl .icon-folder-empty.previous:before,
  .paginationControl .icon-folder-empty.next:before,
  .paginationControl .icon-folder-empty.last:before {
    content: '\e857'; }
  .fontawesome-icon.icon-folder-open-empty:before, .paginationControl .icon-folder-open-empty.first:before,
  .paginationControl .icon-folder-open-empty.previous:before,
  .paginationControl .icon-folder-open-empty.next:before,
  .paginationControl .icon-folder-open-empty.last:before {
    content: '\e858'; }
  .fontawesome-icon.icon-box:before, .paginationControl .icon-box.first:before,
  .paginationControl .icon-box.previous:before,
  .paginationControl .icon-box.next:before,
  .paginationControl .icon-box.last:before {
    content: '\e859'; }
  .fontawesome-icon.icon-rss:before, .paginationControl .icon-rss.first:before,
  .paginationControl .icon-rss.previous:before,
  .paginationControl .icon-rss.next:before,
  .paginationControl .icon-rss.last:before {
    content: '\e85a'; }
  .fontawesome-icon.icon-rss-squared:before, .paginationControl .icon-rss-squared.first:before,
  .paginationControl .icon-rss-squared.previous:before,
  .paginationControl .icon-rss-squared.next:before,
  .paginationControl .icon-rss-squared.last:before {
    content: '\e85b'; }
  .fontawesome-icon.icon-phone:before, .paginationControl .icon-phone.first:before,
  .paginationControl .icon-phone.previous:before,
  .paginationControl .icon-phone.next:before,
  .paginationControl .icon-phone.last:before {
    content: '\e85c'; }
  .fontawesome-icon.icon-phone-squared:before, .paginationControl .icon-phone-squared.first:before,
  .paginationControl .icon-phone-squared.previous:before,
  .paginationControl .icon-phone-squared.next:before,
  .paginationControl .icon-phone-squared.last:before {
    content: '\e85d'; }
  .fontawesome-icon.icon-fax:before, .paginationControl .icon-fax.first:before,
  .paginationControl .icon-fax.previous:before,
  .paginationControl .icon-fax.next:before,
  .paginationControl .icon-fax.last:before {
    content: '\e85e'; }
  .fontawesome-icon.icon-menu:before, .paginationControl .icon-menu.first:before,
  .paginationControl .icon-menu.previous:before,
  .paginationControl .icon-menu.next:before,
  .paginationControl .icon-menu.last:before {
    content: '\e85f'; }
  .fontawesome-icon.icon-cog:before, .paginationControl .icon-cog.first:before,
  .paginationControl .icon-cog.previous:before,
  .paginationControl .icon-cog.next:before,
  .paginationControl .icon-cog.last:before {
    content: '\e860'; }
  .fontawesome-icon.icon-cog-alt:before, .paginationControl .icon-cog-alt.first:before,
  .paginationControl .icon-cog-alt.previous:before,
  .paginationControl .icon-cog-alt.next:before,
  .paginationControl .icon-cog-alt.last:before {
    content: '\e861'; }
  .fontawesome-icon.icon-calendar-empty:before, .paginationControl .icon-calendar-empty.first:before,
  .paginationControl .icon-calendar-empty.previous:before,
  .paginationControl .icon-calendar-empty.next:before,
  .paginationControl .icon-calendar-empty.last:before {
    content: '\e862'; }
  .fontawesome-icon.icon-login:before, .paginationControl .icon-login.first:before,
  .paginationControl .icon-login.previous:before,
  .paginationControl .icon-login.next:before,
  .paginationControl .icon-login.last:before {
    content: '\e863'; }
  .fontawesome-icon.icon-logout:before, .paginationControl .icon-logout.first:before,
  .paginationControl .icon-logout.previous:before,
  .paginationControl .icon-logout.next:before,
  .paginationControl .icon-logout.last:before {
    content: '\e864'; }
  .fontawesome-icon.icon-mic:before, .paginationControl .icon-mic.first:before,
  .paginationControl .icon-mic.previous:before,
  .paginationControl .icon-mic.next:before,
  .paginationControl .icon-mic.last:before {
    content: '\e865'; }
  .fontawesome-icon.icon-mute:before, .paginationControl .icon-mute.first:before,
  .paginationControl .icon-mute.previous:before,
  .paginationControl .icon-mute.next:before,
  .paginationControl .icon-mute.last:before {
    content: '\e866'; }
  .fontawesome-icon.icon-volume-off:before, .paginationControl .icon-volume-off.first:before,
  .paginationControl .icon-volume-off.previous:before,
  .paginationControl .icon-volume-off.next:before,
  .paginationControl .icon-volume-off.last:before {
    content: '\e867'; }
  .fontawesome-icon.icon-volume-down:before, .paginationControl .icon-volume-down.first:before,
  .paginationControl .icon-volume-down.previous:before,
  .paginationControl .icon-volume-down.next:before,
  .paginationControl .icon-volume-down.last:before {
    content: '\e868'; }
  .fontawesome-icon.icon-volume-up:before, .paginationControl .icon-volume-up.first:before,
  .paginationControl .icon-volume-up.previous:before,
  .paginationControl .icon-volume-up.next:before,
  .paginationControl .icon-volume-up.last:before {
    content: '\e869'; }
  .fontawesome-icon.icon-headphones:before, .paginationControl .icon-headphones.first:before,
  .paginationControl .icon-headphones.previous:before,
  .paginationControl .icon-headphones.next:before,
  .paginationControl .icon-headphones.last:before {
    content: '\e86a'; }
  .fontawesome-icon.icon-clock:before, .paginationControl .icon-clock.first:before,
  .paginationControl .icon-clock.previous:before,
  .paginationControl .icon-clock.next:before,
  .paginationControl .icon-clock.last:before {
    content: '\e86b'; }
  .fontawesome-icon.icon-lightbulb:before, .paginationControl .icon-lightbulb.first:before,
  .paginationControl .icon-lightbulb.previous:before,
  .paginationControl .icon-lightbulb.next:before,
  .paginationControl .icon-lightbulb.last:before {
    content: '\e86c'; }
  .fontawesome-icon.icon-block:before, .paginationControl .icon-block.first:before,
  .paginationControl .icon-block.previous:before,
  .paginationControl .icon-block.next:before,
  .paginationControl .icon-block.last:before {
    content: '\e86d'; }
  .fontawesome-icon.icon-resize-full:before, .paginationControl .icon-resize-full.first:before,
  .paginationControl .icon-resize-full.previous:before,
  .paginationControl .icon-resize-full.next:before,
  .paginationControl .icon-resize-full.last:before {
    content: '\e86e'; }
  .fontawesome-icon.icon-resize-full-alt:before, .paginationControl .icon-resize-full-alt.first:before,
  .paginationControl .icon-resize-full-alt.previous:before,
  .paginationControl .icon-resize-full-alt.next:before,
  .paginationControl .icon-resize-full-alt.last:before {
    content: '\e86f'; }
  .fontawesome-icon.icon-down-circled2:before, .paginationControl .icon-down-circled2.first:before,
  .paginationControl .icon-down-circled2.previous:before,
  .paginationControl .icon-down-circled2.next:before,
  .paginationControl .icon-down-circled2.last:before {
    content: '\e870'; }
  .fontawesome-icon.icon-up-circled2:before, .paginationControl .icon-up-circled2.first:before,
  .paginationControl .icon-up-circled2.previous:before,
  .paginationControl .icon-up-circled2.next:before,
  .paginationControl .icon-up-circled2.last:before {
    content: '\e871'; }
  .fontawesome-icon.icon-left-circled2:before, .paginationControl .icon-left-circled2.first:before,
  .paginationControl .icon-left-circled2.previous:before,
  .paginationControl .icon-left-circled2.next:before,
  .paginationControl .icon-left-circled2.last:before {
    content: '\e872'; }
  .fontawesome-icon.icon-right-circled2:before, .paginationControl .icon-right-circled2.first:before,
  .paginationControl .icon-right-circled2.previous:before,
  .paginationControl .icon-right-circled2.next:before,
  .paginationControl .icon-right-circled2.last:before {
    content: '\e873'; }
  .fontawesome-icon.icon-down-dir:before, .paginationControl .icon-down-dir.first:before,
  .paginationControl .icon-down-dir.previous:before,
  .paginationControl .icon-down-dir.next:before,
  .paginationControl .icon-down-dir.last:before {
    content: '\e874'; }
  .fontawesome-icon.icon-up-dir:before, .paginationControl .icon-up-dir.first:before,
  .paginationControl .icon-up-dir.previous:before,
  .paginationControl .icon-up-dir.next:before,
  .paginationControl .icon-up-dir.last:before {
    content: '\e875'; }
  .fontawesome-icon.icon-left-dir:before, .paginationControl .icon-left-dir.first:before,
  .paginationControl .previous:before,
  .paginationControl .icon-left-dir.next:before,
  .paginationControl .icon-left-dir.last:before {
    content: '\e876'; }
  .fontawesome-icon.icon-right-dir:before, .paginationControl .icon-right-dir.first:before,
  .paginationControl .icon-right-dir.previous:before,
  .paginationControl .next:before,
  .paginationControl .icon-right-dir.last:before {
    content: '\e877'; }
  .fontawesome-icon.icon-down-open:before, .paginationControl .icon-down-open.first:before,
  .paginationControl .icon-down-open.previous:before,
  .paginationControl .icon-down-open.next:before,
  .paginationControl .icon-down-open.last:before {
    content: ""; }
  .fontawesome-icon.icon-left-open:before, .paginationControl .icon-left-open.first:before,
  .paginationControl .icon-left-open.previous:before,
  .paginationControl .icon-left-open.next:before,
  .paginationControl .icon-left-open.last:before {
    content: '\e879'; }
  .fontawesome-icon.icon-right-open:before, .paginationControl .icon-right-open.first:before,
  .paginationControl .icon-right-open.previous:before,
  .paginationControl .icon-right-open.next:before,
  .paginationControl .icon-right-open.last:before {
    content: '\e87a'; }
  .fontawesome-icon.icon-up-open:before, .paginationControl .icon-up-open.first:before,
  .paginationControl .icon-up-open.previous:before,
  .paginationControl .icon-up-open.next:before,
  .paginationControl .icon-up-open.last:before {
    content: ""; }
  .fontawesome-icon.icon-angle-left:before, .paginationControl .icon-angle-left.first:before,
  .paginationControl .icon-angle-left.previous:before,
  .paginationControl .icon-angle-left.next:before,
  .paginationControl .icon-angle-left.last:before {
    content: '\e87c'; }
  .fontawesome-icon.icon-angle-right:before, .paginationControl .icon-angle-right.first:before,
  .paginationControl .icon-angle-right.previous:before,
  .paginationControl .icon-angle-right.next:before,
  .paginationControl .icon-angle-right.last:before {
    content: '\e87d'; }
  .fontawesome-icon.icon-angle-double-left:before, .paginationControl .icon-angle-double-left.first:before,
  .paginationControl .icon-angle-double-left.previous:before,
  .paginationControl .icon-angle-double-left.next:before,
  .paginationControl .icon-angle-double-left.last:before {
    content: '\e87e'; }
  .fontawesome-icon.icon-angle-double-right:before, .paginationControl .icon-angle-double-right.first:before,
  .paginationControl .icon-angle-double-right.previous:before,
  .paginationControl .icon-angle-double-right.next:before,
  .paginationControl .icon-angle-double-right.last:before {
    content: '\e87f'; }
  .fontawesome-icon.icon-angle-double-up:before, .paginationControl .icon-angle-double-up.first:before,
  .paginationControl .icon-angle-double-up.previous:before,
  .paginationControl .icon-angle-double-up.next:before,
  .paginationControl .icon-angle-double-up.last:before {
    content: '\e880'; }
  .fontawesome-icon.icon-angle-double-down:before, .paginationControl .icon-angle-double-down.first:before,
  .paginationControl .icon-angle-double-down.previous:before,
  .paginationControl .icon-angle-double-down.next:before,
  .paginationControl .icon-angle-double-down.last:before {
    content: '\e881'; }
  .fontawesome-icon.icon-down:before, .paginationControl .icon-down.first:before,
  .paginationControl .icon-down.previous:before,
  .paginationControl .icon-down.next:before,
  .paginationControl .icon-down.last:before {
    content: '\e882'; }
  .fontawesome-icon.icon-left:before, .paginationControl .icon-left.first:before,
  .paginationControl .icon-left.previous:before,
  .paginationControl .icon-left.next:before,
  .paginationControl .icon-left.last:before {
    content: '\e883'; }
  .fontawesome-icon.icon-right:before, .paginationControl .icon-right.first:before,
  .paginationControl .icon-right.previous:before,
  .paginationControl .icon-right.next:before,
  .paginationControl .icon-right.last:before {
    content: '\e884'; }
  .fontawesome-icon.icon-up:before, .paginationControl .icon-up.first:before,
  .paginationControl .icon-up.previous:before,
  .paginationControl .icon-up.next:before,
  .paginationControl .icon-up.last:before {
    content: '\e885'; }
  .fontawesome-icon.icon-down-big:before, .paginationControl .icon-down-big.first:before,
  .paginationControl .icon-down-big.previous:before,
  .paginationControl .icon-down-big.next:before,
  .paginationControl .icon-down-big.last:before {
    content: '\e886'; }
  .fontawesome-icon.icon-left-big:before, .paginationControl .icon-left-big.first:before,
  .paginationControl .icon-left-big.previous:before,
  .paginationControl .icon-left-big.next:before,
  .paginationControl .icon-left-big.last:before {
    content: '\e887'; }
  .fontawesome-icon.icon-right-big:before, .paginationControl .icon-right-big.first:before,
  .paginationControl .icon-right-big.previous:before,
  .paginationControl .icon-right-big.next:before,
  .paginationControl .icon-right-big.last:before {
    content: '\e888'; }
  .fontawesome-icon.icon-up-big:before, .paginationControl .icon-up-big.first:before,
  .paginationControl .icon-up-big.previous:before,
  .paginationControl .icon-up-big.next:before,
  .paginationControl .icon-up-big.last:before {
    content: '\e889'; }
  .fontawesome-icon.icon-right-hand:before, .paginationControl .icon-right-hand.first:before,
  .paginationControl .icon-right-hand.previous:before,
  .paginationControl .icon-right-hand.next:before,
  .paginationControl .icon-right-hand.last:before {
    content: '\e88a'; }
  .fontawesome-icon.icon-left-hand:before, .paginationControl .icon-left-hand.first:before,
  .paginationControl .icon-left-hand.previous:before,
  .paginationControl .icon-left-hand.next:before,
  .paginationControl .icon-left-hand.last:before {
    content: '\e88b'; }
  .fontawesome-icon.icon-cw:before, .paginationControl .icon-cw.first:before,
  .paginationControl .icon-cw.previous:before,
  .paginationControl .icon-cw.next:before,
  .paginationControl .icon-cw.last:before {
    content: '\e88c'; }
  .fontawesome-icon.icon-ccw:before, .paginationControl .icon-ccw.first:before,
  .paginationControl .icon-ccw.previous:before,
  .paginationControl .icon-ccw.next:before,
  .paginationControl .icon-ccw.last:before {
    content: '\e88d'; }
  .fontawesome-icon.icon-arrows-cw:before, .paginationControl .icon-arrows-cw.first:before,
  .paginationControl .icon-arrows-cw.previous:before,
  .paginationControl .icon-arrows-cw.next:before,
  .paginationControl .icon-arrows-cw.last:before {
    content: '\e88e'; }
  .fontawesome-icon.icon-level-up:before, .paginationControl .icon-level-up.first:before,
  .paginationControl .icon-level-up.previous:before,
  .paginationControl .icon-level-up.next:before,
  .paginationControl .icon-level-up.last:before {
    content: '\e88f'; }
  .fontawesome-icon.icon-level-down:before, .paginationControl .icon-level-down.first:before,
  .paginationControl .icon-level-down.previous:before,
  .paginationControl .icon-level-down.next:before,
  .paginationControl .icon-level-down.last:before {
    content: '\e890'; }
  .fontawesome-icon.icon-shuffle:before, .paginationControl .icon-shuffle.first:before,
  .paginationControl .icon-shuffle.previous:before,
  .paginationControl .icon-shuffle.next:before,
  .paginationControl .icon-shuffle.last:before {
    content: '\e891'; }
  .fontawesome-icon.icon-exchange:before, .paginationControl .icon-exchange.first:before,
  .paginationControl .icon-exchange.previous:before,
  .paginationControl .icon-exchange.next:before,
  .paginationControl .icon-exchange.last:before {
    content: '\e892'; }
  .fontawesome-icon.icon-history:before, .paginationControl .icon-history.first:before,
  .paginationControl .icon-history.previous:before,
  .paginationControl .icon-history.next:before,
  .paginationControl .icon-history.last:before {
    content: '\e893'; }
  .fontawesome-icon.icon-expand:before, .paginationControl .icon-expand.first:before,
  .paginationControl .icon-expand.previous:before,
  .paginationControl .icon-expand.next:before,
  .paginationControl .icon-expand.last:before {
    content: '\e894'; }
  .fontawesome-icon.icon-collapse:before, .paginationControl .icon-collapse.first:before,
  .paginationControl .icon-collapse.previous:before,
  .paginationControl .icon-collapse.next:before,
  .paginationControl .icon-collapse.last:before {
    content: '\e895'; }
  .fontawesome-icon.icon-expand-right:before, .paginationControl .icon-expand-right.first:before,
  .paginationControl .icon-expand-right.previous:before,
  .paginationControl .icon-expand-right.next:before,
  .paginationControl .icon-expand-right.last:before {
    content: '\e896'; }
  .fontawesome-icon.icon-collapse-left:before, .paginationControl .icon-collapse-left.first:before,
  .paginationControl .icon-collapse-left.previous:before,
  .paginationControl .icon-collapse-left.next:before,
  .paginationControl .icon-collapse-left.last:before {
    content: '\e897'; }
  .fontawesome-icon.icon-play:before, .paginationControl .icon-play.first:before,
  .paginationControl .icon-play.previous:before,
  .paginationControl .icon-play.next:before,
  .paginationControl .icon-play.last:before {
    content: '\e898'; }
  .fontawesome-icon.icon-play-circled:before, .paginationControl .icon-play-circled.first:before,
  .paginationControl .icon-play-circled.previous:before,
  .paginationControl .icon-play-circled.next:before,
  .paginationControl .icon-play-circled.last:before {
    content: '\e899'; }
  .fontawesome-icon.icon-to-start-alt:before, .paginationControl .icon-to-start-alt.first:before,
  .paginationControl .icon-to-start-alt.previous:before,
  .paginationControl .icon-to-start-alt.next:before,
  .paginationControl .icon-to-start-alt.last:before {
    content: '\e89a'; }
  .fontawesome-icon.icon-fast-fw:before, .paginationControl .icon-fast-fw.first:before,
  .paginationControl .icon-fast-fw.previous:before,
  .paginationControl .icon-fast-fw.next:before,
  .paginationControl .icon-fast-fw.last:before {
    content: '\e89b'; }
  .fontawesome-icon.icon-fast-bw:before, .paginationControl .icon-fast-bw.first:before,
  .paginationControl .icon-fast-bw.previous:before,
  .paginationControl .icon-fast-bw.next:before,
  .paginationControl .icon-fast-bw.last:before {
    content: '\e89c'; }
  .fontawesome-icon.icon-eject:before, .paginationControl .icon-eject.first:before,
  .paginationControl .icon-eject.previous:before,
  .paginationControl .icon-eject.next:before,
  .paginationControl .icon-eject.last:before {
    content: '\e89d'; }
  .fontawesome-icon.icon-target:before, .paginationControl .icon-target.first:before,
  .paginationControl .icon-target.previous:before,
  .paginationControl .icon-target.next:before,
  .paginationControl .icon-target.last:before {
    content: '\e89e'; }
  .fontawesome-icon.icon-signal:before, .paginationControl .icon-signal.first:before,
  .paginationControl .icon-signal.previous:before,
  .paginationControl .icon-signal.next:before,
  .paginationControl .icon-signal.last:before {
    content: '\e89f'; }
  .fontawesome-icon.icon-wifi:before, .paginationControl .icon-wifi.first:before,
  .paginationControl .icon-wifi.previous:before,
  .paginationControl .icon-wifi.next:before,
  .paginationControl .icon-wifi.last:before {
    content: '\e8a0'; }
  .fontawesome-icon.icon-award:before, .paginationControl .icon-award.first:before,
  .paginationControl .icon-award.previous:before,
  .paginationControl .icon-award.next:before,
  .paginationControl .icon-award.last:before {
    content: '\e8a1'; }
  .fontawesome-icon.icon-desktop:before, .paginationControl .icon-desktop.first:before,
  .paginationControl .icon-desktop.previous:before,
  .paginationControl .icon-desktop.next:before,
  .paginationControl .icon-desktop.last:before {
    content: '\e8a2'; }
  .fontawesome-icon.icon-laptop:before, .paginationControl .icon-laptop.first:before,
  .paginationControl .icon-laptop.previous:before,
  .paginationControl .icon-laptop.next:before,
  .paginationControl .icon-laptop.last:before {
    content: '\e8a3'; }
  .fontawesome-icon.icon-tablet:before, .paginationControl .icon-tablet.first:before,
  .paginationControl .icon-tablet.previous:before,
  .paginationControl .icon-tablet.next:before,
  .paginationControl .icon-tablet.last:before {
    content: '\e8a4'; }
  .fontawesome-icon.icon-mobile:before, .paginationControl .icon-mobile.first:before,
  .paginationControl .icon-mobile.previous:before,
  .paginationControl .icon-mobile.next:before,
  .paginationControl .icon-mobile.last:before {
    content: '\e8a5'; }
  .fontawesome-icon.icon-inbox:before, .paginationControl .icon-inbox.first:before,
  .paginationControl .icon-inbox.previous:before,
  .paginationControl .icon-inbox.next:before,
  .paginationControl .icon-inbox.last:before {
    content: '\e8a6'; }
  .fontawesome-icon.icon-globe:before, .paginationControl .icon-globe.first:before,
  .paginationControl .icon-globe.previous:before,
  .paginationControl .icon-globe.next:before,
  .paginationControl .icon-globe.last:before {
    content: '\e8a7'; }
  .fontawesome-icon.icon-fighter-jet:before, .paginationControl .icon-fighter-jet.first:before,
  .paginationControl .icon-fighter-jet.previous:before,
  .paginationControl .icon-fighter-jet.next:before,
  .paginationControl .icon-fighter-jet.last:before {
    content: '\e8a8'; }
  .fontawesome-icon.icon-paper-plane:before, .paginationControl .icon-paper-plane.first:before,
  .paginationControl .icon-paper-plane.previous:before,
  .paginationControl .icon-paper-plane.next:before,
  .paginationControl .icon-paper-plane.last:before {
    content: '\e8a9'; }
  .fontawesome-icon.icon-paper-plane-empty:before, .paginationControl .icon-paper-plane-empty.first:before,
  .paginationControl .icon-paper-plane-empty.previous:before,
  .paginationControl .icon-paper-plane-empty.next:before,
  .paginationControl .icon-paper-plane-empty.last:before {
    content: '\e8aa'; }
  .fontawesome-icon.icon-space-shuttle:before, .paginationControl .icon-space-shuttle.first:before,
  .paginationControl .icon-space-shuttle.previous:before,
  .paginationControl .icon-space-shuttle.next:before,
  .paginationControl .icon-space-shuttle.last:before {
    content: '\e8ab'; }
  .fontawesome-icon.icon-leaf:before, .paginationControl .icon-leaf.first:before,
  .paginationControl .icon-leaf.previous:before,
  .paginationControl .icon-leaf.next:before,
  .paginationControl .icon-leaf.last:before {
    content: '\e8ac'; }
  .fontawesome-icon.icon-font:before, .paginationControl .icon-font.first:before,
  .paginationControl .icon-font.previous:before,
  .paginationControl .icon-font.next:before,
  .paginationControl .icon-font.last:before {
    content: '\e8ad'; }
  .fontawesome-icon.icon-bold:before, .paginationControl .icon-bold.first:before,
  .paginationControl .icon-bold.previous:before,
  .paginationControl .icon-bold.next:before,
  .paginationControl .icon-bold.last:before {
    content: '\e8ae'; }
  .fontawesome-icon.icon-medium:before, .paginationControl .icon-medium.first:before,
  .paginationControl .icon-medium.previous:before,
  .paginationControl .icon-medium.next:before,
  .paginationControl .icon-medium.last:before {
    content: '\e8af'; }
  .fontawesome-icon.icon-italic:before, .paginationControl .icon-italic.first:before,
  .paginationControl .icon-italic.previous:before,
  .paginationControl .icon-italic.next:before,
  .paginationControl .icon-italic.last:before {
    content: '\e8b0'; }
  .fontawesome-icon.icon-header:before, .paginationControl .icon-header.first:before,
  .paginationControl .icon-header.previous:before,
  .paginationControl .icon-header.next:before,
  .paginationControl .icon-header.last:before {
    content: '\e8b1'; }
  .fontawesome-icon.icon-paragraph:before, .paginationControl .icon-paragraph.first:before,
  .paginationControl .icon-paragraph.previous:before,
  .paginationControl .icon-paragraph.next:before,
  .paginationControl .icon-paragraph.last:before {
    content: '\e8b2'; }
  .fontawesome-icon.icon-text-height:before, .paginationControl .icon-text-height.first:before,
  .paginationControl .icon-text-height.previous:before,
  .paginationControl .icon-text-height.next:before,
  .paginationControl .icon-text-height.last:before {
    content: '\e8b3'; }
  .fontawesome-icon.icon-text-width:before, .paginationControl .icon-text-width.first:before,
  .paginationControl .icon-text-width.previous:before,
  .paginationControl .icon-text-width.next:before,
  .paginationControl .icon-text-width.last:before {
    content: '\e8b4'; }
  .fontawesome-icon.icon-align-left:before, .paginationControl .icon-align-left.first:before,
  .paginationControl .icon-align-left.previous:before,
  .paginationControl .icon-align-left.next:before,
  .paginationControl .icon-align-left.last:before {
    content: '\e8b5'; }
  .fontawesome-icon.icon-list-bullet:before, .paginationControl .icon-list-bullet.first:before,
  .paginationControl .icon-list-bullet.previous:before,
  .paginationControl .icon-list-bullet.next:before,
  .paginationControl .icon-list-bullet.last:before {
    content: '\e8b6'; }
  .fontawesome-icon.icon-list-numbered:before, .paginationControl .icon-list-numbered.first:before,
  .paginationControl .icon-list-numbered.previous:before,
  .paginationControl .icon-list-numbered.next:before,
  .paginationControl .icon-list-numbered.last:before {
    content: '\e8b7'; }
  .fontawesome-icon.icon-strike:before, .paginationControl .icon-strike.first:before,
  .paginationControl .icon-strike.previous:before,
  .paginationControl .icon-strike.next:before,
  .paginationControl .icon-strike.last:before {
    content: '\e8b8'; }
  .fontawesome-icon.icon-underline:before, .paginationControl .icon-underline.first:before,
  .paginationControl .icon-underline.previous:before,
  .paginationControl .icon-underline.next:before,
  .paginationControl .icon-underline.last:before {
    content: '\e8b9'; }
  .fontawesome-icon.icon-superscript:before, .paginationControl .icon-superscript.first:before,
  .paginationControl .icon-superscript.previous:before,
  .paginationControl .icon-superscript.next:before,
  .paginationControl .icon-superscript.last:before {
    content: '\e8ba'; }
  .fontawesome-icon.icon-subscript:before, .paginationControl .icon-subscript.first:before,
  .paginationControl .icon-subscript.previous:before,
  .paginationControl .icon-subscript.next:before,
  .paginationControl .icon-subscript.last:before {
    content: '\e8bb'; }
  .fontawesome-icon.icon-table:before, .paginationControl .icon-table.first:before,
  .paginationControl .icon-table.previous:before,
  .paginationControl .icon-table.next:before,
  .paginationControl .icon-table.last:before {
    content: '\e8bc'; }
  .fontawesome-icon.icon-columns:before, .paginationControl .icon-columns.first:before,
  .paginationControl .icon-columns.previous:before,
  .paginationControl .icon-columns.next:before,
  .paginationControl .icon-columns.last:before {
    content: '\e8bd'; }
  .fontawesome-icon.icon-crop:before, .paginationControl .icon-crop.first:before,
  .paginationControl .icon-crop.previous:before,
  .paginationControl .icon-crop.next:before,
  .paginationControl .icon-crop.last:before {
    content: '\e8be'; }
  .fontawesome-icon.icon-scissors:before, .paginationControl .icon-scissors.first:before,
  .paginationControl .icon-scissors.previous:before,
  .paginationControl .icon-scissors.next:before,
  .paginationControl .icon-scissors.last:before {
    content: '\e8bf'; }
  .fontawesome-icon.icon-paste:before, .paginationControl .icon-paste.first:before,
  .paginationControl .icon-paste.previous:before,
  .paginationControl .icon-paste.next:before,
  .paginationControl .icon-paste.last:before {
    content: '\e8c0'; }
  .fontawesome-icon.icon-briefcase:before, .paginationControl .icon-briefcase.first:before,
  .paginationControl .icon-briefcase.previous:before,
  .paginationControl .icon-briefcase.next:before,
  .paginationControl .icon-briefcase.last:before {
    content: '\e8c1'; }
  .fontawesome-icon.icon-suitcase:before, .paginationControl .icon-suitcase.first:before,
  .paginationControl .icon-suitcase.previous:before,
  .paginationControl .icon-suitcase.next:before,
  .paginationControl .icon-suitcase.last:before {
    content: '\e8c2'; }
  .fontawesome-icon.icon-ellipsis:before, .paginationControl .icon-ellipsis.first:before,
  .paginationControl .icon-ellipsis.previous:before,
  .paginationControl .icon-ellipsis.next:before,
  .paginationControl .icon-ellipsis.last:before {
    content: '\e8c3'; }
  .fontawesome-icon.icon-book:before, .paginationControl .icon-book.first:before,
  .paginationControl .icon-book.previous:before,
  .paginationControl .icon-book.next:before,
  .paginationControl .icon-book.last:before {
    content: '\e8c4'; }
  .fontawesome-icon.icon-ajust:before, .paginationControl .icon-ajust.first:before,
  .paginationControl .icon-ajust.previous:before,
  .paginationControl .icon-ajust.next:before,
  .paginationControl .icon-ajust.last:before {
    content: '\e8c5'; }
  .fontawesome-icon.icon-tint:before, .paginationControl .icon-tint.first:before,
  .paginationControl .icon-tint.previous:before,
  .paginationControl .icon-tint.next:before,
  .paginationControl .icon-tint.last:before {
    content: '\e8c6'; }
  .fontawesome-icon.icon-toggle-off:before, .paginationControl .icon-toggle-off.first:before,
  .paginationControl .icon-toggle-off.previous:before,
  .paginationControl .icon-toggle-off.next:before,
  .paginationControl .icon-toggle-off.last:before {
    content: '\e8c7'; }
  .fontawesome-icon.icon-toggle-on:before, .paginationControl .icon-toggle-on.first:before,
  .paginationControl .icon-toggle-on.previous:before,
  .paginationControl .icon-toggle-on.next:before,
  .paginationControl .icon-toggle-on.last:before {
    content: '\e8c8'; }
  .fontawesome-icon.icon-check:before, .paginationControl .icon-check.first:before,
  .paginationControl .icon-check.previous:before,
  .paginationControl .icon-check.next:before,
  .paginationControl .icon-check.last:before {
    content: '\e8c9'; }
  .fontawesome-icon.icon-check-empty:before, .paginationControl .icon-check-empty.first:before,
  .paginationControl .icon-check-empty.previous:before,
  .paginationControl .icon-check-empty.next:before,
  .paginationControl .icon-check-empty.last:before {
    content: '\e8ca'; }
  .fontawesome-icon.icon-circle:before, .paginationControl .icon-circle.first:before,
  .paginationControl .icon-circle.previous:before,
  .paginationControl .icon-circle.next:before,
  .paginationControl .icon-circle.last:before {
    content: '\e8cb'; }
  .fontawesome-icon.icon-circle-empty:before, .paginationControl .icon-circle-empty.first:before,
  .paginationControl .icon-circle-empty.previous:before,
  .paginationControl .icon-circle-empty.next:before,
  .paginationControl .icon-circle-empty.last:before {
    content: '\e8cc'; }
  .fontawesome-icon.icon-circle-thin:before, .paginationControl .icon-circle-thin.first:before,
  .paginationControl .icon-circle-thin.previous:before,
  .paginationControl .icon-circle-thin.next:before,
  .paginationControl .icon-circle-thin.last:before {
    content: '\e8cd'; }
  .fontawesome-icon.icon-circle-notch:before, .paginationControl .icon-circle-notch.first:before,
  .paginationControl .icon-circle-notch.previous:before,
  .paginationControl .icon-circle-notch.next:before,
  .paginationControl .icon-circle-notch.last:before {
    content: '\e8ce'; }
  .fontawesome-icon.icon-dot-circled:before, .paginationControl .icon-dot-circled.first:before,
  .paginationControl .icon-dot-circled.previous:before,
  .paginationControl .icon-dot-circled.next:before,
  .paginationControl .icon-dot-circled.last:before {
    content: '\e8cf'; }
  .fontawesome-icon.icon-asterisk:before, .paginationControl .icon-asterisk.first:before,
  .paginationControl .icon-asterisk.previous:before,
  .paginationControl .icon-asterisk.next:before,
  .paginationControl .icon-asterisk.last:before {
    content: '\e8d0'; }
  .fontawesome-icon.icon-gift:before, .paginationControl .icon-gift.first:before,
  .paginationControl .icon-gift.previous:before,
  .paginationControl .icon-gift.next:before,
  .paginationControl .icon-gift.last:before {
    content: '\e8d1'; }
  .fontawesome-icon.icon-ticket:before, .paginationControl .icon-ticket.first:before,
  .paginationControl .icon-ticket.previous:before,
  .paginationControl .icon-ticket.next:before,
  .paginationControl .icon-ticket.last:before {
    content: '\e8d2'; }
  .fontawesome-icon.icon-credit-card:before, .paginationControl .icon-credit-card.first:before,
  .paginationControl .icon-credit-card.previous:before,
  .paginationControl .icon-credit-card.next:before,
  .paginationControl .icon-credit-card.last:before {
    content: '\e8d3'; }
  .fontawesome-icon.icon-floppy:before, .paginationControl .icon-floppy.first:before,
  .paginationControl .icon-floppy.previous:before,
  .paginationControl .icon-floppy.next:before,
  .paginationControl .icon-floppy.last:before {
    content: '\e8d4'; }
  .fontawesome-icon.icon-megaphone:before, .paginationControl .icon-megaphone.first:before,
  .paginationControl .icon-megaphone.previous:before,
  .paginationControl .icon-megaphone.next:before,
  .paginationControl .icon-megaphone.last:before {
    content: '\e8d5'; }
  .fontawesome-icon.icon-hdd:before, .paginationControl .icon-hdd.first:before,
  .paginationControl .icon-hdd.previous:before,
  .paginationControl .icon-hdd.next:before,
  .paginationControl .icon-hdd.last:before {
    content: '\e8d6'; }
  .fontawesome-icon.icon-key:before, .paginationControl .icon-key.first:before,
  .paginationControl .icon-key.previous:before,
  .paginationControl .icon-key.next:before,
  .paginationControl .icon-key.last:before {
    content: '\e8d7'; }
  .fontawesome-icon.icon-fork:before, .paginationControl .icon-fork.first:before,
  .paginationControl .icon-fork.previous:before,
  .paginationControl .icon-fork.next:before,
  .paginationControl .icon-fork.last:before {
    content: '\e8d8'; }
  .fontawesome-icon.icon-rocket:before, .paginationControl .icon-rocket.first:before,
  .paginationControl .icon-rocket.previous:before,
  .paginationControl .icon-rocket.next:before,
  .paginationControl .icon-rocket.last:before {
    content: '\e8d9'; }
  .fontawesome-icon.icon-bug:before, .paginationControl .icon-bug.first:before,
  .paginationControl .icon-bug.previous:before,
  .paginationControl .icon-bug.next:before,
  .paginationControl .icon-bug.last:before {
    content: '\e8da'; }
  .fontawesome-icon.icon-certificate:before, .paginationControl .icon-certificate.first:before,
  .paginationControl .icon-certificate.previous:before,
  .paginationControl .icon-certificate.next:before,
  .paginationControl .icon-certificate.last:before {
    content: '\e8db'; }
  .fontawesome-icon.icon-tasks:before, .paginationControl .icon-tasks.first:before,
  .paginationControl .icon-tasks.previous:before,
  .paginationControl .icon-tasks.next:before,
  .paginationControl .icon-tasks.last:before {
    content: '\e8dc'; }
  .fontawesome-icon.icon-filter:before, .paginationControl .icon-filter.first:before,
  .paginationControl .icon-filter.previous:before,
  .paginationControl .icon-filter.next:before,
  .paginationControl .icon-filter.last:before {
    content: '\e8dd'; }
  .fontawesome-icon.icon-beaker:before, .paginationControl .icon-beaker.first:before,
  .paginationControl .icon-beaker.previous:before,
  .paginationControl .icon-beaker.next:before,
  .paginationControl .icon-beaker.last:before {
    content: '\e8de'; }
  .fontawesome-icon.icon-magic:before, .paginationControl .icon-magic.first:before,
  .paginationControl .icon-magic.previous:before,
  .paginationControl .icon-magic.next:before,
  .paginationControl .icon-magic.last:before {
    content: '\e8df'; }
  .fontawesome-icon.icon-train:before, .paginationControl .icon-train.first:before,
  .paginationControl .icon-train.previous:before,
  .paginationControl .icon-train.next:before,
  .paginationControl .icon-train.last:before {
    content: '\e8e0'; }
  .fontawesome-icon.icon-subway:before, .paginationControl .icon-subway.first:before,
  .paginationControl .icon-subway.previous:before,
  .paginationControl .icon-subway.next:before,
  .paginationControl .icon-subway.last:before {
    content: '\e8e1'; }
  .fontawesome-icon.icon-ship:before, .paginationControl .icon-ship.first:before,
  .paginationControl .icon-ship.previous:before,
  .paginationControl .icon-ship.next:before,
  .paginationControl .icon-ship.last:before {
    content: '\e8e2'; }
  .fontawesome-icon.icon-money:before, .paginationControl .icon-money.first:before,
  .paginationControl .icon-money.previous:before,
  .paginationControl .icon-money.next:before,
  .paginationControl .icon-money.last:before {
    content: '\e8e3'; }
  .fontawesome-icon.icon-euro:before, .paginationControl .icon-euro.first:before,
  .paginationControl .icon-euro.previous:before,
  .paginationControl .icon-euro.next:before,
  .paginationControl .icon-euro.last:before {
    content: '\e8e4'; }
  .fontawesome-icon.icon-pound:before, .paginationControl .icon-pound.first:before,
  .paginationControl .icon-pound.previous:before,
  .paginationControl .icon-pound.next:before,
  .paginationControl .icon-pound.last:before {
    content: '\e8e5'; }
  .fontawesome-icon.icon-dollar:before, .paginationControl .icon-dollar.first:before,
  .paginationControl .icon-dollar.previous:before,
  .paginationControl .icon-dollar.next:before,
  .paginationControl .icon-dollar.last:before {
    content: '\e8e6'; }
  .fontawesome-icon.icon-rupee:before, .paginationControl .icon-rupee.first:before,
  .paginationControl .icon-rupee.previous:before,
  .paginationControl .icon-rupee.next:before,
  .paginationControl .icon-rupee.last:before {
    content: '\e8e7'; }
  .fontawesome-icon.icon-yen:before, .paginationControl .icon-yen.first:before,
  .paginationControl .icon-yen.previous:before,
  .paginationControl .icon-yen.next:before,
  .paginationControl .icon-yen.last:before {
    content: '\e8e8'; }
  .fontawesome-icon.icon-rouble:before, .paginationControl .icon-rouble.first:before,
  .paginationControl .icon-rouble.previous:before,
  .paginationControl .icon-rouble.next:before,
  .paginationControl .icon-rouble.last:before {
    content: '\e8e9'; }
  .fontawesome-icon.icon-shekel:before, .paginationControl .icon-shekel.first:before,
  .paginationControl .icon-shekel.previous:before,
  .paginationControl .icon-shekel.next:before,
  .paginationControl .icon-shekel.last:before {
    content: '\e8ea'; }
  .fontawesome-icon.icon-try:before, .paginationControl .icon-try.first:before,
  .paginationControl .icon-try.previous:before,
  .paginationControl .icon-try.next:before,
  .paginationControl .icon-try.last:before {
    content: '\e8eb'; }
  .fontawesome-icon.icon-won:before, .paginationControl .icon-won.first:before,
  .paginationControl .icon-won.previous:before,
  .paginationControl .icon-won.next:before,
  .paginationControl .icon-won.last:before {
    content: '\e8ec'; }
  .fontawesome-icon.icon-bitcoin:before, .paginationControl .icon-bitcoin.first:before,
  .paginationControl .icon-bitcoin.previous:before,
  .paginationControl .icon-bitcoin.next:before,
  .paginationControl .icon-bitcoin.last:before {
    content: '\e8ed'; }
  .fontawesome-icon.icon-sort-name-up:before, .paginationControl .icon-sort-name-up.first:before,
  .paginationControl .icon-sort-name-up.previous:before,
  .paginationControl .icon-sort-name-up.next:before,
  .paginationControl .icon-sort-name-up.last:before {
    content: '\e8ee'; }
  .fontawesome-icon.icon-sort-name-down:before, .paginationControl .icon-sort-name-down.first:before,
  .paginationControl .icon-sort-name-down.previous:before,
  .paginationControl .icon-sort-name-down.next:before,
  .paginationControl .icon-sort-name-down.last:before {
    content: '\e8ef'; }
  .fontawesome-icon.icon-sort-number-up:before, .paginationControl .icon-sort-number-up.first:before,
  .paginationControl .icon-sort-number-up.previous:before,
  .paginationControl .icon-sort-number-up.next:before,
  .paginationControl .icon-sort-number-up.last:before {
    content: '\e8f0'; }
  .fontawesome-icon.icon-sort-number-down:before, .paginationControl .icon-sort-number-down.first:before,
  .paginationControl .icon-sort-number-down.previous:before,
  .paginationControl .icon-sort-number-down.next:before,
  .paginationControl .icon-sort-number-down.last:before {
    content: '\e8f1'; }
  .fontawesome-icon.icon-hammer:before, .paginationControl .icon-hammer.first:before,
  .paginationControl .icon-hammer.previous:before,
  .paginationControl .icon-hammer.next:before,
  .paginationControl .icon-hammer.last:before {
    content: '\e8f2'; }
  .fontawesome-icon.icon-gauge:before, .paginationControl .icon-gauge.first:before,
  .paginationControl .icon-gauge.previous:before,
  .paginationControl .icon-gauge.next:before,
  .paginationControl .icon-gauge.last:before {
    content: '\e8f3'; }
  .fontawesome-icon.icon-sitemap:before, .paginationControl .icon-sitemap.first:before,
  .paginationControl .icon-sitemap.previous:before,
  .paginationControl .icon-sitemap.next:before,
  .paginationControl .icon-sitemap.last:before {
    content: '\e8f4'; }
  .fontawesome-icon.icon-spinner:before, .paginationControl .icon-spinner.first:before,
  .paginationControl .icon-spinner.previous:before,
  .paginationControl .icon-spinner.next:before,
  .paginationControl .icon-spinner.last:before {
    content: '\e8f5'; }
  .fontawesome-icon.icon-coffee:before, .paginationControl .icon-coffee.first:before,
  .paginationControl .icon-coffee.previous:before,
  .paginationControl .icon-coffee.next:before,
  .paginationControl .icon-coffee.last:before {
    content: '\e8f6'; }
  .fontawesome-icon.icon-food:before, .paginationControl .icon-food.first:before,
  .paginationControl .icon-food.previous:before,
  .paginationControl .icon-food.next:before,
  .paginationControl .icon-food.last:before {
    content: '\e8f7'; }
  .fontawesome-icon.icon-beer:before, .paginationControl .icon-beer.first:before,
  .paginationControl .icon-beer.previous:before,
  .paginationControl .icon-beer.next:before,
  .paginationControl .icon-beer.last:before {
    content: '\e8f8'; }
  .fontawesome-icon.icon-user-md:before, .paginationControl .icon-user-md.first:before,
  .paginationControl .icon-user-md.previous:before,
  .paginationControl .icon-user-md.next:before,
  .paginationControl .icon-user-md.last:before {
    content: '\e8f9'; }
  .fontawesome-icon.icon-stethoscope:before, .paginationControl .icon-stethoscope.first:before,
  .paginationControl .icon-stethoscope.previous:before,
  .paginationControl .icon-stethoscope.next:before,
  .paginationControl .icon-stethoscope.last:before {
    content: '\e8fa'; }
  .fontawesome-icon.icon-heartbeat:before, .paginationControl .icon-heartbeat.first:before,
  .paginationControl .icon-heartbeat.previous:before,
  .paginationControl .icon-heartbeat.next:before,
  .paginationControl .icon-heartbeat.last:before {
    content: '\e8fb'; }
  .fontawesome-icon.icon-building-filled:before, .paginationControl .icon-building-filled.first:before,
  .paginationControl .icon-building-filled.previous:before,
  .paginationControl .icon-building-filled.next:before,
  .paginationControl .icon-building-filled.last:before {
    content: '\e8fc'; }
  .fontawesome-icon.icon-bank:before, .paginationControl .icon-bank.first:before,
  .paginationControl .icon-bank.previous:before,
  .paginationControl .icon-bank.next:before,
  .paginationControl .icon-bank.last:before {
    content: '\e8fd'; }
  .fontawesome-icon.icon-smile:before, .paginationControl .icon-smile.first:before,
  .paginationControl .icon-smile.previous:before,
  .paginationControl .icon-smile.next:before,
  .paginationControl .icon-smile.last:before {
    content: '\e8fe'; }
  .fontawesome-icon.icon-frown:before, .paginationControl .icon-frown.first:before,
  .paginationControl .icon-frown.previous:before,
  .paginationControl .icon-frown.next:before,
  .paginationControl .icon-frown.last:before {
    content: '\e8ff'; }
  .fontawesome-icon.icon-meh:before, .paginationControl .icon-meh.first:before,
  .paginationControl .icon-meh.previous:before,
  .paginationControl .icon-meh.next:before,
  .paginationControl .icon-meh.last:before {
    content: '\e900'; }
  .fontawesome-icon.icon-anchor:before, .paginationControl .icon-anchor.first:before,
  .paginationControl .icon-anchor.previous:before,
  .paginationControl .icon-anchor.next:before,
  .paginationControl .icon-anchor.last:before {
    content: '\e901'; }
  .fontawesome-icon.icon-terminal:before, .paginationControl .icon-terminal.first:before,
  .paginationControl .icon-terminal.previous:before,
  .paginationControl .icon-terminal.next:before,
  .paginationControl .icon-terminal.last:before {
    content: '\e902'; }
  .fontawesome-icon.icon-eraser:before, .paginationControl .icon-eraser.first:before,
  .paginationControl .icon-eraser.previous:before,
  .paginationControl .icon-eraser.next:before,
  .paginationControl .icon-eraser.last:before {
    content: '\e903'; }
  .fontawesome-icon.icon-puzzle:before, .paginationControl .icon-puzzle.first:before,
  .paginationControl .icon-puzzle.previous:before,
  .paginationControl .icon-puzzle.next:before,
  .paginationControl .icon-puzzle.last:before {
    content: '\e904'; }
  .fontawesome-icon.icon-shield:before, .paginationControl .icon-shield.first:before,
  .paginationControl .icon-shield.previous:before,
  .paginationControl .icon-shield.next:before,
  .paginationControl .icon-shield.last:before {
    content: '\e905'; }
  .fontawesome-icon.icon-extinguisher:before, .paginationControl .icon-extinguisher.first:before,
  .paginationControl .icon-extinguisher.previous:before,
  .paginationControl .icon-extinguisher.next:before,
  .paginationControl .icon-extinguisher.last:before {
    content: '\e906'; }
  .fontawesome-icon.icon-bullseye:before, .paginationControl .icon-bullseye.first:before,
  .paginationControl .icon-bullseye.previous:before,
  .paginationControl .icon-bullseye.next:before,
  .paginationControl .icon-bullseye.last:before {
    content: '\e907'; }
  .fontawesome-icon.icon-wheelchair:before, .paginationControl .icon-wheelchair.first:before,
  .paginationControl .icon-wheelchair.previous:before,
  .paginationControl .icon-wheelchair.next:before,
  .paginationControl .icon-wheelchair.last:before {
    content: '\e908'; }
  .fontawesome-icon.icon-language:before, .paginationControl .icon-language.first:before,
  .paginationControl .icon-language.previous:before,
  .paginationControl .icon-language.next:before,
  .paginationControl .icon-language.last:before {
    content: '\e909'; }
  .fontawesome-icon.icon-tree:before, .paginationControl .icon-tree.first:before,
  .paginationControl .icon-tree.previous:before,
  .paginationControl .icon-tree.next:before,
  .paginationControl .icon-tree.last:before {
    content: '\e90a'; }
  .fontawesome-icon.icon-database:before, .paginationControl .icon-database.first:before,
  .paginationControl .icon-database.previous:before,
  .paginationControl .icon-database.next:before,
  .paginationControl .icon-database.last:before {
    content: '\e90b'; }
  .fontawesome-icon.icon-server:before, .paginationControl .icon-server.first:before,
  .paginationControl .icon-server.previous:before,
  .paginationControl .icon-server.next:before,
  .paginationControl .icon-server.last:before {
    content: '\e90c'; }
  .fontawesome-icon.icon-lifebuoy:before, .paginationControl .icon-lifebuoy.first:before,
  .paginationControl .icon-lifebuoy.previous:before,
  .paginationControl .icon-lifebuoy.next:before,
  .paginationControl .icon-lifebuoy.last:before {
    content: '\e90d'; }
  .fontawesome-icon.icon-rebel:before, .paginationControl .icon-rebel.first:before,
  .paginationControl .icon-rebel.previous:before,
  .paginationControl .icon-rebel.next:before,
  .paginationControl .icon-rebel.last:before {
    content: '\e90e'; }
  .fontawesome-icon.icon-empire:before, .paginationControl .icon-empire.first:before,
  .paginationControl .icon-empire.previous:before,
  .paginationControl .icon-empire.next:before,
  .paginationControl .icon-empire.last:before {
    content: '\e90f'; }
  .fontawesome-icon.icon-bomb:before, .paginationControl .icon-bomb.first:before,
  .paginationControl .icon-bomb.previous:before,
  .paginationControl .icon-bomb.next:before,
  .paginationControl .icon-bomb.last:before {
    content: '\e910'; }
  .fontawesome-icon.icon-soccer-ball:before, .paginationControl .icon-soccer-ball.first:before,
  .paginationControl .icon-soccer-ball.previous:before,
  .paginationControl .icon-soccer-ball.next:before,
  .paginationControl .icon-soccer-ball.last:before {
    content: '\e911'; }
  .fontawesome-icon.icon-tty:before, .paginationControl .icon-tty.first:before,
  .paginationControl .icon-tty.previous:before,
  .paginationControl .icon-tty.next:before,
  .paginationControl .icon-tty.last:before {
    content: '\e912'; }
  .fontawesome-icon.icon-binoculars:before, .paginationControl .icon-binoculars.first:before,
  .paginationControl .icon-binoculars.previous:before,
  .paginationControl .icon-binoculars.next:before,
  .paginationControl .icon-binoculars.last:before {
    content: '\e913'; }
  .fontawesome-icon.icon-plug:before, .paginationControl .icon-plug.first:before,
  .paginationControl .icon-plug.previous:before,
  .paginationControl .icon-plug.next:before,
  .paginationControl .icon-plug.last:before {
    content: '\e914'; }
  .fontawesome-icon.icon-newspaper:before, .paginationControl .icon-newspaper.first:before,
  .paginationControl .icon-newspaper.previous:before,
  .paginationControl .icon-newspaper.next:before,
  .paginationControl .icon-newspaper.last:before {
    content: '\e915'; }
  .fontawesome-icon.icon-calc:before, .paginationControl .icon-calc.first:before,
  .paginationControl .icon-calc.previous:before,
  .paginationControl .icon-calc.next:before,
  .paginationControl .icon-calc.last:before {
    content: '\e916'; }
  .fontawesome-icon.icon-copyright:before, .paginationControl .icon-copyright.first:before,
  .paginationControl .icon-copyright.previous:before,
  .paginationControl .icon-copyright.next:before,
  .paginationControl .icon-copyright.last:before {
    content: '\e917'; }
  .fontawesome-icon.icon-venus:before, .paginationControl .icon-venus.first:before,
  .paginationControl .icon-venus.previous:before,
  .paginationControl .icon-venus.next:before,
  .paginationControl .icon-venus.last:before {
    content: '\e918'; }
  .fontawesome-icon.icon-mars:before, .paginationControl .icon-mars.first:before,
  .paginationControl .icon-mars.previous:before,
  .paginationControl .icon-mars.next:before,
  .paginationControl .icon-mars.last:before {
    content: '\e919'; }
  .fontawesome-icon.icon-mercury:before, .paginationControl .icon-mercury.first:before,
  .paginationControl .icon-mercury.previous:before,
  .paginationControl .icon-mercury.next:before,
  .paginationControl .icon-mercury.last:before {
    content: '\e91a'; }
  .fontawesome-icon.icon-transgender:before, .paginationControl .icon-transgender.first:before,
  .paginationControl .icon-transgender.previous:before,
  .paginationControl .icon-transgender.next:before,
  .paginationControl .icon-transgender.last:before {
    content: '\e91b'; }
  .fontawesome-icon.icon-transgender-alt:before, .paginationControl .icon-transgender-alt.first:before,
  .paginationControl .icon-transgender-alt.previous:before,
  .paginationControl .icon-transgender-alt.next:before,
  .paginationControl .icon-transgender-alt.last:before {
    content: '\e91c'; }
  .fontawesome-icon.icon-venus-double:before, .paginationControl .icon-venus-double.first:before,
  .paginationControl .icon-venus-double.previous:before,
  .paginationControl .icon-venus-double.next:before,
  .paginationControl .icon-venus-double.last:before {
    content: '\e91d'; }
  .fontawesome-icon.icon-mars-double:before, .paginationControl .icon-mars-double.first:before,
  .paginationControl .icon-mars-double.previous:before,
  .paginationControl .icon-mars-double.next:before,
  .paginationControl .icon-mars-double.last:before {
    content: '\e91e'; }
  .fontawesome-icon.icon-venus-mars:before, .paginationControl .icon-venus-mars.first:before,
  .paginationControl .icon-venus-mars.previous:before,
  .paginationControl .icon-venus-mars.next:before,
  .paginationControl .icon-venus-mars.last:before {
    content: '\e91f'; }
  .fontawesome-icon.icon-mars-stroke:before, .paginationControl .icon-mars-stroke.first:before,
  .paginationControl .icon-mars-stroke.previous:before,
  .paginationControl .icon-mars-stroke.next:before,
  .paginationControl .icon-mars-stroke.last:before {
    content: '\e920'; }
  .fontawesome-icon.icon-mars-stroke-v:before, .paginationControl .icon-mars-stroke-v.first:before,
  .paginationControl .icon-mars-stroke-v.previous:before,
  .paginationControl .icon-mars-stroke-v.next:before,
  .paginationControl .icon-mars-stroke-v.last:before {
    content: '\e921'; }
  .fontawesome-icon.icon-mars-stroke-h:before, .paginationControl .icon-mars-stroke-h.first:before,
  .paginationControl .icon-mars-stroke-h.previous:before,
  .paginationControl .icon-mars-stroke-h.next:before,
  .paginationControl .icon-mars-stroke-h.last:before {
    content: '\e922'; }
  .fontawesome-icon.icon-neuter:before, .paginationControl .icon-neuter.first:before,
  .paginationControl .icon-neuter.previous:before,
  .paginationControl .icon-neuter.next:before,
  .paginationControl .icon-neuter.last:before {
    content: '\e923'; }
  .fontawesome-icon.icon-cc-visa:before, .paginationControl .icon-cc-visa.first:before,
  .paginationControl .icon-cc-visa.previous:before,
  .paginationControl .icon-cc-visa.next:before,
  .paginationControl .icon-cc-visa.last:before {
    content: '\e924'; }
  .fontawesome-icon.icon-cc-mastercard:before, .paginationControl .icon-cc-mastercard.first:before,
  .paginationControl .icon-cc-mastercard.previous:before,
  .paginationControl .icon-cc-mastercard.next:before,
  .paginationControl .icon-cc-mastercard.last:before {
    content: '\e925'; }
  .fontawesome-icon.icon-angellist:before, .paginationControl .icon-angellist.first:before,
  .paginationControl .icon-angellist.previous:before,
  .paginationControl .icon-angellist.next:before,
  .paginationControl .icon-angellist.last:before {
    content: '\e926'; }
  .fontawesome-icon.icon-apple:before, .paginationControl .icon-apple.first:before,
  .paginationControl .icon-apple.previous:before,
  .paginationControl .icon-apple.next:before,
  .paginationControl .icon-apple.last:before {
    content: '\e927'; }
  .fontawesome-icon.icon-behance:before, .paginationControl .icon-behance.first:before,
  .paginationControl .icon-behance.previous:before,
  .paginationControl .icon-behance.next:before,
  .paginationControl .icon-behance.last:before {
    content: '\e928'; }
  .fontawesome-icon.icon-behance-squared:before, .paginationControl .icon-behance-squared.first:before,
  .paginationControl .icon-behance-squared.previous:before,
  .paginationControl .icon-behance-squared.next:before,
  .paginationControl .icon-behance-squared.last:before {
    content: '\e929'; }
  .fontawesome-icon.icon-bitbucket:before, .paginationControl .icon-bitbucket.first:before,
  .paginationControl .icon-bitbucket.previous:before,
  .paginationControl .icon-bitbucket.next:before,
  .paginationControl .icon-bitbucket.last:before {
    content: '\e92a'; }
  .fontawesome-icon.icon-bitbucket-squared:before, .paginationControl .icon-bitbucket-squared.first:before,
  .paginationControl .icon-bitbucket-squared.previous:before,
  .paginationControl .icon-bitbucket-squared.next:before,
  .paginationControl .icon-bitbucket-squared.last:before {
    content: '\e92b'; }
  .fontawesome-icon.icon-buysellads:before, .paginationControl .icon-buysellads.first:before,
  .paginationControl .icon-buysellads.previous:before,
  .paginationControl .icon-buysellads.next:before,
  .paginationControl .icon-buysellads.last:before {
    content: '\e92c'; }
  .fontawesome-icon.icon-cc:before, .paginationControl .icon-cc.first:before,
  .paginationControl .icon-cc.previous:before,
  .paginationControl .icon-cc.next:before,
  .paginationControl .icon-cc.last:before {
    content: '\e92d'; }
  .fontawesome-icon.icon-codeopen:before, .paginationControl .icon-codeopen.first:before,
  .paginationControl .icon-codeopen.previous:before,
  .paginationControl .icon-codeopen.next:before,
  .paginationControl .icon-codeopen.last:before {
    content: '\e92e'; }
  .fontawesome-icon.icon-connectdevelop:before, .paginationControl .icon-connectdevelop.first:before,
  .paginationControl .icon-connectdevelop.previous:before,
  .paginationControl .icon-connectdevelop.next:before,
  .paginationControl .icon-connectdevelop.last:before {
    content: '\e92f'; }
  .fontawesome-icon.icon-css3:before, .paginationControl .icon-css3.first:before,
  .paginationControl .icon-css3.previous:before,
  .paginationControl .icon-css3.next:before,
  .paginationControl .icon-css3.last:before {
    content: '\e930'; }
  .fontawesome-icon.icon-dashcube:before, .paginationControl .icon-dashcube.first:before,
  .paginationControl .icon-dashcube.previous:before,
  .paginationControl .icon-dashcube.next:before,
  .paginationControl .icon-dashcube.last:before {
    content: '\e931'; }
  .fontawesome-icon.icon-delicious:before, .paginationControl .icon-delicious.first:before,
  .paginationControl .icon-delicious.previous:before,
  .paginationControl .icon-delicious.next:before,
  .paginationControl .icon-delicious.last:before {
    content: '\e932'; }
  .fontawesome-icon.icon-deviantart:before, .paginationControl .icon-deviantart.first:before,
  .paginationControl .icon-deviantart.previous:before,
  .paginationControl .icon-deviantart.next:before,
  .paginationControl .icon-deviantart.last:before {
    content: '\e933'; }
  .fontawesome-icon.icon-facebook-official:before, .paginationControl .icon-facebook-official.first:before,
  .paginationControl .icon-facebook-official.previous:before,
  .paginationControl .icon-facebook-official.next:before,
  .paginationControl .icon-facebook-official.last:before {
    content: '\e934'; }
  .fontawesome-icon.icon-flickr:before, .paginationControl .icon-flickr.first:before,
  .paginationControl .icon-flickr.previous:before,
  .paginationControl .icon-flickr.next:before,
  .paginationControl .icon-flickr.last:before {
    content: '\e935'; }
  .fontawesome-icon.icon-forumbee:before, .paginationControl .icon-forumbee.first:before,
  .paginationControl .icon-forumbee.previous:before,
  .paginationControl .icon-forumbee.next:before,
  .paginationControl .icon-forumbee.last:before {
    content: '\e936'; }
  .fontawesome-icon.icon-foursquare:before, .paginationControl .icon-foursquare.first:before,
  .paginationControl .icon-foursquare.previous:before,
  .paginationControl .icon-foursquare.next:before,
  .paginationControl .icon-foursquare.last:before {
    content: '\e937'; }
  .fontawesome-icon.icon-git-squared:before, .paginationControl .icon-git-squared.first:before,
  .paginationControl .icon-git-squared.previous:before,
  .paginationControl .icon-git-squared.next:before,
  .paginationControl .icon-git-squared.last:before {
    content: '\e938'; }
  .fontawesome-icon.icon-git:before, .paginationControl .icon-git.first:before,
  .paginationControl .icon-git.previous:before,
  .paginationControl .icon-git.next:before,
  .paginationControl .icon-git.last:before {
    content: '\e939'; }
  .fontawesome-icon.icon-github:before, .paginationControl .icon-github.first:before,
  .paginationControl .icon-github.previous:before,
  .paginationControl .icon-github.next:before,
  .paginationControl .icon-github.last:before {
    content: '\e93a'; }
  .fontawesome-icon.icon-github-squared:before, .paginationControl .icon-github-squared.first:before,
  .paginationControl .icon-github-squared.previous:before,
  .paginationControl .icon-github-squared.next:before,
  .paginationControl .icon-github-squared.last:before {
    content: '\e93b'; }
  .fontawesome-icon.icon-github-circled:before, .paginationControl .icon-github-circled.first:before,
  .paginationControl .icon-github-circled.previous:before,
  .paginationControl .icon-github-circled.next:before,
  .paginationControl .icon-github-circled.last:before {
    content: '\e93c'; }
  .fontawesome-icon.icon-gittip:before, .paginationControl .icon-gittip.first:before,
  .paginationControl .icon-gittip.previous:before,
  .paginationControl .icon-gittip.next:before,
  .paginationControl .icon-gittip.last:before {
    content: '\e93d'; }
  .fontawesome-icon.icon-google:before, .paginationControl .icon-google.first:before,
  .paginationControl .icon-google.previous:before,
  .paginationControl .icon-google.next:before,
  .paginationControl .icon-google.last:before {
    content: '\e93e'; }
  .fontawesome-icon.icon-gplus:before, .paginationControl .icon-gplus.first:before,
  .paginationControl .icon-gplus.previous:before,
  .paginationControl .icon-gplus.next:before,
  .paginationControl .icon-gplus.last:before {
    content: '\e93f'; }
  .fontawesome-icon.icon-gplus-squared:before, .paginationControl .icon-gplus-squared.first:before,
  .paginationControl .icon-gplus-squared.previous:before,
  .paginationControl .icon-gplus-squared.next:before,
  .paginationControl .icon-gplus-squared.last:before {
    content: '\e940'; }
  .fontawesome-icon.icon-gwallet:before, .paginationControl .icon-gwallet.first:before,
  .paginationControl .icon-gwallet.previous:before,
  .paginationControl .icon-gwallet.next:before,
  .paginationControl .icon-gwallet.last:before {
    content: '\e941'; }
  .fontawesome-icon.icon-lastfm:before, .paginationControl .icon-lastfm.first:before,
  .paginationControl .icon-lastfm.previous:before,
  .paginationControl .icon-lastfm.next:before,
  .paginationControl .icon-lastfm.last:before {
    content: '\e942'; }
  .fontawesome-icon.icon-lastfm-squared:before, .paginationControl .icon-lastfm-squared.first:before,
  .paginationControl .icon-lastfm-squared.previous:before,
  .paginationControl .icon-lastfm-squared.next:before,
  .paginationControl .icon-lastfm-squared.last:before {
    content: '\e943'; }
  .fontawesome-icon.icon-leanpub:before, .paginationControl .icon-leanpub.first:before,
  .paginationControl .icon-leanpub.previous:before,
  .paginationControl .icon-leanpub.next:before,
  .paginationControl .icon-leanpub.last:before {
    content: '\e944'; }
  .fontawesome-icon.icon-linkedin-squared:before, .paginationControl .icon-linkedin-squared.first:before,
  .paginationControl .icon-linkedin-squared.previous:before,
  .paginationControl .icon-linkedin-squared.next:before,
  .paginationControl .icon-linkedin-squared.last:before {
    content: '\e945'; }
  .fontawesome-icon.icon-linux:before, .paginationControl .icon-linux.first:before,
  .paginationControl .icon-linux.previous:before,
  .paginationControl .icon-linux.next:before,
  .paginationControl .icon-linux.last:before {
    content: '\e946'; }
  .fontawesome-icon.icon-linkedin:before, .paginationControl .icon-linkedin.first:before,
  .paginationControl .icon-linkedin.previous:before,
  .paginationControl .icon-linkedin.next:before,
  .paginationControl .icon-linkedin.last:before {
    content: '\e947'; }
  .fontawesome-icon.icon-maxcdn:before, .paginationControl .icon-maxcdn.first:before,
  .paginationControl .icon-maxcdn.previous:before,
  .paginationControl .icon-maxcdn.next:before,
  .paginationControl .icon-maxcdn.last:before {
    content: '\e948'; }
  .fontawesome-icon.icon-meanpath:before, .paginationControl .icon-meanpath.first:before,
  .paginationControl .icon-meanpath.previous:before,
  .paginationControl .icon-meanpath.next:before,
  .paginationControl .icon-meanpath.last:before {
    content: '\e949'; }
  .fontawesome-icon.icon-openid:before, .paginationControl .icon-openid.first:before,
  .paginationControl .icon-openid.previous:before,
  .paginationControl .icon-openid.next:before,
  .paginationControl .icon-openid.last:before {
    content: '\e94a'; }
  .fontawesome-icon.icon-pagelines:before, .paginationControl .icon-pagelines.first:before,
  .paginationControl .icon-pagelines.previous:before,
  .paginationControl .icon-pagelines.next:before,
  .paginationControl .icon-pagelines.last:before {
    content: '\e94b'; }
  .fontawesome-icon.icon-paypal:before, .paginationControl .icon-paypal.first:before,
  .paginationControl .icon-paypal.previous:before,
  .paginationControl .icon-paypal.next:before,
  .paginationControl .icon-paypal.last:before {
    content: '\e94c'; }
  .fontawesome-icon.icon-pied-piper-squared:before, .paginationControl .icon-pied-piper-squared.first:before,
  .paginationControl .icon-pied-piper-squared.previous:before,
  .paginationControl .icon-pied-piper-squared.next:before,
  .paginationControl .icon-pied-piper-squared.last:before {
    content: '\e94d'; }
  .fontawesome-icon.icon-pied-piper-alt:before, .paginationControl .icon-pied-piper-alt.first:before,
  .paginationControl .icon-pied-piper-alt.previous:before,
  .paginationControl .icon-pied-piper-alt.next:before,
  .paginationControl .icon-pied-piper-alt.last:before {
    content: '\e94e'; }
  .fontawesome-icon.icon-pinterest:before, .paginationControl .icon-pinterest.first:before,
  .paginationControl .icon-pinterest.previous:before,
  .paginationControl .icon-pinterest.next:before,
  .paginationControl .icon-pinterest.last:before {
    content: '\e94f'; }
  .fontawesome-icon.icon-sellsy:before, .paginationControl .icon-sellsy.first:before,
  .paginationControl .icon-sellsy.previous:before,
  .paginationControl .icon-sellsy.next:before,
  .paginationControl .icon-sellsy.last:before {
    content: '\e950'; }
  .fontawesome-icon.icon-shirtsinbulk:before, .paginationControl .icon-shirtsinbulk.first:before,
  .paginationControl .icon-shirtsinbulk.previous:before,
  .paginationControl .icon-shirtsinbulk.next:before,
  .paginationControl .icon-shirtsinbulk.last:before {
    content: '\e951'; }
  .fontawesome-icon.icon-simplybuilt:before, .paginationControl .icon-simplybuilt.first:before,
  .paginationControl .icon-simplybuilt.previous:before,
  .paginationControl .icon-simplybuilt.next:before,
  .paginationControl .icon-simplybuilt.last:before {
    content: '\e952'; }
  .fontawesome-icon.icon-skyatlas:before, .paginationControl .icon-skyatlas.first:before,
  .paginationControl .icon-skyatlas.previous:before,
  .paginationControl .icon-skyatlas.next:before,
  .paginationControl .icon-skyatlas.last:before {
    content: '\e953'; }
  .fontawesome-icon.icon-skype:before, .paginationControl .icon-skype.first:before,
  .paginationControl .icon-skype.previous:before,
  .paginationControl .icon-skype.next:before,
  .paginationControl .icon-skype.last:before {
    content: '\e954'; }
  .fontawesome-icon.icon-slack:before, .paginationControl .icon-slack.first:before,
  .paginationControl .icon-slack.previous:before,
  .paginationControl .icon-slack.next:before,
  .paginationControl .icon-slack.last:before {
    content: '\e955'; }
  .fontawesome-icon.icon-slideshare:before, .paginationControl .icon-slideshare.first:before,
  .paginationControl .icon-slideshare.previous:before,
  .paginationControl .icon-slideshare.next:before,
  .paginationControl .icon-slideshare.last:before {
    content: '\e956'; }
  .fontawesome-icon.icon-soundcloud:before, .paginationControl .icon-soundcloud.first:before,
  .paginationControl .icon-soundcloud.previous:before,
  .paginationControl .icon-soundcloud.next:before,
  .paginationControl .icon-soundcloud.last:before {
    content: '\e957'; }
  .fontawesome-icon.icon-spotify:before, .paginationControl .icon-spotify.first:before,
  .paginationControl .icon-spotify.previous:before,
  .paginationControl .icon-spotify.next:before,
  .paginationControl .icon-spotify.last:before {
    content: '\e958'; }
  .fontawesome-icon.icon-stackexchange:before, .paginationControl .icon-stackexchange.first:before,
  .paginationControl .icon-stackexchange.previous:before,
  .paginationControl .icon-stackexchange.next:before,
  .paginationControl .icon-stackexchange.last:before {
    content: '\e959'; }
  .fontawesome-icon.icon-stackoverflow:before, .paginationControl .icon-stackoverflow.first:before,
  .paginationControl .icon-stackoverflow.previous:before,
  .paginationControl .icon-stackoverflow.next:before,
  .paginationControl .icon-stackoverflow.last:before {
    content: '\e95a'; }
  .fontawesome-icon.icon-steam:before, .paginationControl .icon-steam.first:before,
  .paginationControl .icon-steam.previous:before,
  .paginationControl .icon-steam.next:before,
  .paginationControl .icon-steam.last:before {
    content: '\e95b'; }
  .fontawesome-icon.icon-steam-squared:before, .paginationControl .icon-steam-squared.first:before,
  .paginationControl .icon-steam-squared.previous:before,
  .paginationControl .icon-steam-squared.next:before,
  .paginationControl .icon-steam-squared.last:before {
    content: '\e95c'; }
  .fontawesome-icon.icon-stumbleupon:before, .paginationControl .icon-stumbleupon.first:before,
  .paginationControl .icon-stumbleupon.previous:before,
  .paginationControl .icon-stumbleupon.next:before,
  .paginationControl .icon-stumbleupon.last:before {
    content: '\e95d'; }
  .fontawesome-icon.icon-twitter-squared:before, .paginationControl .icon-twitter-squared.first:before,
  .paginationControl .icon-twitter-squared.previous:before,
  .paginationControl .icon-twitter-squared.next:before,
  .paginationControl .icon-twitter-squared.last:before {
    content: '\e95e'; }
  .fontawesome-icon.icon-twitter:before, .paginationControl .icon-twitter.first:before,
  .paginationControl .icon-twitter.previous:before,
  .paginationControl .icon-twitter.next:before,
  .paginationControl .icon-twitter.last:before {
    content: '\e95f'; }
  .fontawesome-icon.icon-vimeo-squared:before, .paginationControl .icon-vimeo-squared.first:before,
  .paginationControl .icon-vimeo-squared.previous:before,
  .paginationControl .icon-vimeo-squared.next:before,
  .paginationControl .icon-vimeo-squared.last:before {
    content: '\e960'; }
  .fontawesome-icon.icon-vine:before, .paginationControl .icon-vine.first:before,
  .paginationControl .icon-vine.previous:before,
  .paginationControl .icon-vine.next:before,
  .paginationControl .icon-vine.last:before {
    content: '\e961'; }
  .fontawesome-icon.icon-vkontakte:before, .paginationControl .icon-vkontakte.first:before,
  .paginationControl .icon-vkontakte.previous:before,
  .paginationControl .icon-vkontakte.next:before,
  .paginationControl .icon-vkontakte.last:before {
    content: '\e962'; }
  .fontawesome-icon.icon-whatsapp:before, .paginationControl .icon-whatsapp.first:before,
  .paginationControl .icon-whatsapp.previous:before,
  .paginationControl .icon-whatsapp.next:before,
  .paginationControl .icon-whatsapp.last:before {
    content: '\e963'; }
  .fontawesome-icon.icon-wechat:before, .paginationControl .icon-wechat.first:before,
  .paginationControl .icon-wechat.previous:before,
  .paginationControl .icon-wechat.next:before,
  .paginationControl .icon-wechat.last:before {
    content: '\e964'; }
  .fontawesome-icon.icon-weibo:before, .paginationControl .icon-weibo.first:before,
  .paginationControl .icon-weibo.previous:before,
  .paginationControl .icon-weibo.next:before,
  .paginationControl .icon-weibo.last:before {
    content: '\e965'; }
  .fontawesome-icon.icon-windows:before, .paginationControl .icon-windows.first:before,
  .paginationControl .icon-windows.previous:before,
  .paginationControl .icon-windows.next:before,
  .paginationControl .icon-windows.last:before {
    content: '\e966'; }
  .fontawesome-icon.icon-wordpress:before, .paginationControl .icon-wordpress.first:before,
  .paginationControl .icon-wordpress.previous:before,
  .paginationControl .icon-wordpress.next:before,
  .paginationControl .icon-wordpress.last:before {
    content: '\e967'; }
  .fontawesome-icon.icon-xing:before, .paginationControl .icon-xing.first:before,
  .paginationControl .icon-xing.previous:before,
  .paginationControl .icon-xing.next:before,
  .paginationControl .icon-xing.last:before {
    content: '\e968'; }
  .fontawesome-icon.icon-xing-squared:before, .paginationControl .icon-xing-squared.first:before,
  .paginationControl .icon-xing-squared.previous:before,
  .paginationControl .icon-xing-squared.next:before,
  .paginationControl .icon-xing-squared.last:before {
    content: '\e969'; }
  .fontawesome-icon.icon-yelp:before, .paginationControl .icon-yelp.first:before,
  .paginationControl .icon-yelp.previous:before,
  .paginationControl .icon-yelp.next:before,
  .paginationControl .icon-yelp.last:before {
    content: '\e96a'; }
  .fontawesome-icon.icon-youtube:before, .paginationControl .icon-youtube.first:before,
  .paginationControl .icon-youtube.previous:before,
  .paginationControl .icon-youtube.next:before,
  .paginationControl .icon-youtube.last:before {
    content: '\e96b'; }
  .fontawesome-icon.icon-user-times:before, .paginationControl .icon-user-times.first:before,
  .paginationControl .icon-user-times.previous:before,
  .paginationControl .icon-user-times.next:before,
  .paginationControl .icon-user-times.last:before {
    content: '\e996'; }
  .fontawesome-icon.icon-users-1:before, .paginationControl .icon-users-1.first:before,
  .paginationControl .icon-users-1.previous:before,
  .paginationControl .icon-users-1.next:before,
  .paginationControl .icon-users-1.last:before {
    content: '\e997'; }
  .fontawesome-icon.icon-male:before, .paginationControl .icon-male.first:before,
  .paginationControl .icon-male.previous:before,
  .paginationControl .icon-male.next:before,
  .paginationControl .icon-male.last:before {
    content: '\e998'; }
  .fontawesome-icon.icon-female:before, .paginationControl .icon-female.first:before,
  .paginationControl .icon-female.previous:before,
  .paginationControl .icon-female.next:before,
  .paginationControl .icon-female.last:before {
    content: '\e999'; }
  .fontawesome-icon.icon-child:before, .paginationControl .icon-child.first:before,
  .paginationControl .icon-child.previous:before,
  .paginationControl .icon-child.next:before,
  .paginationControl .icon-child.last:before {
    content: '\e99a'; }
  .fontawesome-icon.icon-user-secret:before, .paginationControl .icon-user-secret.first:before,
  .paginationControl .icon-user-secret.previous:before,
  .paginationControl .icon-user-secret.next:before,
  .paginationControl .icon-user-secret.last:before {
    content: '\e99b'; }
  .fontawesome-icon.icon-cancel-circled2:before, .paginationControl .icon-cancel-circled2.first:before,
  .paginationControl .icon-cancel-circled2.previous:before,
  .paginationControl .icon-cancel-circled2.next:before,
  .paginationControl .icon-cancel-circled2.last:before {
    content: '\e99c'; }
  .fontawesome-icon.icon-plus-1:before, .paginationControl .icon-plus-1.first:before,
  .paginationControl .icon-plus-1.previous:before,
  .paginationControl .icon-plus-1.next:before,
  .paginationControl .icon-plus-1.last:before {
    content: '\e99d'; }
  .fontawesome-icon.icon-plus-circled-1:before, .paginationControl .icon-plus-circled-1.first:before,
  .paginationControl .icon-plus-circled-1.previous:before,
  .paginationControl .icon-plus-circled-1.next:before,
  .paginationControl .icon-plus-circled-1.last:before {
    content: '\e99e'; }
  .fontawesome-icon.icon-plus-squared-1:before, .paginationControl .icon-plus-squared-1.first:before,
  .paginationControl .icon-plus-squared-1.previous:before,
  .paginationControl .icon-plus-squared-1.next:before,
  .paginationControl .icon-plus-squared-1.last:before {
    content: '\e99f'; }
  .fontawesome-icon.icon-plus-squared-alt:before, .paginationControl .icon-plus-squared-alt.first:before,
  .paginationControl .icon-plus-squared-alt.previous:before,
  .paginationControl .icon-plus-squared-alt.next:before,
  .paginationControl .icon-plus-squared-alt.last:before {
    content: '\e9a0'; }
  .fontawesome-icon.icon-minus-1:before, .paginationControl .icon-minus-1.first:before,
  .paginationControl .icon-minus-1.previous:before,
  .paginationControl .icon-minus-1.next:before,
  .paginationControl .icon-minus-1.last:before {
    content: '\e9a1'; }
  .fontawesome-icon.icon-lock-open:before, .paginationControl .icon-lock-open.first:before,
  .paginationControl .icon-lock-open.previous:before,
  .paginationControl .icon-lock-open.next:before,
  .paginationControl .icon-lock-open.last:before {
    content: '\e9a2'; }
  .fontawesome-icon.icon-lock-open-alt:before, .paginationControl .icon-lock-open-alt.first:before,
  .paginationControl .icon-lock-open-alt.previous:before,
  .paginationControl .icon-lock-open-alt.next:before,
  .paginationControl .icon-lock-open-alt.last:before {
    content: '\e9a3'; }
  .fontawesome-icon.icon-pin:before, .paginationControl .icon-pin.first:before,
  .paginationControl .icon-pin.previous:before,
  .paginationControl .icon-pin.next:before,
  .paginationControl .icon-pin.last:before {
    content: '\e9a4'; }
  .fontawesome-icon.icon-eye:before, .paginationControl .icon-eye.first:before,
  .paginationControl .icon-eye.previous:before,
  .paginationControl .icon-eye.next:before,
  .paginationControl .icon-eye.last:before {
    content: '\e9a5'; }
  .fontawesome-icon.icon-eye-off:before, .paginationControl .icon-eye-off.first:before,
  .paginationControl .icon-eye-off.previous:before,
  .paginationControl .icon-eye-off.next:before,
  .paginationControl .icon-eye-off.last:before {
    content: '\e9a6'; }
  .fontawesome-icon.icon-tag:before, .paginationControl .icon-tag.first:before,
  .paginationControl .icon-tag.previous:before,
  .paginationControl .icon-tag.next:before,
  .paginationControl .icon-tag.last:before {
    content: '\e9a7'; }
  .fontawesome-icon.icon-reply-1:before, .paginationControl .icon-reply-1.first:before,
  .paginationControl .icon-reply-1.previous:before,
  .paginationControl .icon-reply-1.next:before,
  .paginationControl .icon-reply-1.last:before {
    content: '\e9a8'; }
  .fontawesome-icon.icon-reply-all-1:before, .paginationControl .icon-reply-all-1.first:before,
  .paginationControl .icon-reply-all-1.previous:before,
  .paginationControl .icon-reply-all-1.next:before,
  .paginationControl .icon-reply-all-1.last:before {
    content: '\e9a9'; }
  .fontawesome-icon.icon-forward-1:before, .paginationControl .icon-forward-1.first:before,
  .paginationControl .icon-forward-1.previous:before,
  .paginationControl .icon-forward-1.next:before,
  .paginationControl .icon-forward-1.last:before {
    content: '\e9aa'; }
  .fontawesome-icon.icon-quote-left:before, .paginationControl .icon-quote-left.first:before,
  .paginationControl .icon-quote-left.previous:before,
  .paginationControl .icon-quote-left.next:before,
  .paginationControl .icon-quote-left.last:before {
    content: '\e9ab'; }
  .fontawesome-icon.icon-quote-right:before, .paginationControl .icon-quote-right.first:before,
  .paginationControl .icon-quote-right.previous:before,
  .paginationControl .icon-quote-right.next:before,
  .paginationControl .icon-quote-right.last:before {
    content: '\e9ac'; }
  .fontawesome-icon.icon-code-1:before, .paginationControl .icon-code-1.first:before,
  .paginationControl .icon-code-1.previous:before,
  .paginationControl .icon-code-1.next:before,
  .paginationControl .icon-code-1.last:before {
    content: '\e9ad'; }
  .fontawesome-icon.icon-chat-empty:before, .paginationControl .icon-chat-empty.first:before,
  .paginationControl .icon-chat-empty.previous:before,
  .paginationControl .icon-chat-empty.next:before,
  .paginationControl .icon-chat-empty.last:before {
    content: '\e9ae'; }
  .fontawesome-icon.icon-bell:before, .paginationControl .icon-bell.first:before,
  .paginationControl .icon-bell.previous:before,
  .paginationControl .icon-bell.next:before,
  .paginationControl .icon-bell.last:before {
    content: '\e9af'; }
  .fontawesome-icon.icon-bell-alt:before, .paginationControl .icon-bell-alt.first:before,
  .paginationControl .icon-bell-alt.previous:before,
  .paginationControl .icon-bell-alt.next:before,
  .paginationControl .icon-bell-alt.last:before {
    content: '\e9b0'; }
  .fontawesome-icon.icon-bell-off:before, .paginationControl .icon-bell-off.first:before,
  .paginationControl .icon-bell-off.previous:before,
  .paginationControl .icon-bell-off.next:before,
  .paginationControl .icon-bell-off.last:before {
    content: '\e9b1'; }
  .fontawesome-icon.icon-bell-off-empty:before, .paginationControl .icon-bell-off-empty.first:before,
  .paginationControl .icon-bell-off-empty.previous:before,
  .paginationControl .icon-bell-off-empty.next:before,
  .paginationControl .icon-bell-off-empty.last:before {
    content: '\e9b2'; }
  .fontawesome-icon.icon-attention-alt:before, .paginationControl .icon-attention-alt.first:before,
  .paginationControl .icon-attention-alt.previous:before,
  .paginationControl .icon-attention-alt.next:before,
  .paginationControl .icon-attention-alt.last:before {
    content: '\e9b3'; }
  .fontawesome-icon.icon-file-excel:before, .paginationControl .icon-file-excel.first:before,
  .paginationControl .icon-file-excel.previous:before,
  .paginationControl .icon-file-excel.next:before,
  .paginationControl .icon-file-excel.last:before {
    content: '\e9b4'; }
  .fontawesome-icon.icon-file-powerpoint:before, .paginationControl .icon-file-powerpoint.first:before,
  .paginationControl .icon-file-powerpoint.previous:before,
  .paginationControl .icon-file-powerpoint.next:before,
  .paginationControl .icon-file-powerpoint.last:before {
    content: '\e9b5'; }
  .fontawesome-icon.icon-file-image:before, .paginationControl .icon-file-image.first:before,
  .paginationControl .icon-file-image.previous:before,
  .paginationControl .icon-file-image.next:before,
  .paginationControl .icon-file-image.last:before {
    content: '\e9b6'; }
  .fontawesome-icon.icon-file-archive:before, .paginationControl .icon-file-archive.first:before,
  .paginationControl .icon-file-archive.previous:before,
  .paginationControl .icon-file-archive.next:before,
  .paginationControl .icon-file-archive.last:before {
    content: '\e9b7'; }
  .fontawesome-icon.icon-file-audio:before, .paginationControl .icon-file-audio.first:before,
  .paginationControl .icon-file-audio.previous:before,
  .paginationControl .icon-file-audio.next:before,
  .paginationControl .icon-file-audio.last:before {
    content: '\e9b8'; }
  .fontawesome-icon.icon-file-video:before, .paginationControl .icon-file-video.first:before,
  .paginationControl .icon-file-video.previous:before,
  .paginationControl .icon-file-video.next:before,
  .paginationControl .icon-file-video.last:before {
    content: '\e9b9'; }
  .fontawesome-icon.icon-wrench:before, .paginationControl .icon-wrench.first:before,
  .paginationControl .icon-wrench.previous:before,
  .paginationControl .icon-wrench.next:before,
  .paginationControl .icon-wrench.last:before {
    content: '\e9ba'; }
  .fontawesome-icon.icon-sliders:before, .paginationControl .icon-sliders.first:before,
  .paginationControl .icon-sliders.previous:before,
  .paginationControl .icon-sliders.next:before,
  .paginationControl .icon-sliders.last:before {
    content: '\e9bb'; }
  .fontawesome-icon.icon-basket:before, .paginationControl .icon-basket.first:before,
  .paginationControl .icon-basket.previous:before,
  .paginationControl .icon-basket.next:before,
  .paginationControl .icon-basket.last:before {
    content: '\e9bc'; }
  .fontawesome-icon.icon-cart-plus:before, .paginationControl .icon-cart-plus.first:before,
  .paginationControl .icon-cart-plus.previous:before,
  .paginationControl .icon-cart-plus.next:before,
  .paginationControl .icon-cart-plus.last:before {
    content: '\e9bd'; }
  .fontawesome-icon.icon-cart-arrow-down:before, .paginationControl .icon-cart-arrow-down.first:before,
  .paginationControl .icon-cart-arrow-down.previous:before,
  .paginationControl .icon-cart-arrow-down.next:before,
  .paginationControl .icon-cart-arrow-down.last:before {
    content: '\e9be'; }
  .fontawesome-icon.icon-calendar:before, .paginationControl .icon-calendar.first:before,
  .paginationControl .icon-calendar.previous:before,
  .paginationControl .icon-calendar.next:before,
  .paginationControl .icon-calendar.last:before {
    content: '\e9bf'; }
  .fontawesome-icon.icon-resize-small:before, .paginationControl .icon-resize-small.first:before,
  .paginationControl .icon-resize-small.previous:before,
  .paginationControl .icon-resize-small.next:before,
  .paginationControl .icon-resize-small.last:before {
    content: '\e9c0'; }
  .fontawesome-icon.icon-resize-vertical:before, .paginationControl .icon-resize-vertical.first:before,
  .paginationControl .icon-resize-vertical.previous:before,
  .paginationControl .icon-resize-vertical.next:before,
  .paginationControl .icon-resize-vertical.last:before {
    content: '\e9c1'; }
  .fontawesome-icon.icon-resize-horizontal:before, .paginationControl .icon-resize-horizontal.first:before,
  .paginationControl .icon-resize-horizontal.previous:before,
  .paginationControl .icon-resize-horizontal.next:before,
  .paginationControl .icon-resize-horizontal.last:before {
    content: '\e9c2'; }
  .fontawesome-icon.icon-move:before, .paginationControl .icon-move.first:before,
  .paginationControl .icon-move.previous:before,
  .paginationControl .icon-move.next:before,
  .paginationControl .icon-move.last:before {
    content: '\e9c3'; }
  .fontawesome-icon.icon-zoom-in:before, .paginationControl .icon-zoom-in.first:before,
  .paginationControl .icon-zoom-in.previous:before,
  .paginationControl .icon-zoom-in.next:before,
  .paginationControl .icon-zoom-in.last:before {
    content: '\e9c4'; }
  .fontawesome-icon.icon-zoom-out:before, .paginationControl .icon-zoom-out.first:before,
  .paginationControl .icon-zoom-out.previous:before,
  .paginationControl .icon-zoom-out.next:before,
  .paginationControl .icon-zoom-out.last:before {
    content: '\e9c5'; }
  .fontawesome-icon.icon-angle-up:before, .paginationControl .icon-angle-up.first:before,
  .paginationControl .icon-angle-up.previous:before,
  .paginationControl .icon-angle-up.next:before,
  .paginationControl .icon-angle-up.last:before {
    content: '\e9c6'; }
  .fontawesome-icon.icon-angle-down:before, .paginationControl .icon-angle-down.first:before,
  .paginationControl .icon-angle-down.previous:before,
  .paginationControl .icon-angle-down.next:before,
  .paginationControl .icon-angle-down.last:before {
    content: '\e9c7'; }
  .fontawesome-icon.icon-angle-circled-left:before, .paginationControl .icon-angle-circled-left.first:before,
  .paginationControl .icon-angle-circled-left.previous:before,
  .paginationControl .icon-angle-circled-left.next:before,
  .paginationControl .icon-angle-circled-left.last:before {
    content: '\e9c8'; }
  .fontawesome-icon.icon-angle-circled-right:before, .paginationControl .icon-angle-circled-right.first:before,
  .paginationControl .icon-angle-circled-right.previous:before,
  .paginationControl .icon-angle-circled-right.next:before,
  .paginationControl .icon-angle-circled-right.last:before {
    content: '\e9c9'; }
  .fontawesome-icon.icon-angle-circled-up:before, .paginationControl .icon-angle-circled-up.first:before,
  .paginationControl .icon-angle-circled-up.previous:before,
  .paginationControl .icon-angle-circled-up.next:before,
  .paginationControl .icon-angle-circled-up.last:before {
    content: '\e9ca'; }
  .fontawesome-icon.icon-angle-circled-down:before, .paginationControl .icon-angle-circled-down.first:before,
  .paginationControl .icon-angle-circled-down.previous:before,
  .paginationControl .icon-angle-circled-down.next:before,
  .paginationControl .icon-angle-circled-down.last:before {
    content: '\e9cb'; }
  .fontawesome-icon.icon-up-hand:before, .paginationControl .icon-up-hand.first:before,
  .paginationControl .icon-up-hand.previous:before,
  .paginationControl .icon-up-hand.next:before,
  .paginationControl .icon-up-hand.last:before {
    content: '\e9cc'; }
  .fontawesome-icon.icon-down-hand:before, .paginationControl .icon-down-hand.first:before,
  .paginationControl .icon-down-hand.previous:before,
  .paginationControl .icon-down-hand.next:before,
  .paginationControl .icon-down-hand.last:before {
    content: '\e9cd'; }
  .fontawesome-icon.icon-left-circled:before, .paginationControl .icon-left-circled.first:before,
  .paginationControl .icon-left-circled.previous:before,
  .paginationControl .icon-left-circled.next:before,
  .paginationControl .icon-left-circled.last:before {
    content: '\e9ce'; }
  .fontawesome-icon.icon-right-circled:before, .paginationControl .icon-right-circled.first:before,
  .paginationControl .icon-right-circled.previous:before,
  .paginationControl .icon-right-circled.next:before,
  .paginationControl .icon-right-circled.last:before {
    content: '\e9cf'; }
  .fontawesome-icon.icon-up-circled:before, .paginationControl .icon-up-circled.first:before,
  .paginationControl .icon-up-circled.previous:before,
  .paginationControl .icon-up-circled.next:before,
  .paginationControl .icon-up-circled.last:before {
    content: '\e9d0'; }
  .fontawesome-icon.icon-down-circled:before, .paginationControl .icon-down-circled.first:before,
  .paginationControl .icon-down-circled.previous:before,
  .paginationControl .icon-down-circled.next:before,
  .paginationControl .icon-down-circled.last:before {
    content: '\e9d1'; }
  .fontawesome-icon.icon-play-circled2:before, .paginationControl .icon-play-circled2.first:before,
  .paginationControl .icon-play-circled2.previous:before,
  .paginationControl .icon-play-circled2.next:before,
  .paginationControl .icon-play-circled2.last:before {
    content: '\e9d2'; }
  .fontawesome-icon.icon-stop:before, .paginationControl .icon-stop.first:before,
  .paginationControl .icon-stop.previous:before,
  .paginationControl .icon-stop.next:before,
  .paginationControl .icon-stop.last:before {
    content: '\e9d3'; }
  .fontawesome-icon.icon-pause:before, .paginationControl .icon-pause.first:before,
  .paginationControl .icon-pause.previous:before,
  .paginationControl .icon-pause.next:before,
  .paginationControl .icon-pause.last:before {
    content: '\e9d4'; }
  .fontawesome-icon.icon-to-end:before, .paginationControl .icon-to-end.first:before,
  .paginationControl .icon-to-end.previous:before,
  .paginationControl .icon-to-end.next:before,
  .paginationControl .last:before {
    content: '\e9d5'; }
  .fontawesome-icon.icon-to-end-alt:before, .paginationControl .icon-to-end-alt.first:before,
  .paginationControl .icon-to-end-alt.previous:before,
  .paginationControl .icon-to-end-alt.next:before,
  .paginationControl .icon-to-end-alt.last:before {
    content: '\e9d6'; }
  .fontawesome-icon.icon-to-start:before, .paginationControl .first:before,
  .paginationControl .icon-to-start.previous:before,
  .paginationControl .icon-to-start.next:before,
  .paginationControl .icon-to-start.last:before {
    content: '\e9d7'; }
  .fontawesome-icon.icon-sun:before, .paginationControl .icon-sun.first:before,
  .paginationControl .icon-sun.previous:before,
  .paginationControl .icon-sun.next:before,
  .paginationControl .icon-sun.last:before {
    content: '\e9d8'; }
  .fontawesome-icon.icon-cloud:before, .paginationControl .icon-cloud.first:before,
  .paginationControl .icon-cloud.previous:before,
  .paginationControl .icon-cloud.next:before,
  .paginationControl .icon-cloud.last:before {
    content: '\e9d9'; }
  .fontawesome-icon.icon-flash:before, .paginationControl .icon-flash.first:before,
  .paginationControl .icon-flash.previous:before,
  .paginationControl .icon-flash.next:before,
  .paginationControl .icon-flash.last:before {
    content: '\e9da'; }
  .fontawesome-icon.icon-moon:before, .paginationControl .icon-moon.first:before,
  .paginationControl .icon-moon.previous:before,
  .paginationControl .icon-moon.next:before,
  .paginationControl .icon-moon.last:before {
    content: '\e9db'; }
  .fontawesome-icon.icon-umbrella:before, .paginationControl .icon-umbrella.first:before,
  .paginationControl .icon-umbrella.previous:before,
  .paginationControl .icon-umbrella.next:before,
  .paginationControl .icon-umbrella.last:before {
    content: '\e9dc'; }
  .fontawesome-icon.icon-flight:before, .paginationControl .icon-flight.first:before,
  .paginationControl .icon-flight.previous:before,
  .paginationControl .icon-flight.next:before,
  .paginationControl .icon-flight.last:before {
    content: '\e9dd'; }
  .fontawesome-icon.icon-align-center:before, .paginationControl .icon-align-center.first:before,
  .paginationControl .icon-align-center.previous:before,
  .paginationControl .icon-align-center.next:before,
  .paginationControl .icon-align-center.last:before {
    content: '\e9de'; }
  .fontawesome-icon.icon-align-right:before, .paginationControl .icon-align-right.first:before,
  .paginationControl .icon-align-right.previous:before,
  .paginationControl .icon-align-right.next:before,
  .paginationControl .icon-align-right.last:before {
    content: '\e9df'; }
  .fontawesome-icon.icon-align-justify:before, .paginationControl .icon-align-justify.first:before,
  .paginationControl .icon-align-justify.previous:before,
  .paginationControl .icon-align-justify.next:before,
  .paginationControl .icon-align-justify.last:before {
    content: '\e9e0'; }
  .fontawesome-icon.icon-list:before, .paginationControl .icon-list.first:before,
  .paginationControl .icon-list.previous:before,
  .paginationControl .icon-list.next:before,
  .paginationControl .icon-list.last:before {
    content: '\e9e1'; }
  .fontawesome-icon.icon-indent-left:before, .paginationControl .icon-indent-left.first:before,
  .paginationControl .icon-indent-left.previous:before,
  .paginationControl .icon-indent-left.next:before,
  .paginationControl .icon-indent-left.last:before {
    content: '\e9e2'; }
  .fontawesome-icon.icon-indent-right:before, .paginationControl .icon-indent-right.first:before,
  .paginationControl .icon-indent-right.previous:before,
  .paginationControl .icon-indent-right.next:before,
  .paginationControl .icon-indent-right.last:before {
    content: '\e9e3'; }
  .fontawesome-icon.icon-ellipsis-vert:before, .paginationControl .icon-ellipsis-vert.first:before,
  .paginationControl .icon-ellipsis-vert.previous:before,
  .paginationControl .icon-ellipsis-vert.next:before,
  .paginationControl .icon-ellipsis-vert.last:before {
    content: '\e9e4'; }
  .fontawesome-icon.icon-off:before, .paginationControl .icon-off.first:before,
  .paginationControl .icon-off.previous:before,
  .paginationControl .icon-off.next:before,
  .paginationControl .icon-off.last:before {
    content: '\e9e5'; }
  .fontawesome-icon.icon-road:before, .paginationControl .icon-road.first:before,
  .paginationControl .icon-road.previous:before,
  .paginationControl .icon-road.next:before,
  .paginationControl .icon-road.last:before {
    content: '\e9e6'; }
  .fontawesome-icon.icon-list-alt:before, .paginationControl .icon-list-alt.first:before,
  .paginationControl .icon-list-alt.previous:before,
  .paginationControl .icon-list-alt.next:before,
  .paginationControl .icon-list-alt.last:before {
    content: '\e9e7'; }
  .fontawesome-icon.icon-qrcode:before, .paginationControl .icon-qrcode.first:before,
  .paginationControl .icon-qrcode.previous:before,
  .paginationControl .icon-qrcode.next:before,
  .paginationControl .icon-qrcode.last:before {
    content: '\e9e8'; }
  .fontawesome-icon.icon-barcode:before, .paginationControl .icon-barcode.first:before,
  .paginationControl .icon-barcode.previous:before,
  .paginationControl .icon-barcode.next:before,
  .paginationControl .icon-barcode.last:before {
    content: '\e9e9'; }
  .fontawesome-icon.icon-fire:before, .paginationControl .icon-fire.first:before,
  .paginationControl .icon-fire.previous:before,
  .paginationControl .icon-fire.next:before,
  .paginationControl .icon-fire.last:before {
    content: '\e9ea'; }
  .fontawesome-icon.icon-magnet:before, .paginationControl .icon-magnet.first:before,
  .paginationControl .icon-magnet.previous:before,
  .paginationControl .icon-magnet.next:before,
  .paginationControl .icon-magnet.last:before {
    content: '\e9eb'; }
  .fontawesome-icon.icon-chart-bar:before, .paginationControl .icon-chart-bar.first:before,
  .paginationControl .icon-chart-bar.previous:before,
  .paginationControl .icon-chart-bar.next:before,
  .paginationControl .icon-chart-bar.last:before {
    content: '\e9ec'; }
  .fontawesome-icon.icon-chart-area:before, .paginationControl .icon-chart-area.first:before,
  .paginationControl .icon-chart-area.previous:before,
  .paginationControl .icon-chart-area.next:before,
  .paginationControl .icon-chart-area.last:before {
    content: '\e9ed'; }
  .fontawesome-icon.icon-chart-pie:before, .paginationControl .icon-chart-pie.first:before,
  .paginationControl .icon-chart-pie.previous:before,
  .paginationControl .icon-chart-pie.next:before,
  .paginationControl .icon-chart-pie.last:before {
    content: '\e9ee'; }
  .fontawesome-icon.icon-chart-line:before, .paginationControl .icon-chart-line.first:before,
  .paginationControl .icon-chart-line.previous:before,
  .paginationControl .icon-chart-line.next:before,
  .paginationControl .icon-chart-line.last:before {
    content: '\e9ef'; }
  .fontawesome-icon.icon-cab:before, .paginationControl .icon-cab.first:before,
  .paginationControl .icon-cab.previous:before,
  .paginationControl .icon-cab.next:before,
  .paginationControl .icon-cab.last:before {
    content: '\e9f0'; }
  .fontawesome-icon.icon-taxi:before, .paginationControl .icon-taxi.first:before,
  .paginationControl .icon-taxi.previous:before,
  .paginationControl .icon-taxi.next:before,
  .paginationControl .icon-taxi.last:before {
    content: '\e9f1'; }
  .fontawesome-icon.icon-truck:before, .paginationControl .icon-truck.first:before,
  .paginationControl .icon-truck.previous:before,
  .paginationControl .icon-truck.next:before,
  .paginationControl .icon-truck.last:before {
    content: '\e9f2'; }
  .fontawesome-icon.icon-bus:before, .paginationControl .icon-bus.first:before,
  .paginationControl .icon-bus.previous:before,
  .paginationControl .icon-bus.next:before,
  .paginationControl .icon-bus.last:before {
    content: '\e9f3'; }
  .fontawesome-icon.icon-bicycle:before, .paginationControl .icon-bicycle.first:before,
  .paginationControl .icon-bicycle.previous:before,
  .paginationControl .icon-bicycle.next:before,
  .paginationControl .icon-bicycle.last:before {
    content: '\e9f4'; }
  .fontawesome-icon.icon-motorcycle:before, .paginationControl .icon-motorcycle.first:before,
  .paginationControl .icon-motorcycle.previous:before,
  .paginationControl .icon-motorcycle.next:before,
  .paginationControl .icon-motorcycle.last:before {
    content: '\e9f5'; }
  .fontawesome-icon.icon-viacoin:before, .paginationControl .icon-viacoin.first:before,
  .paginationControl .icon-viacoin.previous:before,
  .paginationControl .icon-viacoin.next:before,
  .paginationControl .icon-viacoin.last:before {
    content: '\e9f6'; }
  .fontawesome-icon.icon-sort:before, .paginationControl .icon-sort.first:before,
  .paginationControl .icon-sort.previous:before,
  .paginationControl .icon-sort.next:before,
  .paginationControl .icon-sort.last:before {
    content: '\e9f7'; }
  .fontawesome-icon.icon-sort-down:before, .paginationControl .icon-sort-down.first:before,
  .paginationControl .icon-sort-down.previous:before,
  .paginationControl .icon-sort-down.next:before,
  .paginationControl .icon-sort-down.last:before {
    content: '\e9f8'; }
  .fontawesome-icon.icon-sort-up:before, .paginationControl .icon-sort-up.first:before,
  .paginationControl .icon-sort-up.previous:before,
  .paginationControl .icon-sort-up.next:before,
  .paginationControl .icon-sort-up.last:before {
    content: '\e9f9'; }
  .fontawesome-icon.icon-sort-alt-up:before, .paginationControl .icon-sort-alt-up.first:before,
  .paginationControl .icon-sort-alt-up.previous:before,
  .paginationControl .icon-sort-alt-up.next:before,
  .paginationControl .icon-sort-alt-up.last:before {
    content: '\e9fa'; }
  .fontawesome-icon.icon-sort-alt-down:before, .paginationControl .icon-sort-alt-down.first:before,
  .paginationControl .icon-sort-alt-down.previous:before,
  .paginationControl .icon-sort-alt-down.next:before,
  .paginationControl .icon-sort-alt-down.last:before {
    content: '\e9fb'; }
  .fontawesome-icon.icon-ambulance:before, .paginationControl .icon-ambulance.first:before,
  .paginationControl .icon-ambulance.previous:before,
  .paginationControl .icon-ambulance.next:before,
  .paginationControl .icon-ambulance.last:before {
    content: '\e9fc'; }
  .fontawesome-icon.icon-medkit:before, .paginationControl .icon-medkit.first:before,
  .paginationControl .icon-medkit.previous:before,
  .paginationControl .icon-medkit.next:before,
  .paginationControl .icon-medkit.last:before {
    content: '\e9fd'; }
  .fontawesome-icon.icon-h-sigh:before, .paginationControl .icon-h-sigh.first:before,
  .paginationControl .icon-h-sigh.previous:before,
  .paginationControl .icon-h-sigh.next:before,
  .paginationControl .icon-h-sigh.last:before {
    content: '\e9fe'; }
  .fontawesome-icon.icon-bed:before, .paginationControl .icon-bed.first:before,
  .paginationControl .icon-bed.previous:before,
  .paginationControl .icon-bed.next:before,
  .paginationControl .icon-bed.last:before {
    content: '\e9ff'; }
  .fontawesome-icon.icon-hospital:before, .paginationControl .icon-hospital.first:before,
  .paginationControl .icon-hospital.previous:before,
  .paginationControl .icon-hospital.next:before,
  .paginationControl .icon-hospital.last:before {
    content: '\ea00'; }
  .fontawesome-icon.icon-building:before, .paginationControl .icon-building.first:before,
  .paginationControl .icon-building.previous:before,
  .paginationControl .icon-building.next:before,
  .paginationControl .icon-building.last:before {
    content: '\ea01'; }
  .fontawesome-icon.icon-graduation-cap:before, .paginationControl .icon-graduation-cap.first:before,
  .paginationControl .icon-graduation-cap.previous:before,
  .paginationControl .icon-graduation-cap.next:before,
  .paginationControl .icon-graduation-cap.last:before {
    content: '\ea02'; }
  .fontawesome-icon.icon-paw:before, .paginationControl .icon-paw.first:before,
  .paginationControl .icon-paw.previous:before,
  .paginationControl .icon-paw.next:before,
  .paginationControl .icon-paw.last:before {
    content: '\ea03'; }
  .fontawesome-icon.icon-spoon:before, .paginationControl .icon-spoon.first:before,
  .paginationControl .icon-spoon.previous:before,
  .paginationControl .icon-spoon.next:before,
  .paginationControl .icon-spoon.last:before {
    content: '\ea04'; }
  .fontawesome-icon.icon-cube:before, .paginationControl .icon-cube.first:before,
  .paginationControl .icon-cube.previous:before,
  .paginationControl .icon-cube.next:before,
  .paginationControl .icon-cube.last:before {
    content: '\ea05'; }
  .fontawesome-icon.icon-cubes:before, .paginationControl .icon-cubes.first:before,
  .paginationControl .icon-cubes.previous:before,
  .paginationControl .icon-cubes.next:before,
  .paginationControl .icon-cubes.last:before {
    content: '\ea06'; }
  .fontawesome-icon.icon-recycle:before, .paginationControl .icon-recycle.first:before,
  .paginationControl .icon-recycle.previous:before,
  .paginationControl .icon-recycle.next:before,
  .paginationControl .icon-recycle.last:before {
    content: '\ea07'; }
  .fontawesome-icon.icon-at:before, .paginationControl .icon-at.first:before,
  .paginationControl .icon-at.previous:before,
  .paginationControl .icon-at.next:before,
  .paginationControl .icon-at.last:before {
    content: '\ea08'; }
  .fontawesome-icon.icon-eyedropper:before, .paginationControl .icon-eyedropper.first:before,
  .paginationControl .icon-eyedropper.previous:before,
  .paginationControl .icon-eyedropper.next:before,
  .paginationControl .icon-eyedropper.last:before {
    content: '\ea09'; }
  .fontawesome-icon.icon-brush:before, .paginationControl .icon-brush.first:before,
  .paginationControl .icon-brush.previous:before,
  .paginationControl .icon-brush.next:before,
  .paginationControl .icon-brush.last:before {
    content: '\ea0a'; }
  .fontawesome-icon.icon-birthday:before, .paginationControl .icon-birthday.first:before,
  .paginationControl .icon-birthday.previous:before,
  .paginationControl .icon-birthday.next:before,
  .paginationControl .icon-birthday.last:before {
    content: '\ea0b'; }
  .fontawesome-icon.icon-diamond:before, .paginationControl .icon-diamond.first:before,
  .paginationControl .icon-diamond.previous:before,
  .paginationControl .icon-diamond.next:before,
  .paginationControl .icon-diamond.last:before {
    content: '\ea0c'; }
  .fontawesome-icon.icon-street-view:before, .paginationControl .icon-street-view.first:before,
  .paginationControl .icon-street-view.previous:before,
  .paginationControl .icon-street-view.next:before,
  .paginationControl .icon-street-view.last:before {
    content: '\ea0d'; }
  .fontawesome-icon.icon-cc-discover:before, .paginationControl .icon-cc-discover.first:before,
  .paginationControl .icon-cc-discover.previous:before,
  .paginationControl .icon-cc-discover.next:before,
  .paginationControl .icon-cc-discover.last:before {
    content: '\ea0e'; }
  .fontawesome-icon.icon-cc-amex:before, .paginationControl .icon-cc-amex.first:before,
  .paginationControl .icon-cc-amex.previous:before,
  .paginationControl .icon-cc-amex.next:before,
  .paginationControl .icon-cc-amex.last:before {
    content: '\ea0f'; }
  .fontawesome-icon.icon-cc-paypal:before, .paginationControl .icon-cc-paypal.first:before,
  .paginationControl .icon-cc-paypal.previous:before,
  .paginationControl .icon-cc-paypal.next:before,
  .paginationControl .icon-cc-paypal.last:before {
    content: '\ea10'; }
  .fontawesome-icon.icon-cc-stripe:before, .paginationControl .icon-cc-stripe.first:before,
  .paginationControl .icon-cc-stripe.previous:before,
  .paginationControl .icon-cc-stripe.next:before,
  .paginationControl .icon-cc-stripe.last:before {
    content: '\ea11'; }
  .fontawesome-icon.icon-adn:before, .paginationControl .icon-adn.first:before,
  .paginationControl .icon-adn.previous:before,
  .paginationControl .icon-adn.next:before,
  .paginationControl .icon-adn.last:before {
    content: '\ea12'; }
  .fontawesome-icon.icon-android:before, .paginationControl .icon-android.first:before,
  .paginationControl .icon-android.previous:before,
  .paginationControl .icon-android.next:before,
  .paginationControl .icon-android.last:before {
    content: '\ea13'; }
  .fontawesome-icon.icon-digg:before, .paginationControl .icon-digg.first:before,
  .paginationControl .icon-digg.previous:before,
  .paginationControl .icon-digg.next:before,
  .paginationControl .icon-digg.last:before {
    content: '\ea14'; }
  .fontawesome-icon.icon-dribbble:before, .paginationControl .icon-dribbble.first:before,
  .paginationControl .icon-dribbble.previous:before,
  .paginationControl .icon-dribbble.next:before,
  .paginationControl .icon-dribbble.last:before {
    content: '\ea15'; }
  .fontawesome-icon.icon-dropbox:before, .paginationControl .icon-dropbox.first:before,
  .paginationControl .icon-dropbox.previous:before,
  .paginationControl .icon-dropbox.next:before,
  .paginationControl .icon-dropbox.last:before {
    content: '\ea16'; }
  .fontawesome-icon.icon-drupal:before, .paginationControl .icon-drupal.first:before,
  .paginationControl .icon-drupal.previous:before,
  .paginationControl .icon-drupal.next:before,
  .paginationControl .icon-drupal.last:before {
    content: '\ea17'; }
  .fontawesome-icon.icon-facebook:before, .paginationControl .icon-facebook.first:before,
  .paginationControl .icon-facebook.previous:before,
  .paginationControl .icon-facebook.next:before,
  .paginationControl .icon-facebook.last:before {
    content: '\ea18'; }
  .fontawesome-icon.icon-facebook-squared:before, .paginationControl .icon-facebook-squared.first:before,
  .paginationControl .icon-facebook-squared.previous:before,
  .paginationControl .icon-facebook-squared.next:before,
  .paginationControl .icon-facebook-squared.last:before {
    content: '\ea19'; }
  .fontawesome-icon.icon-hacker-news:before, .paginationControl .icon-hacker-news.first:before,
  .paginationControl .icon-hacker-news.previous:before,
  .paginationControl .icon-hacker-news.next:before,
  .paginationControl .icon-hacker-news.last:before {
    content: '\ea1a'; }
  .fontawesome-icon.icon-html5:before, .paginationControl .icon-html5.first:before,
  .paginationControl .icon-html5.previous:before,
  .paginationControl .icon-html5.next:before,
  .paginationControl .icon-html5.last:before {
    content: '\ea1b'; }
  .fontawesome-icon.icon-instagram:before, .paginationControl .icon-instagram.first:before,
  .paginationControl .icon-instagram.previous:before,
  .paginationControl .icon-instagram.next:before,
  .paginationControl .icon-instagram.last:before {
    content: '\ea1c'; }
  .fontawesome-icon.icon-ioxhost:before, .paginationControl .icon-ioxhost.first:before,
  .paginationControl .icon-ioxhost.previous:before,
  .paginationControl .icon-ioxhost.next:before,
  .paginationControl .icon-ioxhost.last:before {
    content: '\ea1d'; }
  .fontawesome-icon.icon-joomla:before, .paginationControl .icon-joomla.first:before,
  .paginationControl .icon-joomla.previous:before,
  .paginationControl .icon-joomla.next:before,
  .paginationControl .icon-joomla.last:before {
    content: '\ea1e'; }
  .fontawesome-icon.icon-jsfiddle:before, .paginationControl .icon-jsfiddle.first:before,
  .paginationControl .icon-jsfiddle.previous:before,
  .paginationControl .icon-jsfiddle.next:before,
  .paginationControl .icon-jsfiddle.last:before {
    content: '\ea1f'; }
  .fontawesome-icon.icon-pinterest-circled:before, .paginationControl .icon-pinterest-circled.first:before,
  .paginationControl .icon-pinterest-circled.previous:before,
  .paginationControl .icon-pinterest-circled.next:before,
  .paginationControl .icon-pinterest-circled.last:before {
    content: '\ea20'; }
  .fontawesome-icon.icon-pinterest-squared:before, .paginationControl .icon-pinterest-squared.first:before,
  .paginationControl .icon-pinterest-squared.previous:before,
  .paginationControl .icon-pinterest-squared.next:before,
  .paginationControl .icon-pinterest-squared.last:before {
    content: '\ea21'; }
  .fontawesome-icon.icon-qq:before, .paginationControl .icon-qq.first:before,
  .paginationControl .icon-qq.previous:before,
  .paginationControl .icon-qq.next:before,
  .paginationControl .icon-qq.last:before {
    content: '\ea22'; }
  .fontawesome-icon.icon-reddit:before, .paginationControl .icon-reddit.first:before,
  .paginationControl .icon-reddit.previous:before,
  .paginationControl .icon-reddit.next:before,
  .paginationControl .icon-reddit.last:before {
    content: '\ea23'; }
  .fontawesome-icon.icon-reddit-squared:before, .paginationControl .icon-reddit-squared.first:before,
  .paginationControl .icon-reddit-squared.previous:before,
  .paginationControl .icon-reddit-squared.next:before,
  .paginationControl .icon-reddit-squared.last:before {
    content: '\ea24'; }
  .fontawesome-icon.icon-renren:before, .paginationControl .icon-renren.first:before,
  .paginationControl .icon-renren.previous:before,
  .paginationControl .icon-renren.next:before,
  .paginationControl .icon-renren.last:before {
    content: '\ea25'; }
  .fontawesome-icon.icon-stumbleupon-circled:before, .paginationControl .icon-stumbleupon-circled.first:before,
  .paginationControl .icon-stumbleupon-circled.previous:before,
  .paginationControl .icon-stumbleupon-circled.next:before,
  .paginationControl .icon-stumbleupon-circled.last:before {
    content: '\ea26'; }
  .fontawesome-icon.icon-tencent-weibo:before, .paginationControl .icon-tencent-weibo.first:before,
  .paginationControl .icon-tencent-weibo.previous:before,
  .paginationControl .icon-tencent-weibo.next:before,
  .paginationControl .icon-tencent-weibo.last:before {
    content: '\ea27'; }
  .fontawesome-icon.icon-trello:before, .paginationControl .icon-trello.first:before,
  .paginationControl .icon-trello.previous:before,
  .paginationControl .icon-trello.next:before,
  .paginationControl .icon-trello.last:before {
    content: '\ea28'; }
  .fontawesome-icon.icon-tumblr:before, .paginationControl .icon-tumblr.first:before,
  .paginationControl .icon-tumblr.previous:before,
  .paginationControl .icon-tumblr.next:before,
  .paginationControl .icon-tumblr.last:before {
    content: '\ea29'; }
  .fontawesome-icon.icon-tumblr-squared:before, .paginationControl .icon-tumblr-squared.first:before,
  .paginationControl .icon-tumblr-squared.previous:before,
  .paginationControl .icon-tumblr-squared.next:before,
  .paginationControl .icon-tumblr-squared.last:before {
    content: '\ea2a'; }
  .fontawesome-icon.icon-twitch:before, .paginationControl .icon-twitch.first:before,
  .paginationControl .icon-twitch.previous:before,
  .paginationControl .icon-twitch.next:before,
  .paginationControl .icon-twitch.last:before {
    content: '\ea2b'; }
  .fontawesome-icon.icon-yahoo:before, .paginationControl .icon-yahoo.first:before,
  .paginationControl .icon-yahoo.previous:before,
  .paginationControl .icon-yahoo.next:before,
  .paginationControl .icon-yahoo.last:before {
    content: '\ea2c'; }
  .fontawesome-icon.icon-youtube-squared:before, .paginationControl .icon-youtube-squared.first:before,
  .paginationControl .icon-youtube-squared.previous:before,
  .paginationControl .icon-youtube-squared.next:before,
  .paginationControl .icon-youtube-squared.last:before {
    content: '\ea2d'; }
  .fontawesome-icon.icon-youtube-play:before, .paginationControl .icon-youtube-play.first:before,
  .paginationControl .icon-youtube-play.previous:before,
  .paginationControl .icon-youtube-play.next:before,
  .paginationControl .icon-youtube-play.last:before {
    content: '\ea2e'; }
  .fontawesome-icon.icon-blank:before, .paginationControl .icon-blank.first:before,
  .paginationControl .icon-blank.previous:before,
  .paginationControl .icon-blank.next:before,
  .paginationControl .icon-blank.last:before {
    content: '\ea2f'; }
  .fontawesome-icon.icon-lemon:before, .paginationControl .icon-lemon.first:before,
  .paginationControl .icon-lemon.previous:before,
  .paginationControl .icon-lemon.next:before,
  .paginationControl .icon-lemon.last:before {
    content: '\ea30'; }

@font-face {
  font-family: 'iconesmembogo';
  src: url(../fonts/fontello/iconesmembogo.eot?31226467);
  src: url(../fonts/fontello/iconesmembogo.eot?31226467#iefix) format("embedded-opentype"), url(../fonts/fontello/iconesmembogo.woff?31226467) format("woff"), url(../fonts/fontello/iconesmembogo.ttf?31226467) format("truetype"), url(../fonts/fontello/iconesmembogo.svg?31226467#iconesmembogo) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'iconesmembogo';
    src: url('../font/iconesmembogo.svg?31226467#iconesmembogo') format('svg');
  }
}
*/
.membogo-icon:before {
  font-family: "iconesmembogo";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.membogo-icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .membogo-icon.icon-phone:before {
    content: '\e800'; }
  .membogo-icon.icon-phone:before {
    content: '\e800'; }
  .membogo-icon.icon-clock:before {
    content: '\e801'; }
  .membogo-icon.icon-clock-1:before {
    content: '\e802'; }
  .membogo-icon.icon-mail:before {
    content: '\e803'; }
  .membogo-icon.icon-user:before {
    content: '\e804'; }
  .membogo-icon.icon-down-dir:before {
    content: '\e805'; }
  .membogo-icon.icon-up-dir:before {
    content: '\e806'; }
  .membogo-icon.icon-left-dir:before, .paginationControl .membogo-icon.previous:before {
    content: '\e807'; }
  .membogo-icon.icon-right-dir:before, .paginationControl .membogo-icon.next:before {
    content: '\e808'; }
  .membogo-icon.icon-down-open:before {
    content: '\e809'; }
  .membogo-icon.icon-left-open:before {
    content: '\e80a'; }
  .membogo-icon.icon-right-open:before {
    content: '\e80b'; }
  .membogo-icon.icon-up-open:before {
    content: '\e80c'; }
  .membogo-icon.icon-down:before {
    content: '\e80d'; }
  .membogo-icon.icon-up:before {
    content: '\e80e'; }
  .membogo-icon.icon-right-big:before {
    content: '\e80f'; }
  .membogo-icon.icon-left-big:before {
    content: '\e810'; }
  .membogo-icon.icon-down-big:before {
    content: '\e811'; }
  .membogo-icon.icon-left:before {
    content: '\e812'; }
  .membogo-icon.icon-right:before {
    content: '\e813'; }
  .membogo-icon.icon-right-circled2:before {
    content: '\e814'; }
  .membogo-icon.icon-left-circled2:before {
    content: '\e815'; }
  .membogo-icon.icon-up-circled2:before {
    content: '\e816'; }
  .membogo-icon.icon-down-circled2:before {
    content: '\e817'; }
  .membogo-icon.icon-up-big:before {
    content: '\e818'; }
  .membogo-icon.icon-right-1:before {
    content: '\e819'; }
  .membogo-icon.icon-left-1:before {
    content: '\e81a'; }
  .membogo-icon.icon-down-1:before {
    content: '\e81b'; }
  .membogo-icon.icon-up-open-big:before {
    content: '\e81c'; }
  .membogo-icon.icon-right-open-big:before {
    content: '\e81d'; }
  .membogo-icon.icon-down-open-big:before {
    content: '\e81e'; }
  .membogo-icon.icon-left-open-big:before {
    content: '\e81f'; }
  .membogo-icon.icon-right-open-mini:before {
    content: '\e820'; }
  .membogo-icon.icon-up-open-mini:before {
    content: '\e821'; }
  .membogo-icon.icon-left-open-mini:before {
    content: '\e822'; }
  .membogo-icon.icon-up-open-1:before {
    content: '\e823'; }
  .membogo-icon.icon-down-open-mini:before {
    content: '\e824'; }
  .membogo-icon.icon-right-open-1:before {
    content: '\e825'; }
  .membogo-icon.icon-down-open-1:before {
    content: '\e826'; }
  .membogo-icon.icon-left-open-1:before {
    content: '\e827'; }
  .membogo-icon.icon-up-circled:before {
    content: '\e828'; }
  .membogo-icon.icon-right-circled:before {
    content: '\e829'; }
  .membogo-icon.icon-left-circled:before {
    content: '\e82a'; }
  .membogo-icon.icon-arrow-combo:before {
    content: '\e82b'; }
  .membogo-icon.icon-up-1:before {
    content: '\e82c'; }
  .membogo-icon.icon-down-dir-1:before {
    content: '\e82d'; }
  .membogo-icon.icon-right-dir-1:before {
    content: '\e82e'; }
  .membogo-icon.icon-left-dir-1:before {
    content: '\e82f'; }
  .membogo-icon.icon-down-circled:before {
    content: '\e830'; }
  .membogo-icon.icon-up-dir-1:before {
    content: '\e831'; }
  .membogo-icon.icon-left-bold:before {
    content: '\e832'; }
  .membogo-icon.icon-right-bold:before {
    content: '\e833'; }
  .membogo-icon.icon-down-bold:before {
    content: '\e834'; }
  .membogo-icon.icon-up-bold:before {
    content: '\e835'; }
  .membogo-icon.icon-left-thin:before {
    content: '\e836'; }
  .membogo-icon.icon-right-thin:before {
    content: '\e837'; }
  .membogo-icon.icon-ccw:before {
    content: '\e838'; }
  .membogo-icon.icon-up-thin:before {
    content: '\e839'; }
  .membogo-icon.icon-down-thin:before {
    content: '\e83a'; }
  .membogo-icon.icon-left-open-outline:before {
    content: '\e83b'; }
  .membogo-icon.icon-left-open-2:before {
    content: '\e83c'; }
  .membogo-icon.icon-right-open-2:before {
    content: '\e83d'; }
  .membogo-icon.icon-right-open-outline:before {
    content: '\e83e'; }
  .membogo-icon.icon-left-2:before {
    content: '\e83f'; }
  .membogo-icon.icon-down-2:before {
    content: '\e840'; }
  .membogo-icon.icon-up-2:before {
    content: '\e841'; }
  .membogo-icon.icon-down-outline:before {
    content: '\e842'; }
  .membogo-icon.icon-right-2:before {
    content: '\e843'; }
  .membogo-icon.icon-left-outline:before {
    content: '\e844'; }
  .membogo-icon.icon-down-small:before {
    content: '\e845'; }
  .membogo-icon.icon-right-outline:before {
    content: '\e846'; }
  .membogo-icon.icon-up-outline:before {
    content: '\e847'; }
  .membogo-icon.icon-left-small:before {
    content: '\e848'; }
  .membogo-icon.icon-right-small:before {
    content: '\e849'; }
  .membogo-icon.icon-up-small:before {
    content: '\e84a'; }
  .membogo-icon.icon-up-3:before {
    content: '\e84b'; }
  .membogo-icon.icon-up-dir-2:before {
    content: '\e84c'; }
  .membogo-icon.icon-left-dir-2:before {
    content: '\e84d'; }
  .membogo-icon.icon-down-dir-2:before {
    content: '\e84e'; }
  .membogo-icon.icon-down-3:before {
    content: '\e84f'; }
  .membogo-icon.icon-right-dir-2:before {
    content: '\e850'; }
  .membogo-icon.icon-down-bold-1:before {
    content: '\e851'; }
  .membogo-icon.icon-left-bold-1:before {
    content: '\e852'; }
  .membogo-icon.icon-down-fat:before {
    content: '\e853'; }
  .membogo-icon.icon-up-fat:before {
    content: '\e854'; }
  .membogo-icon.icon-left-fat:before {
    content: '\e855'; }
  .membogo-icon.icon-right-fat:before {
    content: '\e856'; }
  .membogo-icon.icon-up-bold-1:before {
    content: '\e857'; }
  .membogo-icon.icon-right-bold-1:before {
    content: '\e858'; }
  .membogo-icon.icon-up-4:before {
    content: '\e859'; }
  .membogo-icon.icon-down-4:before {
    content: '\e85a'; }
  .membogo-icon.icon-left-3:before {
    content: '\e85b'; }
  .membogo-icon.icon-right-3:before {
    content: '\e85c'; }
  .membogo-icon.icon-down-circled-1:before {
    content: '\e85d'; }
  .membogo-icon.icon-left-circled-1:before {
    content: '\e85e'; }
  .membogo-icon.icon-right-circled-1:before {
    content: '\e85f'; }
  .membogo-icon.icon-left-circle:before {
    content: '\e860'; }
  .membogo-icon.icon-down-circle:before {
    content: '\e861'; }
  .membogo-icon.icon-right-circle:before {
    content: '\e862'; }
  .membogo-icon.icon-up-circle:before {
    content: '\e863'; }
  .membogo-icon.icon-left-open-3:before {
    content: '\e864'; }
  .membogo-icon.icon-right-open-3:before {
    content: '\e865'; }
  .membogo-icon.icon-down-open-2:before {
    content: '\e866'; }
  .membogo-icon.icon-left-open-4:before {
    content: '\e867'; }
  .membogo-icon.icon-up-open-2:before {
    content: '\e868'; }
  .membogo-icon.icon-right-open-4:before {
    content: '\e869'; }
  .membogo-icon.icon-lock:before {
    content: '\e86a'; }
  .membogo-icon.icon-lock-open-alt:before {
    content: '\e86b'; }
  .membogo-icon.icon-lock-open:before {
    content: '\e86c'; }
  .membogo-icon.icon-lock-1:before {
    content: '\e86d'; }
  .membogo-icon.icon-lock-filled:before {
    content: '\e86e'; }
  .membogo-icon.icon-logout:before {
    content: '\e86f'; }
  .membogo-icon.icon-logout-1:before {
    content: '\e870'; }
  .membogo-icon.icon-logout-2:before {
    content: '\e871'; }
  .membogo-icon.icon-logout-3:before {
    content: '\e872'; }
  .membogo-icon.icon-login:before {
    content: '\e873'; }
  .membogo-icon.icon-login-1:before {
    content: '\e874'; }
  .membogo-icon.icon-login-2:before {
    content: '\e875'; }
  .membogo-icon.icon-help-circled:before {
    content: '\e876'; }
  .membogo-icon.icon-lifebuoy:before {
    content: '\e877'; }
  .membogo-icon.icon-lifebuoy-1:before {
    content: '\e878'; }
  .membogo-icon.icon-language:before {
    content: '\e879'; }
  .membogo-icon.icon-globe:before {
    content: '\e87a'; }
  .membogo-icon.icon-globe-1:before {
    content: '\e87b'; }
  .membogo-icon.icon-calendar:before {
    content: '\e87c'; }
  .membogo-icon.icon-calendar-1:before {
    content: '\e87d'; }
  .membogo-icon.icon-calendar-inv:before {
    content: '\e87e'; }
  .membogo-icon.icon-calendar-2:before {
    content: '\e87f'; }
  .membogo-icon.icon-calendar-outlilne:before {
    content: '\e880'; }
  .membogo-icon.icon-calendar-3:before {
    content: '\e881'; }
  .membogo-icon.icon-calendar-empty:before {
    content: '\e882'; }
  .membogo-icon.icon-calendar-4:before {
    content: '\e883'; }
  .membogo-icon.icon-calendar-5:before {
    content: '\e884'; }
  .membogo-icon.icon-calendar-alt:before {
    content: '\e885'; }
  .membogo-icon.icon-calendar-6:before {
    content: '\e886'; }
  .membogo-icon.icon-calendar-7:before {
    content: '\e887'; }
  .membogo-icon.icon-calendar-circled:before {
    content: '\e888'; }
  .membogo-icon.icon-chart-pie:before {
    content: '\e889'; }
  .membogo-icon.icon-chart-bar:before {
    content: '\e88a'; }
  .membogo-icon.icon-chart-pie-1:before {
    content: '\e88b'; }
  .membogo-icon.icon-chart-bar-1:before {
    content: '\e88c'; }
  .membogo-icon.icon-chart-pie-alt:before {
    content: '\e88d'; }
  .membogo-icon.icon-chart-pie-2:before {
    content: '\e88e'; }
  .membogo-icon.icon-star:before {
    content: '\e88f'; }
  .membogo-icon.icon-th-list:before {
    content: '\e890'; }
  .membogo-icon.icon-th-thumb:before {
    content: '\e891'; }
  .membogo-icon.icon-th-large:before {
    content: '\e892'; }
  .membogo-icon.icon-th:before {
    content: '\e893'; }
  .membogo-icon.icon-th-list-1:before {
    content: '\e894'; }
  .membogo-icon.icon-list:before {
    content: '\e895'; }
  .membogo-icon.icon-clipboard:before {
    content: '\e896'; }
  .membogo-icon.icon-th-list-2:before {
    content: '\e897'; }
  .membogo-icon.icon-user-add:before {
    content: '\e898'; }
  .membogo-icon.icon-chart:before {
    content: '\e899'; }
  .membogo-icon.icon-chart-alt:before {
    content: '\e89a'; }
  .membogo-icon.icon-chart-bar-outline:before {
    content: '\e89b'; }
  .membogo-icon.icon-chart-bar-2:before {
    content: '\e89c'; }
  .membogo-icon.icon-dollar:before {
    content: '\e89d'; }
  .membogo-icon.icon-upload-cloud:before {
    content: '\e89e'; }
  .membogo-icon.icon-help-circled-1:before {
    content: '\e89f'; }
  .membogo-icon.icon-help-circled-2:before {
    content: '\e8a0'; }
  .membogo-icon.icon-help-circled-alt:before {
    content: '\e8a1'; }
  .membogo-icon.icon-question:before {
    content: '\e8a2'; }
  .membogo-icon.icon-help-circled-3:before {
    content: '\e8a3'; }
  .membogo-icon.icon-search:before {
    content: '\e8a4'; }
  .membogo-icon.icon-search-1:before {
    content: '\e8a5'; }
  .membogo-icon.icon-search-2:before {
    content: '\e8a6'; }
  .membogo-icon.icon-search-outline:before {
    content: '\e8a7'; }
  .membogo-icon.icon-flashlight:before {
    content: '\e8a8'; }
  .membogo-icon.icon-search-3:before {
    content: '\e8a9'; }
  .membogo-icon.icon-search-4:before {
    content: '\e8aa'; }
  .membogo-icon.icon-search-5:before {
    content: '\e8ab'; }
  .membogo-icon.icon-search-6:before {
    content: '\e8ac'; }
  .membogo-icon.icon-search-circled:before {
    content: '\e8ad'; }
  .membogo-icon.icon-search-7:before {
    content: '\e8ae'; }
  .membogo-icon.icon-search-8:before {
    content: '\e8af'; }
  .membogo-icon.icon-docs-landscape:before {
    content: '\e8b0'; }
  .membogo-icon.icon-doc:before {
    content: '\e8b1'; }
  .membogo-icon.icon-note:before {
    content: '\e8b2'; }
  .membogo-icon.icon-doc-new:before {
    content: '\e8b3'; }
  .membogo-icon.icon-doc-circled:before {
    content: '\e8b4'; }
  .membogo-icon.icon-doc-1:before {
    content: '\e8b5'; }
  .membogo-icon.icon-doc-new-circled:before {
    content: '\e8b6'; }
  .membogo-icon.icon-doc-2:before {
    content: '\e8b7'; }
  .membogo-icon.icon-article-alt:before {
    content: '\e8b8'; }
  .membogo-icon.icon-article:before {
    content: '\e8b9'; }
  .membogo-icon.icon-doc-3:before {
    content: '\e8ba'; }
  .membogo-icon.icon-doc-4:before {
    content: '\e8bb'; }
  .membogo-icon.icon-doc-inv:before {
    content: '\e8bc'; }
  .membogo-icon.icon-article-1:before {
    content: '\e8bd'; }
  .membogo-icon.icon-doc-inv-alt:before {
    content: '\e8be'; }
  .membogo-icon.icon-doc-alt:before {
    content: '\e8bf'; }
  .membogo-icon.icon-article-alt-1:before {
    content: '\e8c0'; }
  .membogo-icon.icon-book-open:before {
    content: '\e8c1'; }
  .membogo-icon.icon-doc-text:before {
    content: '\e8c2'; }
  .membogo-icon.icon-doc-5:before {
    content: '\e8c3'; }
  .membogo-icon.icon-doc-remove:before {
    content: '\e8c4'; }
  .membogo-icon.icon-doc-add:before {
    content: '\e8c5'; }
  .membogo-icon.icon-doc-6:before {
    content: '\e8c6'; }
  .membogo-icon.icon-docs:before {
    content: '\e8c7'; }
  .membogo-icon.icon-doc-text-inv:before {
    content: '\e8c8'; }
  .membogo-icon.icon-book-open-1:before {
    content: '\e8c9'; }
  .membogo-icon.icon-doc-landscape:before {
    content: '\e8ca'; }
  .membogo-icon.icon-doc-text-1:before {
    content: '\e8cb'; }
  .membogo-icon.icon-doc-text-inv-1:before {
    content: '\e8cc'; }
  .membogo-icon.icon-doc-inv-1:before {
    content: '\e8cd'; }
  .membogo-icon.icon-docs-1:before {
    content: '\e8ce'; }
  .membogo-icon.icon-doc-7:before {
    content: '\e8cf'; }
  .membogo-icon.icon-doc-text-2:before {
    content: '\e8d0'; }
  .membogo-icon.icon-arrows-cw:before {
    content: '\e8d1'; }
  .membogo-icon.icon-pencil:before {
    content: '\e8d2'; }
  .membogo-icon.icon-folder-open:before {
    content: '\e8d3'; }
  .membogo-icon.icon-edit:before {
    content: '\e8d4'; }
  .membogo-icon.icon-pencil-1:before {
    content: '\e8d5'; }
  .membogo-icon.icon-graduation-cap:before {
    content: '\e8d6'; }
  .membogo-icon.icon-menu:before {
    content: '\e8d7'; }
  .membogo-icon.icon-layout:before {
    content: '\e8d8'; }
  .membogo-icon.icon-info-circled:before {
    content: '\e8d9'; }
  .membogo-icon.icon-list-1:before {
    content: '\e8da'; }
  .membogo-icon.icon-wrench:before {
    content: '\e8db'; }
  .membogo-icon.icon-mail-1:before {
    content: '\e8dc'; }
  .membogo-icon.icon-th-list-3:before {
    content: '\e8dd'; }
  .membogo-icon.icon-export:before {
    content: '\e8de'; }
  .membogo-icon.icon-college:before {
    content: '\e8df'; }
  .membogo-icon.icon-buffer:before {
    content: '\e8e0'; }
  .membogo-icon.icon-group:before {
    content: '\e8e1'; }
  .membogo-icon.icon-money:before {
    content: '\e8e2'; }
  .membogo-icon.icon-money-1:before {
    content: '\e8e3'; }

@font-face {
  font-family: 'fontello_mgf';
  src: url(../fonts/fontello/fontello_mgf.eot?24104225);
  src: url(../fonts/fontello/fontello_mgf.eot?24104225#iefix) format("embedded-opentype"), url(../fonts/fontello/fontello_mgf.woff?24104225) format("woff"), url(../fonts/fontello/fontello_mgf.ttf?24104225) format("truetype"), url(../fonts/fontello/fontello_mgf.svg?24104225#fontello_mgf) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello_mgf';
    src: url('../font/fontello_mgf.svg?24104225#fontello_mgf') format('svg');
  }
}
*/
.mgf-icon:before {
  font-family: "fontello_mgf";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.mgf-icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .mgf-icon.icon-search:before {
    content: '\e800'; }
  .mgf-icon.icon-mail:before {
    content: '\e801'; }
  .mgf-icon.icon-heart:before {
    content: '\e802'; }
  .mgf-icon.icon-heart-broken:before {
    content: '\e803'; }
  .mgf-icon.icon-star:before {
    content: '\e804'; }
  .mgf-icon.icon-star-empty:before {
    content: '\e805'; }
  .mgf-icon.icon-star-half:before {
    content: '\e806'; }
  .mgf-icon.icon-star-half_empty:before {
    content: '\e807'; }
  .mgf-icon.icon-user:before {
    content: '\e808'; }
  .mgf-icon.icon-user-male:before {
    content: '\e809'; }
  .mgf-icon.icon-user-female:before {
    content: '\e80a'; }
  .mgf-icon.icon-users:before {
    content: '\e80b'; }
  .mgf-icon.icon-movie:before {
    content: '\e80c'; }
  .mgf-icon.icon-videocam:before {
    content: '\e80d'; }
  .mgf-icon.icon-isight:before {
    content: '\e80e'; }
  .mgf-icon.icon-camera:before {
    content: '\e80f'; }
  .mgf-icon.icon-menu:before {
    content: '\e810'; }
  .mgf-icon.icon-th-thumb:before {
    content: '\e811'; }
  .mgf-icon.icon-th-thumb-empty:before {
    content: '\e812'; }
  .mgf-icon.icon-th-list:before {
    content: '\e813'; }
  .mgf-icon.icon-ok:before {
    content: '\e814'; }
  .mgf-icon.icon-ok-circled:before {
    content: '\e815'; }
  .mgf-icon.icon-cancel:before {
    content: '\e816'; }
  .mgf-icon.icon-cancel-circled:before {
    content: '\e817'; }
  .mgf-icon.icon-plus:before {
    content: '\e818'; }
  .mgf-icon.icon-help-circled:before {
    content: '\e819'; }
  .mgf-icon.icon-help-circled-alt:before {
    content: '\e81a'; }
  .mgf-icon.icon-info-circled:before {
    content: '\e81b'; }
  .mgf-icon.icon-info-circled-alt:before {
    content: '\e81c'; }
  .mgf-icon.icon-home:before {
    content: '\e81d'; }
  .mgf-icon.icon-link:before {
    content: '\e81e'; }
  .mgf-icon.icon-attach:before {
    content: '\e81f'; }
  .mgf-icon.icon-lock:before {
    content: '\e820'; }
  .mgf-icon.icon-lock-alt:before {
    content: '\e821'; }
  .mgf-icon.icon-lock-open:before {
    content: '\e822'; }
  .mgf-icon.icon-lock-open-alt:before {
    content: '\e823'; }
  .mgf-icon.icon-eye:before {
    content: '\e824'; }
  .mgf-icon.icon-download:before {
    content: '\e825'; }
  .mgf-icon.icon-upload:before {
    content: '\e826'; }
  .mgf-icon.icon-download-cloud:before {
    content: '\e827'; }
  .mgf-icon.icon-upload-cloud:before {
    content: '\e828'; }
  .mgf-icon.icon-reply:before {
    content: '\e829'; }
  .mgf-icon.icon-pencil:before {
    content: '\e82a'; }
  .mgf-icon.icon-export:before {
    content: '\e82b'; }
  .mgf-icon.icon-print:before {
    content: '\e82c'; }
  .mgf-icon.icon-retweet:before {
    content: '\e82d'; }
  .mgf-icon.icon-comment:before {
    content: '\e82e'; }
  .mgf-icon.icon-chat:before {
    content: '\e82f'; }
  .mgf-icon.icon-bell:before {
    content: '\e830'; }
  .mgf-icon.icon-attention:before {
    content: '\e831'; }
  .mgf-icon.icon-attention-alt:before {
    content: '\e832'; }
  .mgf-icon.icon-location:before {
    content: '\e833'; }
  .mgf-icon.icon-trash:before {
    content: '\e834'; }
  .mgf-icon.icon-doc:before {
    content: '\e835'; }
  .mgf-icon.icon-newspaper:before {
    content: '\e836'; }
  .mgf-icon.icon-folder:before {
    content: '\e837'; }
  .mgf-icon.icon-folder-open:before {
    content: '\e838'; }
  .mgf-icon.icon-folder-empty:before {
    content: '\e839'; }
  .mgf-icon.icon-folder-open-empty:before {
    content: '\e83a'; }
  .mgf-icon.icon-cog:before {
    content: '\e83b'; }
  .mgf-icon.icon-calendar:before {
    content: '\e83c'; }
  .mgf-icon.icon-login:before {
    content: '\e83d'; }
  .mgf-icon.icon-logout:before {
    content: '\e83e'; }
  .mgf-icon.icon-mic:before {
    content: '\e83f'; }
  .mgf-icon.icon-mic-off:before {
    content: '\e840'; }
  .mgf-icon.icon-clock:before {
    content: '\e841'; }
  .mgf-icon.icon-stopwatch:before {
    content: '\e842'; }
  .mgf-icon.icon-hourglass:before {
    content: '\e843'; }
  .mgf-icon.icon-zoom-in:before {
    content: '\e844'; }
  .mgf-icon.icon-zoom-out:before {
    content: '\e845'; }
  .mgf-icon.icon-down-open:before {
    content: '\e846'; }
  .mgf-icon.icon-left-open:before {
    content: '\e847'; }
  .mgf-icon.icon-right-open:before {
    content: '\e848'; }
  .mgf-icon.icon-up-open:before {
    content: '\e849'; }
  .mgf-icon.icon-down:before {
    content: '\e84a'; }
  .mgf-icon.icon-left:before {
    content: '\e84b'; }
  .mgf-icon.icon-right:before {
    content: '\e84c'; }
  .mgf-icon.icon-up:before {
    content: '\e84d'; }
  .mgf-icon.icon-down-bold:before {
    content: '\e84e'; }
  .mgf-icon.icon-left-bold:before {
    content: '\e84f'; }
  .mgf-icon.icon-right-bold:before {
    content: '\e850'; }
  .mgf-icon.icon-up-bold:before {
    content: '\e851'; }
  .mgf-icon.icon-down-fat:before {
    content: '\e852'; }
  .mgf-icon.icon-left-fat:before {
    content: '\e853'; }
  .mgf-icon.icon-right-fat:before {
    content: '\e854'; }
  .mgf-icon.icon-up-fat:before {
    content: '\e855'; }
  .mgf-icon.icon-ccw:before {
    content: '\e856'; }
  .mgf-icon.icon-shuffle:before {
    content: '\e857'; }
  .mgf-icon.icon-play:before {
    content: '\e858'; }
  .mgf-icon.icon-pause:before {
    content: '\e859'; }
  .mgf-icon.icon-stop:before {
    content: '\e85a'; }
  .mgf-icon.icon-to-end:before, .paginationControl .mgf-icon.last:before {
    content: '\e85b'; }
  .mgf-icon.icon-to-start:before, .paginationControl .mgf-icon.first:before {
    content: '\e85c'; }
  .mgf-icon.icon-fast-forward:before {
    content: '\e85d'; }
  .mgf-icon.icon-fast-backward:before {
    content: '\e85e'; }
  .mgf-icon.icon-trophy:before {
    content: '\e85f'; }
  .mgf-icon.icon-monitor:before {
    content: '\e860'; }
  .mgf-icon.icon-tablet:before {
    content: '\e861'; }
  .mgf-icon.icon-mobile:before {
    content: '\e862'; }
  .mgf-icon.icon-data-science:before {
    content: '\e863'; }
  .mgf-icon.icon-data-science-inv:before {
    content: '\e864'; }
  .mgf-icon.icon-inbox:before {
    content: '\e865'; }
  .mgf-icon.icon-globe:before {
    content: '\e866'; }
  .mgf-icon.icon-globe-inv:before {
    content: '\e867'; }
  .mgf-icon.icon-flash:before {
    content: '\e868'; }
  .mgf-icon.icon-cloud:before {
    content: '\e869'; }
  .mgf-icon.icon-coverflow:before {
    content: '\e86a'; }
  .mgf-icon.icon-coverflow-empty:before {
    content: '\e86b'; }
  .mgf-icon.icon-math:before {
    content: '\e86c'; }
  .mgf-icon.icon-math-circled:before {
    content: '\e86d'; }
  .mgf-icon.icon-math-circled-empty:before {
    content: '\e86e'; }
  .mgf-icon.icon-paper-plane:before {
    content: '\e86f'; }
  .mgf-icon.icon-paper-plane-alt:before {
    content: '\e870'; }
  .mgf-icon.icon-paper-plane-alt2:before {
    content: '\e871'; }
  .mgf-icon.icon-fontsize:before {
    content: '\e872'; }
  .mgf-icon.icon-color-adjust:before {
    content: '\e873'; }
  .mgf-icon.icon-fire:before {
    content: '\e874'; }
  .mgf-icon.icon-chart-bar:before {
    content: '\e875'; }
  .mgf-icon.icon-hdd:before {
    content: '\e876'; }
  .mgf-icon.icon-connected-object:before {
    content: '\e877'; }
  .mgf-icon.icon-ruler:before {
    content: '\e878'; }
  .mgf-icon.icon-vector:before {
    content: '\e879'; }
  .mgf-icon.icon-vector-pencil:before {
    content: '\e87a'; }
  .mgf-icon.icon-at:before {
    content: '\e87b'; }
  .mgf-icon.icon-hash:before {
    content: '\e87c'; }
  .mgf-icon.icon-female:before {
    content: '\e87d'; }
  .mgf-icon.icon-male:before {
    content: '\e87e'; }
  .mgf-icon.icon-spread:before {
    content: '\e87f'; }
  .mgf-icon.icon-king:before {
    content: '\e880'; }
  .mgf-icon.icon-anchor:before {
    content: '\e881'; }
  .mgf-icon.icon-joystick:before {
    content: '\e882'; }
  .mgf-icon.icon-spinner1:before {
    content: '\e883'; }
  .mgf-icon.icon-spinner2:before {
    content: '\e884'; }
  .mgf-icon.icon-github:before {
    content: '\e885'; }
  .mgf-icon.icon-github-circled:before {
    content: '\e886'; }
  .mgf-icon.icon-github-circled-alt:before {
    content: '\e887'; }
  .mgf-icon.icon-github-circled-alt2:before {
    content: '\e888'; }
  .mgf-icon.icon-twitter:before {
    content: '\e889'; }
  .mgf-icon.icon-twitter-circled:before {
    content: '\e88a'; }
  .mgf-icon.icon-facebook:before {
    content: '\e88b'; }
  .mgf-icon.icon-facebook-circled:before {
    content: '\e88c'; }
  .mgf-icon.icon-gplus:before {
    content: '\e88d'; }
  .mgf-icon.icon-gplus-circled:before {
    content: '\e88e'; }
  .mgf-icon.icon-linkedin:before {
    content: '\e88f'; }
  .mgf-icon.icon-linkedin-circled:before {
    content: '\e890'; }
  .mgf-icon.icon-dribbble:before {
    content: '\e891'; }
  .mgf-icon.icon-dribbble-circled:before {
    content: '\e892'; }
  .mgf-icon.icon-instagram:before {
    content: '\e893'; }
  .mgf-icon.icon-instagram-circled:before {
    content: '\e894'; }
  .mgf-icon.icon-soundcloud:before {
    content: '\e895'; }
  .mgf-icon.icon-soundcloud-circled:before {
    content: '\e896'; }
  .mgf-icon.icon-mfg-logo:before {
    content: '\e897'; }
  .mgf-icon.icon-mfg-logo-circled:before {
    content: '\e898'; }

@font-face {
  font-family: 'fontello_websymbols';
  src: url(../fonts/fontello/fontello_websymbols.eot?19211558);
  src: url(../fonts/fontello/fontello_websymbols.eot?19211558#iefix) format("embedded-opentype"), url(../fonts/fontello/fontello_websymbols.woff?19211558) format("woff"), url(../fonts/fontello/fontello_websymbols.ttf?19211558) format("truetype"), url(../fonts/fontello/fontello_websymbols.svg?19211558#fontello_websymbols) format("svg");
  font-weight: normal;
  font-style: normal; }

.websymbols-icon:before {
  font-family: "fontello_websymbols";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.websymbols-icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */ }
  .websymbols-icon.icon-search:before {
    content: '\e800'; }
  .websymbols-icon.icon-mail:before {
    content: '\e801'; }
  .websymbols-icon.icon-heart:before {
    content: '\e802'; }
  .websymbols-icon.icon-heart-empty:before {
    content: '\e803'; }
  .websymbols-icon.icon-star:before {
    content: '\e804'; }
  .websymbols-icon.icon-user:before {
    content: '\e805'; }
  .websymbols-icon.icon-video:before {
    content: '\e806'; }
  .websymbols-icon.icon-picture:before {
    content: '\e807'; }
  .websymbols-icon.icon-th-large:before {
    content: '\e808'; }
  .websymbols-icon.icon-th:before {
    content: '\e809'; }
  .websymbols-icon.icon-th-list:before {
    content: '\e80a'; }
  .websymbols-icon.icon-ok:before {
    content: '\e80b'; }
  .websymbols-icon.icon-ok-circle:before {
    content: '\e80c'; }
  .websymbols-icon.icon-cancel:before {
    content: '\e80d'; }
  .websymbols-icon.icon-cancel-circle:before {
    content: '\e80e'; }
  .websymbols-icon.icon-plus-circle:before {
    content: '\e80f'; }
  .websymbols-icon.icon-minus-circle:before {
    content: '\e810'; }
  .websymbols-icon.icon-link:before {
    content: '\e811'; }
  .websymbols-icon.icon-attach:before {
    content: '\e812'; }
  .websymbols-icon.icon-lock:before {
    content: '\e813'; }
  .websymbols-icon.icon-lock-open:before {
    content: '\e814'; }
  .websymbols-icon.icon-tag:before {
    content: '\e815'; }
  .websymbols-icon.icon-reply:before {
    content: '\e816'; }
  .websymbols-icon.icon-reply-all:before {
    content: '\e817'; }
  .websymbols-icon.icon-forward:before {
    content: '\e818'; }
  .websymbols-icon.icon-code:before {
    content: '\e819'; }
  .websymbols-icon.icon-retweet:before {
    content: '\e81a'; }
  .websymbols-icon.icon-comment:before {
    content: '\e81b'; }
  .websymbols-icon.icon-comment-alt:before {
    content: '\e81c'; }
  .websymbols-icon.icon-chat:before {
    content: '\e81d'; }
  .websymbols-icon.icon-attention:before {
    content: '\e81e'; }
  .websymbols-icon.icon-location:before {
    content: '\e81f'; }
  .websymbols-icon.icon-doc:before {
    content: '\e820'; }
  .websymbols-icon.icon-docs-landscape:before {
    content: '\e821'; }
  .websymbols-icon.icon-folder:before {
    content: '\e822'; }
  .websymbols-icon.icon-archive:before {
    content: '\e823'; }
  .websymbols-icon.icon-rss:before {
    content: '\e824'; }
  .websymbols-icon.icon-rss-alt:before {
    content: '\e825'; }
  .websymbols-icon.icon-cog:before {
    content: '\e826'; }
  .websymbols-icon.icon-logout:before {
    content: '\e827'; }
  .websymbols-icon.icon-clock:before {
    content: '\e828'; }
  .websymbols-icon.icon-block:before {
    content: '\e829'; }
  .websymbols-icon.icon-resize-full:before {
    content: '\e82a'; }
  .websymbols-icon.icon-resize-full-circle:before {
    content: '\e82b'; }
  .websymbols-icon.icon-popup:before {
    content: '\e82c'; }
  .websymbols-icon.icon-left-open:before {
    content: '\e82d'; }
  .websymbols-icon.icon-right-open:before {
    content: '\e82e'; }
  .websymbols-icon.icon-down-circle:before {
    content: '\e82f'; }
  .websymbols-icon.icon-left-circle:before {
    content: '\e830'; }
  .websymbols-icon.icon-right-circle:before {
    content: '\e831'; }
  .websymbols-icon.icon-up-circle:before {
    content: '\e832'; }
  .websymbols-icon.icon-down-dir:before {
    content: '\e833'; }
  .websymbols-icon.icon-right-dir:before, .paginationControl .websymbols-icon.next:before {
    content: '\e834'; }
  .websymbols-icon.icon-down-micro:before {
    content: '\e835'; }
  .websymbols-icon.icon-up-micro:before {
    content: '\e836'; }
  .websymbols-icon.icon-cw-circle:before {
    content: '\e837'; }
  .websymbols-icon.icon-arrows-cw:before {
    content: '\e838'; }
  .websymbols-icon.icon-updown-circle:before {
    content: '\e839'; }
  .websymbols-icon.icon-target:before {
    content: '\e83a'; }
  .websymbols-icon.icon-signal:before {
    content: '\e83b'; }
  .websymbols-icon.icon-progress-0:before {
    content: '\e83c'; }
  .websymbols-icon.icon-progress-1:before {
    content: '\e83d'; }
  .websymbols-icon.icon-progress-2:before {
    content: '\e83e'; }
  .websymbols-icon.icon-progress-3:before {
    content: '\e83f'; }
  .websymbols-icon.icon-progress-4:before {
    content: '\e840'; }
  .websymbols-icon.icon-progress-5:before {
    content: '\e841'; }
  .websymbols-icon.icon-progress-6:before {
    content: '\e842'; }
  .websymbols-icon.icon-progress-7:before {
    content: '\e843'; }
  .websymbols-icon.icon-font:before {
    content: '\e844'; }
  .websymbols-icon.icon-list:before {
    content: '\e845'; }
  .websymbols-icon.icon-list-numbered:before {
    content: '\e846'; }
  .websymbols-icon.icon-indent-left:before {
    content: '\e847'; }
  .websymbols-icon.icon-indent-right:before {
    content: '\e848'; }
  .websymbols-icon.icon-cloud:before {
    content: '\e849'; }
  .websymbols-icon.icon-terminal:before {
    content: '\e84a'; }
  .websymbols-icon.icon-facebook-rect:before {
    content: '\e84b'; }
  .websymbols-icon.icon-twitter-bird:before {
    content: '\e84c'; }
  .websymbols-icon.icon-vimeo-rect:before {
    content: '\e84d'; }
  .websymbols-icon.icon-tumblr-rect:before {
    content: '\e84e'; }
  .websymbols-icon.icon-googleplus-rect:before {
    content: '\e84f'; }
  .websymbols-icon.icon-linkedin-rect:before {
    content: '\e850'; }
  .websymbols-icon.icon-skype:before {
    content: '\e851'; }
  .websymbols-icon.icon-vkontakte-rect:before {
    content: '\e852'; }
  .websymbols-icon.icon-youtube:before {
    content: '\e853'; }
  .websymbols-icon.icon-odnoklassniki-rect:before {
    content: '\e854'; }

.icn-svg::before, .module-icon.config::before, .module-icon.home::before {
  content: '';
  display: inline-block;
  background: no-repeat center center;
  width: 13px;
  height: 13px;
  vertical-align: middle; }

.icn-tel-info::before {
  background-image: url(../images/icn-tel-info.svg); }

.icn-rocket::before {
  background-image: url(../images/icn-rocket.svg);
  -ms-transform: rotate(9deg);
  transform: rotate(9deg) translate3d(0, 0, 0); }

.icn-cog-double::before, .module-icon.config::before {
  background-image: url(../images/icon-cog-double.svg);
  width: 25px;
  height: 19px;
  /*width: 18.24px;
  height: 13.22px;
  background-size: cover;*/ }

.icn-books::before {
  background-image: url(../images/icn-books.svg);
  width: 60px;
  height: 57px; }

.icn-home::before, .module-icon.home::before {
  background-image: url(../images/icon-home.svg);
  width: 60px;
  height: 48px; }

.icn-google::before {
  background-color: #ffffff;
  background-image: url(../images/icn-google-logo.svg);
  width: 22px;
  height: 22px;
  background-size: 18px 18px;
  vertical-align: text-bottom; }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.mgo-loader {
  display: inline-block;
  animation: fa-spin 1s infinite steps(10);
  color: #04b9ee;
  font-size: 26px;
  line-height: 1; }
  .mgo-loader:before {
    margin: 0; }
  .mgo-loader.mgo-loader-small {
    font-size: 12px; }
  .mgo-loader.mgo-loader-large {
    font-size: 39px; }

/* @deprecated UX 4 */
/*
.tabs-container {
  border: 1px solid #cccdcd;

  &.tabs-header {
    border-top-width: 0;
    border-right: 0;
    border-left: 0;
    @at-root {
      .main-page > & {
        margin-top: (-$page-content-padding-vertical);
        margin-bottom: $page-content-padding-vertical;
      }
    }
    > .tab-content {
      padding: ($tabs-content-padding-vertical - 2px) $page-content-padding-horizontal $tabs-content-padding-vertical;
    }
  }

  > .tab-content {
    padding: $tabs-content-padding-vertical $page-content-padding-horizontal;
    background-color: $white;
    @include fit-formcontrol-to-col();
  }
}
*/
/* @deprecated UX 4 */
/*.
nav {
  &.nav-tabs {
    $nav-tabs__border-color: #d1d3d4;
    display: inline-block;
    width: 100%;
    border-color: $nav-tabs__border-color;
    margin-bottom: -7px; // @TODO Keep and eye out for this if layout goes off
    > li {
      > a {
        border-radius: 0;
        border: none;
        border-right: 1px solid $nav-tabs__border-color;
        border-bottom: 1px solid transparent;
        color: #757679;
        margin: 0;
        padding: $tabs-content-padding-vertical $page-content-padding-horizontal;
        line-height: 1;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $black;
          border-color: $nav-tabs__border-color;
        }

        &.has-icon {
          position: relative;
          padding-left: 48px;

          > [class*='icon-'] {
            color: $yellow-sea;
            font-size: 20px;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        @at-root {
          .tabs-container__page-edit & {
            border-right: 0;
          }
        }
      }

      &.active {
        > a {
          color: $black;
          border-right: 1px solid $nav-tabs__border-color !important;

          &:hover,
          &:focus {
            color: $black;
            border: none;
            border-right: 1px solid $nav-tabs__border-color;
            border-bottom: 1px solid transparent;
            background-color: $white;
          }

          @at-root {
            .tabs-container__page-edit & {
              border-top: 1px solid $nav-tabs__border-color;
              margin-top: -1px;
              border-left: 1px solid $nav-tabs__border-color;
            }
          }
        }

        @at-root {
          .tabs-container__page-edit & {
            &:first-child {
              > a {
                border-left: 1px solid $nav-tabs__border-color;
                margin-left: -1px;
              }
            }
          }
        }
      }

      &:last-child {
        > a {
          border-right: none;
        }
      }
    }

    @at-root {
      .tabs-container__page-edit & { !* MGO-6228 - Onglets pour une page web (Contenu/Config) *!
        padding-left: $page-content-padding-horizontal;
        padding-right: $page-content-padding-horizontal;
      }
    }
  }
}
*/
#members > .tabs-container.tabs-header {
  border-top-width: 1px;
  margin-top: 40px; }

/**
 *  - NOT retina ready.
 *
 *  @TODO#ddesousa
 *   - Keep sprite coordinates in one map and move axis fixes to another map.
 */
/**
   * v1.2
   *
   * $x, $y = number of sprite spaces
   * $xf, $yf = axis pixel fix
   * A sprite that is second from left and third from top: @include module-bgp(2, 3);
   * It needs to be pulled 1 pixel to the left and pushed 2 pixels to the bottom: @include module-bgp(2, 3, -1, 2);
   * $m = multiplier
   * For a sprite that is half the size: @include module-bgp(2, 3, -1, 2, (1/2));
   * ... a third the size: @include module-bgp(2, 3, -1, 2, (1/3));
   */
.module-icon {
  display: block;
  width: 90px;
  height: 70px;
  background: url(../images/iconset-3.0.12.png) no-repeat -100% -100%;
  background-size: 270px 490px;
  /* Default */ }
  .module-icon.configuration {
    background-position: -90px -210px; }
  .module-icon.member {
    background-position: 0px 0px; }
  .module-icon.donation {
    background-position: 0px -281px; }
  .module-icon.accounting {
    background-position: -90px -280px; }
  .module-icon.election {
    background-position: -90px -70px; }
  .module-icon.shop {
    background-position: -90px 0px; }
  .module-icon.p2p {
    background-position: -180px 0px; }
  .module-icon.formation {
    background-position: 0px -70px; }
  .module-icon.job {
    background-position: -180px -70px; }
  .module-icon.web {
    background-position: 0px -140px; }
  .module-icon.congres {
    background-position: -90px -140px; }
  .module-icon.event {
    background-position: -180px -140px; }
  .module-icon.communication {
    background-position: 0px -210px; }
  .module-icon.payment {
    background-position: -180px -210px; }
  .module-icon.crm {
    background-position: -180px -280px; }
  .module-icon.jamespot {
    background-position: 0px -350px; }
  .module-icon.smacl {
    background-position: -90px -349px; }
  .module-icon.association {
    background-position: 0px -420px; }
  .module-icon.creditagricole {
    background-position: -90px -420px; }
  .module-icon.formbuilder {
    background-position: -180px -420px; }
  .module-icon.config {
    padding: 12px; }
    .module-icon.config::before {
      width: 100%;
      height: 100%;
      background-size: cover;
      vertical-align: baseline; }
  .module-icon.home {
    padding: 11px; }
  .module-icon.small {
    width: 54px;
    height: 42px;
    background-size: 162px 294px; }
    .module-icon.small.configuration {
      background-position: -54px -126px; }
    .module-icon.small.member {
      background-position: 0px 0px; }
    .module-icon.small.donation {
      background-position: 0px -168px; }
    .module-icon.small.accounting {
      background-position: -54px -168px; }
    .module-icon.small.election {
      background-position: -54px -42px; }
    .module-icon.small.shop {
      background-position: -54px 0px; }
    .module-icon.small.p2p {
      background-position: -108px 0px; }
    .module-icon.small.formation {
      background-position: 0px -42px; }
    .module-icon.small.job {
      background-position: -108px -42px; }
    .module-icon.small.web {
      background-position: 0px -84px; }
    .module-icon.small.congres {
      background-position: -54px -84px; }
    .module-icon.small.event {
      background-position: -108px -84px; }
    .module-icon.small.communication {
      background-position: 0px -126px; }
    .module-icon.small.payment {
      background-position: -108px -126px; }
    .module-icon.small.crm {
      background-position: -108px -168px; }
    .module-icon.small.jamespot {
      background-position: 0px -210px; }
    .module-icon.small.smacl {
      background-position: -108px -209px; }
    .module-icon.small.association {
      background-position: 0px -252px; }
    .module-icon.small.creditagricole {
      background-position: -54px -252px; }
    .module-icon.small.formbuilder {
      background-position: -108px -252px; }
    .module-icon.small.config {
      padding: 7px; }
  .module-icon.in--appInfoWrapper {
    width: 30px;
    height: 24px;
    background-size: 90px 164px; }
    .module-icon.in--appInfoWrapper.configuration {
      background-position: -30px -70px; }
    .module-icon.in--appInfoWrapper.member {
      background-position: 0px 0px; }
    .module-icon.in--appInfoWrapper.donation {
      background-position: 0px -94px; }
    .module-icon.in--appInfoWrapper.accounting {
      background-position: -30px -94px; }
    .module-icon.in--appInfoWrapper.election {
      background-position: -30px -23px; }
    .module-icon.in--appInfoWrapper.shop {
      background-position: -30px 0px; }
    .module-icon.in--appInfoWrapper.p2p {
      background-position: -60px 0px; }
    .module-icon.in--appInfoWrapper.formation {
      background-position: 0px -24px; }
    .module-icon.in--appInfoWrapper.job {
      background-position: -60px -24px; }
    .module-icon.in--appInfoWrapper.web {
      background-position: 0px -47px; }
    .module-icon.in--appInfoWrapper.congres {
      background-position: -30px -46px; }
    .module-icon.in--appInfoWrapper.event {
      background-position: -60px -47px; }
    .module-icon.in--appInfoWrapper.communication {
      background-position: 0px -71px; }
    .module-icon.in--appInfoWrapper.payment {
      background-position: -60px -70px; }
    .module-icon.in--appInfoWrapper.crm {
      background-position: -60px -94px; }
    .module-icon.in--appInfoWrapper.jamespot {
      background-position: 0px -117px; }
    .module-icon.in--appInfoWrapper.smacl {
      background-position: -60px -117px; }
    .module-icon.in--appInfoWrapper.association {
      background-position: 0px -141px; }
    .module-icon.in--appInfoWrapper.creditagricole {
      background-position: -30px -141px; }
    .module-icon.in--appInfoWrapper.formbuilder {
      background-position: -60px -141px; }
    .module-icon.in--appInfoWrapper.config {
      padding: 3px; }
  .module-icon.in--app-box-select {
    width: 90px;
    height: 70px;
    background-size: 270px 490px; }
    .module-icon.in--app-box-select.configuration {
      background-position: -90px -210px; }
    .module-icon.in--app-box-select.member {
      background-position: 0px 0px; }
    .module-icon.in--app-box-select.donation {
      background-position: 0px -280px; }
    .module-icon.in--app-box-select.accounting {
      background-position: -90px -280px; }
    .module-icon.in--app-box-select.election {
      background-position: -90px -70px; }
    .module-icon.in--app-box-select.shop {
      background-position: -90px 0px; }
    .module-icon.in--app-box-select.p2p {
      background-position: -180px 0px; }
    .module-icon.in--app-box-select.formation {
      background-position: 0px -70px; }
    .module-icon.in--app-box-select.job {
      background-position: -180px -70px; }
    .module-icon.in--app-box-select.web {
      background-position: 0px -140px; }
    .module-icon.in--app-box-select.congres {
      background-position: -90px -140px; }
    .module-icon.in--app-box-select.event {
      background-position: -180px -140px; }
    .module-icon.in--app-box-select.communication {
      background-position: 0px -210px; }
    .module-icon.in--app-box-select.payment {
      background-position: -180px -210px; }
    .module-icon.in--app-box-select.crm {
      background-position: -180px -280px; }
    .module-icon.in--app-box-select.jamespot {
      background-position: 0px -350px; }
    .module-icon.in--app-box-select.smacl {
      background-position: -90px -348px; }
    .module-icon.in--app-box-select.association {
      background-position: 0px -420px; }
    .module-icon.in--app-box-select.creditagricole {
      background-position: -90px -420px; }
    .module-icon.in--app-box-select.formbuilder {
      background-position: -180px -420px; }
  .module-icon.module-icon__app-select {
    width: 72px;
    height: 56px;
    background-size: 216px 392px; }
    .module-icon.module-icon__app-select.configuration {
      background-position: -72px -168px; }
    .module-icon.module-icon__app-select.member {
      background-position: 0px 0px; }
    .module-icon.module-icon__app-select.donation {
      background-position: 0px -224px; }
    .module-icon.module-icon__app-select.accounting {
      background-position: -72px -224px; }
    .module-icon.module-icon__app-select.election {
      background-position: -72px -56px; }
    .module-icon.module-icon__app-select.shop {
      background-position: -72px 0px; }
    .module-icon.module-icon__app-select.p2p {
      background-position: -144px 0px; }
    .module-icon.module-icon__app-select.formation {
      background-position: 0px -56px; }
    .module-icon.module-icon__app-select.job {
      background-position: -144px -56px; }
    .module-icon.module-icon__app-select.web {
      background-position: 0px -112px; }
    .module-icon.module-icon__app-select.congres {
      background-position: -72px -112px; }
    .module-icon.module-icon__app-select.event {
      background-position: -144px -112px; }
    .module-icon.module-icon__app-select.communication {
      background-position: 0px -168px; }
    .module-icon.module-icon__app-select.payment {
      background-position: -144px -168px; }
    .module-icon.module-icon__app-select.crm {
      background-position: -144px -224px; }
    .module-icon.module-icon__app-select.jamespot {
      background-position: 0px -280px; }
    .module-icon.module-icon__app-select.smacl {
      background-position: -144px -279px; }
    .module-icon.module-icon__app-select.association {
      background-position: 0px -336px; }
    .module-icon.module-icon__app-select.creditagricole {
      background-position: -72px -336px; }
    .module-icon.module-icon__app-select.formbuilder {
      background-position: -144px -336px; }
  .module-icon.module-icon__widget {
    width: 75px;
    height: 59px;
    background-size: 225px 409px; }
    .module-icon.module-icon__widget.configuration {
      background-position: -75px -177px; }
    .module-icon.module-icon__widget.member {
      background-position: 0px 1px; }
    .module-icon.module-icon__widget.donation {
      background-position: 0px -235px; }
    .module-icon.module-icon__widget.accounting {
      background-position: -74px -234px; }
    .module-icon.module-icon__widget.election {
      background-position: -75px -56px; }
    .module-icon.module-icon__widget.shop {
      background-position: -75px 0px; }
    .module-icon.module-icon__widget.p2p {
      background-position: -150px 0px; }
    .module-icon.module-icon__widget.formation {
      background-position: 0px -59px; }
    .module-icon.module-icon__widget.job {
      background-position: -150px -58px; }
    .module-icon.module-icon__widget.web {
      background-position: 0px -117px; }
    .module-icon.module-icon__widget.congres {
      background-position: -75px -118px; }
    .module-icon.module-icon__widget.event {
      background-position: -151px -116px; }
    .module-icon.module-icon__widget.communication {
      background-position: 0px -175px; }
    .module-icon.module-icon__widget.payment {
      background-position: -150px -175px; }
    .module-icon.module-icon__widget.crm {
      background-position: -149px -233px; }
    .module-icon.module-icon__widget.jamespot {
      background-position: 0px -292px; }
    .module-icon.module-icon__widget.smacl {
      background-position: -150px -294px; }
    .module-icon.module-icon__widget.association {
      background-position: 0px -351px; }
    .module-icon.module-icon__widget.creditagricole {
      background-position: -75px -354px; }
    .module-icon.module-icon__widget.formbuilder {
      background-position: -150px -350px; }
  .module-icon.module-icon__widget-small {
    width: 23px;
    height: 18px;
    background-size: 68px 123px; }
    .module-icon.module-icon__widget-small.configuration {
      background-position: -23px -52px; }
    .module-icon.module-icon__widget-small.member {
      background-position: 0px 0px; }
    .module-icon.module-icon__widget-small.donation {
      background-position: 0px -71px; }
    .module-icon.module-icon__widget-small.accounting {
      background-position: -23px -70px; }
    .module-icon.module-icon__widget-small.election {
      background-position: -23px -17px; }
    .module-icon.module-icon__widget-small.shop {
      background-position: -23px 0px; }
    .module-icon.module-icon__widget-small.p2p {
      background-position: -46px 0px; }
    .module-icon.module-icon__widget-small.formation {
      background-position: 0px -18px; }
    .module-icon.module-icon__widget-small.job {
      background-position: -45px -18px; }
    .module-icon.module-icon__widget-small.web {
      background-position: 0px -35px; }
    .module-icon.module-icon__widget-small.congres {
      background-position: -23px -34px; }
    .module-icon.module-icon__widget-small.event {
      background-position: -46px -35px; }
    .module-icon.module-icon__widget-small.communication {
      background-position: 0px -53px; }
    .module-icon.module-icon__widget-small.payment {
      background-position: -46px -52px; }
    .module-icon.module-icon__widget-small.crm {
      background-position: -46px -70px; }
    .module-icon.module-icon__widget-small.jamespot {
      background-position: 0px -88px; }
    .module-icon.module-icon__widget-small.smacl {
      background-position: -46px -88px; }
    .module-icon.module-icon__widget-small.association {
      background-position: 0px -105px; }
    .module-icon.module-icon__widget-small.creditagricole {
      background-position: -23px -105px; }
    .module-icon.module-icon__widget-small.formbuilder {
      background-position: -46px -105px; }
    .module-icon.module-icon__widget-small.config {
      padding: 2px; }

/** for CAPG - mai 2018 **/
.module-icon.module-icon__widget.creditagricole,
.module-icon.module-icon__app-select.creditagricole {
  background: url(../images/logo-capg-appbox.png) no-repeat center top;
  background-size: 86px 74px;
  width: 100%;
  height: 79px; }
  .module-icon.module-icon__widget.creditagricole + .widget-apps__item-title,
  .module-icon.module-icon__widget.creditagricole + .app-dropdown__app-title,
  .module-icon.module-icon__app-select.creditagricole + .widget-apps__item-title,
  .module-icon.module-icon__app-select.creditagricole + .app-dropdown__app-title {
    display: none !important; }

.module-icon.module-icon__app-select.creditagricole {
  margin-top: 6px !important;
  margin-bottom: 11px !important; }

.btn.btn-default, .btn-group.btn-group__primary > .btn-default.dropdown-toggle {
  padding: 10px 15px;
  border: 1px solid #cbccce;
  color: #000000;
  box-shadow: 0 3px 3.96px 0.04px rgba(0, 0, 0, 0.08);
  background: #efefef;
  /* fallback */
  background: -webkit-linear-gradient(top, #efefef 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #efefef 0%, #dcdcdc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#efefef', endColorstr='#dcdcdc', GradientType=0); }
  .btn.btn-default:hover, .btn-group.btn-group__primary > .btn-default.dropdown-toggle:hover {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border-color: #bcbec0;
    background: #ffffff;
    /* fallback */
    background: -webkit-linear-gradient(top, #ffffff 0%, #dcddde 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #dcddde 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dcddde', GradientType=0); }
  .btn.btn-default:focus, .btn-group.btn-group__primary > .btn-default.dropdown-toggle:focus, .btn.btn-default:active:focus, .btn-group.btn-group__primary > .btn-default.dropdown-toggle:active:focus, .btn.btn-default.active:focus, .btn-group.btn-group__primary > .btn-default.active.dropdown-toggle:focus, .btn.btn-default:active:hover, .btn-group.btn-group__primary > .btn-default.dropdown-toggle:active:hover {
    background: #fbfbfb;
    color: #000000;
    border-color: #bcbec0; }

.btn.btn-edit::before, .btn-group.btn-group__primary > .btn-edit.dropdown-toggle::before {
  content: "\e827";
  margin: 0 8px 0 -1px; }

.btn.btn-settings::before, .btn-group.btn-group__primary > .btn-settings.dropdown-toggle::before {
  content: "\e844";
  margin: 0;
  font-size: 15px; }

.btn.btn-columns, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle {
  padding: 10px 15px;
  border: 1px solid #cbccce;
  color: #000000;
  box-shadow: 0 3px 3.96px 0.04px rgba(0, 0, 0, 0.08);
  background: #efefef;
  /* fallback */
  background: -webkit-linear-gradient(top, #efefef 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #efefef 0%, #dcdcdc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#efefef', endColorstr='#dcdcdc', GradientType=0); }
  .btn.btn-columns:hover, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle:hover {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border-color: #bcbec0;
    background: #ffffff;
    /* fallback */
    background: -webkit-linear-gradient(top, #ffffff 0%, #dcddde 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #dcddde 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dcddde', GradientType=0); }
  .btn.btn-columns:focus, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle:focus, .btn.btn-columns:active:focus, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle:active:focus, .btn.btn-columns.active:focus, .btn-group.btn-group__primary > .btn-columns.active.dropdown-toggle:focus, .btn.btn-columns:active:hover, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle:active:hover {
    background: #fbfbfb;
    color: #000000;
    border-color: #bcbec0; }
  .btn.btn-columns::before, .btn-group.btn-group__primary > .btn-columns.dropdown-toggle::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    content: "\f0db"; }

.btn.btn-preview, .btn-group.btn-group__primary > .btn-preview.dropdown-toggle {
  padding-top: 9px;
  padding-bottom: 9px; }
  .btn.btn-preview:before, .btn-group.btn-group__primary > .btn-preview.dropdown-toggle:before {
    position: relative;
    top: 1px;
    font-size: 15px; }

.btn.btn-learnmore, .btn-group.btn-group__primary > .btn-learnmore.dropdown-toggle {
  background: #00A572;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 9px 20px;
  opacity: 1;
  transition: all 33ms ease-in-out; }
  .btn.btn-learnmore:hover, .btn-group.btn-group__primary > .btn-learnmore.dropdown-toggle:hover, .btn.btn-learnmore:focus, .btn-group.btn-group__primary > .btn-learnmore.dropdown-toggle:focus {
    background: #00A572;
    color: #FFFFFF;
    opacity: 0.9; }

.btn.btn-login, .btn-group.btn-group__primary > .btn-login.dropdown-toggle {
  color: #ffffff;
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 19px 15px;
  border-radius: 2px;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
  background: #f6b73f;
  /* fallback */
  background: -webkit-linear-gradient(top, #f6b73f 0%, #faa81a 100%);
  background: linear-gradient(to bottom, #f6b73f 0%, #faa81a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6b73f', endColorstr='#faa81a', GradientType=0); }
  .btn.btn-login:hover, .btn-group.btn-group__primary > .btn-login.dropdown-toggle:hover {
    background: #faa81a;
    /* fallback */
    background: -webkit-linear-gradient(top, #faa81a 0%, #faa81a 100%);
    background: linear-gradient(to bottom, #faa81a 0%, #faa81a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa81a', endColorstr='#faa81a', GradientType=0); }
  .btn.btn-login:focus, .btn-group.btn-group__primary > .btn-login.dropdown-toggle:focus, .btn.btn-login:active:focus, .btn-group.btn-group__primary > .btn-login.dropdown-toggle:active:focus, .btn.btn-login.active:focus, .btn-group.btn-group__primary > .btn-login.active.dropdown-toggle:focus, .btn.btn-login:active:hover, .btn-group.btn-group__primary > .btn-login.dropdown-toggle:active:hover {
    background: #faa81a;
    /* fallback */
    background: -webkit-linear-gradient(top, #faa81a 0%, #f6b73f 100%);
    background: linear-gradient(to bottom, #faa81a 0%, #f6b73f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa81a', endColorstr='#f6b73f', GradientType=0); }

.btn.btn-login-google, .btn-group.btn-group__primary > .btn-login-google.dropdown-toggle {
  font-weight: 600;
  line-height: normal;
  font-size: 16px;
  color: #3F403F;
  background-color: #ffffff;
  border: 1px solid #C4C4C4;
  padding-top: 16px;
  padding-bottom: 16px; }
  .btn.btn-login-google .icn-google, .btn-group.btn-group__primary > .btn-login-google.dropdown-toggle .icn-google {
    margin-right: 5px; }
  .btn.btn-login-google .fa-facebook, .btn-group.btn-group__primary > .btn-login-google.dropdown-toggle .fa-facebook {
    font-size: 17px;
    color: #4267b2;
    margin-right: 10px;
    position: relative;
    top: 1px; }

.btn[class*="icon-"]:empty:before, .btn-group.btn-group__primary > [class*="icon-"].dropdown-toggle:empty:before {
  margin: 0; }

.btn[class*="icon-"].icon-custom-export:before, .btn-group.btn-group__primary > [class*="icon-"].icon-custom-export.dropdown-toggle:before {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url(../images/ico_export@2x.png) no-repeat center center;
  background-size: cover;
  vertical-align: middle; }

.btn[class*="icon-"].icon-file-pdf:before, .btn-group.btn-group__primary > [class*="icon-"].icon-file-pdf.dropdown-toggle:before {
  color: #ef0c0d; }

.btn.close, .btn-group.btn-group__primary > .close.dropdown-toggle {
  opacity: 1;
  filter: alpha(opacity=100);
  text-shadow: none;
  float: none; }
  .btn.close:hover, .btn-group.btn-group__primary > .close.dropdown-toggle:hover {
    opacity: 1;
    filter: alpha(opacity=100); }

.btn.btn--loading, .btn-group.btn-group__primary > .btn--loading.dropdown-toggle {
  color: transparent;
  text-shadow: none;
  position: relative; }
  .btn.btn--loading .mgo-loader, .btn-group.btn-group__primary > .btn--loading.dropdown-toggle .mgo-loader {
    color: white; }
    .btn.btn--loading .mgo-loader__wrapper, .btn-group.btn-group__primary > .btn--loading.dropdown-toggle .mgo-loader__wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }

.btn-group.status {
  line-height: 30px; }
  .btn-group.status .dropdown-toggle {
    background-color: #000000;
    border-radius: 2em;
    padding: 0 9px 0 10px; }
    .btn-group.status .dropdown-toggle span {
      color: #ffffff;
      font-size: 14px; }
    .btn-group.status .dropdown-toggle .toggle {
      color: #727176; }
  .btn-group.status .dropdown-menu > li > a {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 700; }

.btn-group.arrow .dropdown-menu {
  top: 105%; }
  .btn-group.arrow .dropdown-menu:before {
    content: '';
    position: absolute;
    left: 44px;
    top: -8px;
    z-index: 9999;
    width: 18px;
    height: 9px;
    background: url(../images/fleche-bl-top.png) no-repeat center bottom; }

.btn-group.arrow.arrow-right .dropdown-menu:before {
  left: auto;
  right: 44px; }

.btn-group.arrow.dropup .dropdown-menu {
  top: auto;
  bottom: 107%; }
  .btn-group.arrow.dropup .dropdown-menu:before {
    top: auto;
    left: 44px;
    bottom: -8px;
    background-image: url(../images/fleche-bl-bottom.png); }

.btn-group.arrow.dropup.arrow-right .dropdown-menu:before {
  left: auto;
  right: 44px; }

.btn-group.arrow.dropleft.options .dropdown-toggle:hover {
  cursor: pointer; }

.btn-group.arrow.dropleft .dropdown-menu:before {
  width: 9px;
  height: 18px;
  right: -8px;
  left: auto;
  top: 12px;
  background-image: url(../images/fleche-bl-right.png); }

.btn-group.arrow.dropleft .dropdown-menu.table-option {
  width: auto; }
  .btn-group.arrow.dropleft .dropdown-menu.table-option:before {
    display: none; }

.btn-group.icon {
  background-color: transparent;
  line-height: 1; }
  .btn-group.icon .dropdown-toggle {
    min-width: 0;
    padding: 0;
    font-size: 15px; }
    .btn-group.icon .dropdown-toggle span {
      color: #000000; }
      .btn-group.icon .dropdown-toggle span [class*='icon-'] {
        margin: 0; }

.btn-group.btn-group__primary > .dropdown-toggle span {
  color: inherit;
  margin-right: 0; }

.btn-group.btn-group__primary > .dropdown-toggle .toggle {
  color: inherit; }
  .btn-group.btn-group__primary > .dropdown-toggle .toggle::before {
    margin-left: 0;
    margin-right: -3px; }

.ui-autocomplete.ui-widget-content {
  font-family: inherit;
  font-weight: inherit;
  max-height: 200px;
  overflow-y: scroll; }
  .ui-autocomplete.ui-widget-content .ui-state-focus {
    margin: 0;
    border: 0;
    font-weight: inherit;
    color: white;
    background: #1da7e4; }

.fg-label.input-required:after, .input-required > .fg-label:after, .form-group.input-required > label:after {
  content: '*';
  color: #ff5e58;
  margin-left: 3px; }

/*
* Remove iOS default styling.
*/
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  appearance: none; }

.fg-label {
  display: block; }
  .fg-label.label-inline {
    display: inline-block;
    margin-right: 8px; }

.disp-ib .help-block {
  max-width: 400px;
  line-height: 1.2; }

#pagenameinfo .help-block {
  /* MGO-6229 - Super hack :'( -- Article associé à la page. */
  white-space: nowrap; }

.form-control-static {
  /* MGO-5993 */
  min-height: 23px;
  padding-top: -2px;
  padding-bottom: -2px;
  line-height: 2.7rem; }
  .form-horizontal .form-control-static {
    min-height: 35px;
    padding-top: 4px;
    padding-bottom: 4px; }

.form-horizontal .form-group {
  margin-bottom: 1px; }

.form-horizontal .form-group:first-child,
.form-horizontal h2 + .form-group {
  margin-top: -11px; }

.form-horizontal .control-label {
  text-align: left;
  padding-top: 4px;
  line-height: 2.7rem; }

.form-horizontal .errors {
  padding-top: 10px; }

.form-horizontal > .row > .col-xs-6 .control-label {
  width: 25%; }

.form-horizontal > .row > .col-xs-6 .form-control-static {
  width: 75%; }

.form-view.form-horizontal .form-group {
  margin-bottom: 0; }
  .form-view.form-horizontal .form-group.input-required {
    margin: 0; }

.form-group.input-tall .control-label {
  font-weight: bold; }

.form-group.input-tall .form-control {
  height: 45px; }

.form-group.media-preview .control-label {
  display: block; }

.form-group.media-preview .form-control-preview {
  display: inline-block;
  max-width: 400px;
  width: auto;
  line-height: 23px;
  min-height: 35px;
  padding-bottom: 6px;
  padding-top: 6px; }
  .form-group.media-preview .form-control-preview img {
    width: 100%; }

.form-group.media-preview .form-control-actions {
  display: inline-block;
  vertical-align: top; }

.form-group > .checkbox ~ .ml25,
.form-group > .radio ~ .ml25,
.form-group > .ml25 ~ .checkbox,
.form-group > .ml25 ~ .radio {
  margin-top: -5px; }

.ml25 > .form-group {
  margin-bottom: 18px; }

.has-feedback .form-control {
  padding-right: 30px; }

.has-feedback.input-tall .form-control {
  padding-right: 40px; }

.has-feedback.input-tall .form-control-feedback {
  font-size: 24px;
  right: 5px; }

.input-group {
  width: 400px; }

.form-control {
  width: 400px; }
  .form-control:focus::placeholder {
    opacity: 0;
    filter: alpha(opacity=0); }
  .form-control.input-tall {
    height: 45px; }
  .form-control.fc-xs {
    width: 52px !important; }
  .form-control.fc-sm {
    width: 100px !important; }
    .form-control.fc-sm.fc-icon {
      width: 133px !important; }
  .form-control.fc-md {
    width: 230px !important; }
    .form-control.fc-md.fc-icon {
      width: 263px !important; }
  .form-control.fc-lg {
    width: 400px !important; }
    .form-control.fc-lg.fc-icon {
      width: 433px !important; }
  .form-control.fc-xlg {
    width: 550px !important; }
    .form-control.fc-xlg.fc-icon {
      width: 583px !important; }
  .form-control.fc-full {
    width: 100% !important; }
  .form-control.fc-icon {
    padding-right: 33px;
    background-repeat: no-repeat;
    background-position: center right 9px; }
  .form-control.fc-datepicker {
    background-image: url(../images/ico_calendar.png);
    width: 152px !important; }
  .form-control.fc-timepicker {
    background-image: url(../images/ico_clock.png); }

/* @deprecated ux4 - transferred to resources/assets for retrocompatibility
.has-success,
.has-error,
.has-warning {
  .form-control {
    box-shadow: $input-outside-shadow;

    &:focus {
      box-shadow: $input-outside-shadow;
    }
  }

  .errors {
    margin: 4px 0 0 0;
    padding: 0;
    list-style: none;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 1.3;
  }

  .input-group-addon {
    color: $input-addon-color;
    background-color: $input-bg-color;
  }
}

.has-success {
  .control-label {
    color: inherit;
  }

  .form-control {
    border-color: $input-success-border-color;

    &:focus {
      border-color: $input-success-border-color;
    }
  }

  .errors {
    color: $input-success-color;
  }

  .checkbox {
    color: $input-success-color;
  }

  .form-control-feedback {
    color: $input-success-color;
  }

  .input-group-addon {
    border-color: $input-success-border-color;
  }
}

.has-error {
  .control-label, .fg-label {
    color: inherit;
  }

  .form-control {
    border-color: $input-error-border-color;

    &:focus {
      border-color: $input-error-border-color;
    }
  }

  .errors {
    color: $input-error-color;
  }

  .checkbox {
    color: $input-error-color;
  }

  .form-control-feedback {
    color: $input-error-color;
  }

  .input-group-addon {
    border-color: $input-error-border-color;
  }
}

.has-warning {
  .control-label {
    color: $input-warning-color;
  }

  .form-control {
    border-color: $input-warning-border-color;

    &:focus {
      border-color: $input-warning-border-color;
    }
  }

  .errors {
    color: $input-warning-color;
  }

  .checkbox {
    color: $input-warning-color;
  }

  .form-control-feedback {
    color: $input-warning-color;
  }

  .input-group-addon {
    border-color: $input-warning-border-color;
  }
}
*/
.input-group {
  /*
   * @TODO#SHAME - MEMBOGO-5254 - perçu d'avance.
   * - Ajuster en back et non-front.
   */ }
  .o-layout-wrapper .input-group {
    width: auto; }
  .input-group.input-tall .input-group-addon,
  .input-group.input-tall .form-control {
    height: 45px; }
  .input-group.input-tall .input-group-addon {
    padding: 0;
    width: 45px; }
    .input-group.input-tall .input-group-addon [class*='icon']:before {
      font-size: 24px; }
  .input-group.input-tall .input-group-btn .btn, .input-group.input-tall .input-group-btn .btn-group.btn-group__primary > .dropdown-toggle {
    height: 45px;
    min-width: 45px; }
  .input-group[class*='has-'] {
    position: relative;
    padding-bottom: 24px; }
    .input-group[class*='has-'] ul {
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0; }
    .input-group[class*='has-'].has-error-global {
      padding-bottom: 0; }
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0 3px 3px 0; }

.input-group-addon {
  background-color: #fbfbfb;
  color: #000000;
  box-shadow: 0 3px 1.96px 0.04px rgba(0, 0, 0, 0.05);
  border-color: #c7c8ca;
  border-radius: 3px; }
  .input-group-addon .icon-search {
    font-size: 13px; }
    .input-group-addon .icon-search::before {
      margin: 0; }

/*
 * @TODO#SHAME - MEMBOGO-5254 - perçu d'avance.
 * - Ajuster en back et non-front.
 */
.input-group-addon,
.input-group-btn {
  width: auto; }
  .input-group-addon:last-child .dropdown-toggle,
  .input-group-btn:last-child .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

select.form-control {
  appearance: none;
  background: #fff url(../images/icon-membogo-arrow-combo@2x.png) no-repeat right 5px center;
  background-size: 13px 13px;
  padding-right: 28px; }
  select.form-control::-ms-expand {
    display: none; }
  select.form-control[multiple], select.form-control[size] {
    background-image: none;
    padding-right: 0; }
    select.form-control[multiple] option, select.form-control[size] option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.file-upload .btn, .file-upload .btn-group.btn-group__primary > .dropdown-toggle {
  position: relative;
  overflow: hidden; }
  .file-upload .btn input[type="file"], .file-upload .btn-group.btn-group__primary > .dropdown-toggle input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    opacity: 0;
    filter: alpha(opacity=0);
    outline: none;
    cursor: inherit; }

.file-upload .upload-info {
  display: inline-block;
  margin: 0 7px; }

.form-inline.search-form__date-pickers {
  margin-bottom: -12px; }
  .form-inline.search-form__date-pickers .form-group {
    margin-bottom: 12px; }

.inline-form-group {
  margin-left: -5px;
  margin-right: -5px; }
  .inline-form-group > [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px; }

fieldset + fieldset {
  margin-top: 30px; }

dl,
dd {
  margin: 0; }

.advanced-search-form .inline-form-group {
  margin-bottom: 0; }

.advanced-search-form .alert-dismissible {
  margin-bottom: 13px; }
  .advanced-search-form .alert-dismissible [data-dismiss="alert"] {
    margin-top: 9px; }

form + form {
  margin-top: 18px; }

.list-wrapper {
  margin-bottom: 26px;
  line-height: 1; }
  .list-wrapper:last-child {
    margin-bottom: 0; }
  .list-wrapper .fg-label {
    margin-bottom: 18px; }
  .list-wrapper .list-item + .list-item {
    margin-top: -2px; }

/* MGO-4993 */
.form-horizontal textarea,
.form-horizontal .checkbox:last-child {
  margin-bottom: 18px; }

/* MEMBOGO-6855, MEMBOGO-6986 */
.form-create .section .row + .row,
.crm-entity-edition-form .section .row + .row {
  margin-top: 18px; }

.form-section__description {
  border: 1px solid #d8d8d9;
  background-color: #F5F5F6;
  padding: 9px 14px;
  margin-bottom: 25px; }

.crm-entity__info-wrapper {
  margin-bottom: 20px; }
  .crm-entity__info-wrapper .crm-entity__info-content {
    background: #fff;
    border: 1px solid #d5d7d8;
    padding: 13px 15px; }

.field-object-element-list {
  margin-bottom: 13px;
  line-height: 1;
  width: 400px;
  max-width: 100%;
  display: flex; }
  .field-object-element-list > select {
    flex-grow: 1; }
  .field-object-element-list > .field-object-add-element {
    margin-left: 5px; }
  .field-object-element-list .input-group-addon {
    width: 1%; }

.field-object-element {
  margin-bottom: 9.75px; }
  .field-object-element .field-object-remove-element {
    padding: 0;
    margin: -1px 6px 0; }

/* @deprecated with ux4
.alert {
  padding: 0;
  margin-bottom: 10px;
  border: none;
  border-radius: 0;
  position: relative;
  list-style: none;
  font-size: 14px;
  line-height: $body-line-height-ratio;

  ul {
    list-style: none;
    padding: 0;
  }

  &.alert-success,
  &.alert-danger,
  &.alert-info,
  &.alert-warning {
    padding: 13px;
    border: 1px solid transparent;
    text-align: center;
    border-radius: $input-border-radius;

    &.alert-icon {
      padding: 12px;

      [class*='icon-'] {
        font-size: 19px;
        vertical-align: middle;
      }
    }

    .alert-link {
      color: inherit;
    }

    .message {
      vertical-align: middle;
    }
  }

  &.alert-success {
    color: $alert-success-color;
    background-color: $alert-success-bg;
    border-color: $alert-success-color;
  }

  &.alert-danger {
    color: $alert-danger-color;
    background-color: $alert-danger-bg;
    border-color: $alert-danger-color;
  }

  &.alert-warning {
    color: $alert-warning-color;
    background-color: $alert-warning-bg;
    border-color: $alert-warning-color;
  }

  &.alert-info {
    color: $alert-info-color;
    background-color: $alert-info-bg;
    border-color: $alert-info-color;

    a,
    .alert-link {
      display: inline-block;
      font-size: inherit;
      font-weight: $semibold;
      color: #002e4b;

      &.onlinehelp-linkout__withText {
        i::before {
          margin-right: 0;
        }
      }
    }
  }



  @at-root {
    #message_box & {
      > ul {
        max-width: $page-width-fixed;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
*/
/* @deprecated with ux4
.alert-dismissable,
.alert-dismissible {
  padding-right: 35px !important;

  [data-dismiss="alert"] {
    float: none;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
*/
/* Inline warning */
/* @deprecated with ux4
.alert-warning {
  color: $input-warning-color;
  background-color: transparent;
  border: 0;
}
*/
.alert.alert-trial-green, .alert.alert-trial-red {
  padding: 9px 13px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  text-align: center; }
  .alert.alert-trial-green a, .alert.alert-trial-red a {
    color: #fff;
    text-decoration: underline; }

.alert.alert-trial-green {
  background: #50b848; }

.alert.alert-trial-red {
  background: #e23b35; }

.alert .priority-messages.user-password-info {
  width: 400px; }

/* Variables */
/* UI 2.0 */
/* Progressively migrate 1.0 styles here */
#wizard {
  display: none;
  position: fixed;
  z-index: 1002;
  width: 531px;
  top: 106px;
  bottom: 0;
  right: -531px;
  background-color: #fafafb;
  border-left: 1px solid #cccdcd;
  box-shadow: 10px 10px 21px 1px #272727; }
  #wizard .circle .mask,
  #wizard .circle .fill,
  #wizard .circle .shadow {
    width: 122px;
    height: 122px;
    position: absolute;
    border-radius: 50%; }
  #wizard .mask,
  #wizard .fill {
    -webkit-backface-visibility: hidden;
    transition: transform 1s;
    border-radius: 50%; }
  #wizard .mask {
    clip: rect(0px, 122px, 122px, 61px); }
    #wizard .mask .fill {
      clip: rect(0px, 61px, 122px, 0px);
      background-color: #000000; }
  #wizard .inset {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -48px;
    margin-top: -48px;
    background-color: #ffffff;
    border-radius: 50%; }
  #wizard .percentage {
    font: 10px/10px Montserrat, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    overflow: hidden;
    position: absolute;
    top: 27px;
    left: 0;
    right: 0;
    line-height: 1;
    text-align: center; }
    #wizard .percentage .numbers {
      font-size: 36px;
      line-height: 36px;
      font-weight: 700; }
      #wizard .percentage .numbers span {
        font-size: 26px;
        font-weight: 400; }
  #wizard h3 {
    font-size: 15px;
    line-height: 17px;
    text-align: left;
    margin: 0 0 10px;
    overflow-wrap: break-word;
    word-wrap: break-word; }
    #wizard h3.wizard-description-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 18px; }
  #wizard .radial-progress {
    margin: 7px auto 20px;
    width: 122px;
    height: 122px;
    background: url(../images/fond-progress.png) no-repeat center;
    border-radius: 50%;
    position: relative; }
    #wizard .radial-progress:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -7px;
      display: block;
      width: 136px;
      height: 136px;
      background-color: #fff;
      border: 1px solid #d4d5d5;
      border-radius: 50%;
      z-index: -1; }

.wizard-description {
  margin-bottom: 1em; }
  .wizard-description p {
    margin-bottom: 12px; }
  .wizard-description ul,
  .wizard-description ol {
    padding-left: 30px;
    line-height: 1.7; }
    .wizard-description ul > li + li,
    .wizard-description ol > li + li {
      padding-top: 5px; }
  .wizard-description img {
    max-width: 100%;
    height: auto !important; }
  .wizard-description .wizard-step-process {
    text-decoration: underline; }
    .wizard-description .wizard-step-process:hover {
      text-decoration: none; }
    .wizard-description .wizard-step-process [class*='icon-'] {
      font-size: 11px;
      margin-left: 2px; }
  .wizard-description .alert {
    margin-top: 30px; }

#wizard-box {
  height: 100%; }
  #wizard-box > .wizard-body {
    height: calc(100% - 55px);
    overflow-y: auto;
    overflow-x: hidden; }
    #wizard-box > .wizard-body > .row {
      margin: 0;
      position: relative; }
  #wizard-box .wizard-detail {
    height: 100%;
    padding: 20px 5px 20px 20px;
    position: absolute;
    top: 0;
    bottom: 0; }
  #wizard-box .wizard-inner-details {
    min-height: calc(100% + 20px);
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #cccdcd;
    background-color: #ffffff; }
  #wizard-box .wizard-group {
    padding: 20px; }
    #wizard-box .wizard-group > .wizard-inner-details {
      margin: 0; }
    #wizard-box .wizard-group h3 {
      color: #333; }
      #wizard-box .wizard-group h3.home-title {
        font-size: 19px;
        margin-bottom: 15px; }
      #wizard-box .wizard-group h3.group-title {
        margin-bottom: 13px; }
    #wizard-box .wizard-group .home-intro {
      line-height: 19px;
      margin-bottom: 21px; }
    #wizard-box .wizard-group .wizard-list {
      padding: 0;
      float: none; }
      #wizard-box .wizard-group .wizard-list > ul > li {
        cursor: default; }
        #wizard-box .wizard-group .wizard-list > ul > li:last-child::before {
          height: 110%; }
      #wizard-box .wizard-group .wizard-list + .group-title {
        margin-top: 8px; }
    #wizard-box .wizard-group .wizard-group-name {
      margin-left: 25px;
      position: relative;
      top: -2px; }
    #wizard-box .wizard-group .wizard-element {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0; }
      #wizard-box .wizard-group .wizard-element.locked .wizard-group-name {
        cursor: not-allowed;
        text-decoration: none; }
  #wizard-box .wizard-element-name {
    line-height: 16px;
    margin-left: 28px;
    overflow-wrap: break-word;
    word-wrap: break-word; }
  #wizard-box .wizard-element {
    margin-top: 10px;
    margin-bottom: 10px; }
    #wizard-box .wizard-element .icone {
      position: relative; }
      #wizard-box .wizard-element .icone .status {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        background-color: #dcdcdc; }
        #wizard-box .wizard-element .icone .status ::before {
          margin: 0 2px; }
    #wizard-box .wizard-element.enabled .icone .status {
      background-color: #b0b0b0; }
    #wizard-box .wizard-element.done .icone .status {
      background: #03ba52 !important; }

.wizard-title {
  background-color: #FBAE17;
  padding: 0;
  margin: 0 0 0 -1px;
  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 53px;
  text-transform: uppercase;
  color: #000;
  border: 1px solid #FBAE17; }
  .wizard-title > .icon-lifebuoy::before {
    font-size: 22px;
    line-height: 30px;
    margin-right: 12px; }
  .wizard-title .wizard-back {
    float: left;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: none;
    color: #000;
    background-color: #FBAE17;
    padding: 0 15px; }
    .wizard-title .wizard-back ::before {
      font-size: 33px;
      margin: 0 11px 0 0;
      width: auto;
      vertical-align: middle;
      position: relative;
      top: -2px; }
    .wizard-title .wizard-back:hover {
      text-decoration: none; }
  .wizard-title .wizard-close {
    float: right;
    color: #000;
    background-color: #FBAE17;
    padding: 0 18px; }
    .wizard-title .wizard-close ::before {
      position: relative;
      top: 4px;
      font-size: 33px;
      line-height: 30px;
      margin: 0 -2.4px;
      width: auto; }
    .wizard-title .wizard-close:hover, .wizard-title .wizard-close:focus {
      text-decoration: none; }

.wizard-list {
  padding: 20px 10px;
  float: right; }
  .wizard-list > h3 {
    margin-top: 10px !important; }
  .wizard-list > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background: url(../images/fond-steps-wizard.png) repeat-y 8px 0; }
    .wizard-list > ul > li {
      padding: 6px 16px;
      margin-left: -16px;
      border: 1px solid transparent;
      min-height: 32px;
      cursor: not-allowed; }
      .wizard-list > ul > li.enabled {
        cursor: pointer; }
      .wizard-list > ul > li.active {
        background-color: #ffffff;
        border-color: #cccdcd;
        border-left-color: transparent;
        border-radius: 0 4px 4px 0;
        position: relative;
        z-index: 5; }
      .wizard-list > ul > li:first-child, .wizard-list > ul > li:last-child {
        position: relative; }
        .wizard-list > ul > li:first-child:before, .wizard-list > ul > li:last-child:before {
          content: '';
          display: block;
          position: absolute;
          left: 22px;
          width: 5px;
          background-color: #fafafb; }
        .wizard-list > ul > li:first-child.active:before, .wizard-list > ul > li:last-child.active:before {
          display: none; }
      .wizard-list > ul > li:first-child:before {
        top: -1px;
        height: 16px; }
      .wizard-list > ul > li:last-child:before {
        height: calc(100% - 1px);
        margin-top: 1px; }
      .wizard-list > ul > li:only-child:before {
        height: 109%;
        margin-top: -1px; }
  .wizard-list .wizard-back {
    position: relative;
    top: 9px;
    left: -1px; }

.wizard-btn-open {
  position: fixed;
  top: 106px;
  right: 0;
  z-index: 1002;
  display: none; }
  .wizard-btn-open .wizard-close {
    background-color: transparent;
    box-shadow: -1px 1px 9px 0 rgba(0, 0, 0, 0.3);
    padding: 11px 18px; }
    .wizard-btn-open .wizard-close .icon-lifebuoy.mgo-loader {
      color: inherit;
      display: block;
      animation-timing-function: steps(6, end);
      transform: translate3d(0, 0, 0);
      transform-origin: 50% 50%; }
    .wizard-btn-open .wizard-close ::before {
      font-size: 27px;
      top: 0;
      display: block;
      line-height: 1; }

.well {
  background-color: #eaeaea; }

/* Variables */
/* v2.0 */
#main-menu.ui-sortable {
  margin: 0;
  padding: 0;
  list-style: none; }
  #main-menu.ui-sortable li {
    list-style: none;
    background: none;
    border: none;
    position: relative;
    margin: 0 !important;
    color: inherit;
    font-weight: 400; }
    #main-menu.ui-sortable li span.clearleff {
      display: block;
      background-color: #ffffff;
      border: 1px solid #d5d7d8;
      /*padding: 0 8px 0 16px;*/
      height: 40px;
      line-height: 40px;
      cursor: move;
      margin-bottom: -1px !important;
      padding: 0 205px 0 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    #main-menu.ui-sortable li .actions {
      position: absolute;
      top: 0;
      right: 4px;
      height: 40px;
      line-height: 40px; }
      #main-menu.ui-sortable li .actions > span {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle; }
        #main-menu.ui-sortable li .actions > span:nth-last-child(2) {
          margin-right: 5px; }
        #main-menu.ui-sortable li .actions > span:last-child {
          margin-right: 0; }
      #main-menu.ui-sortable li .actions a {
        color: #000000; }
        #main-menu.ui-sortable li .actions a:hover {
          color: #32b7e9; }
      #main-menu.ui-sortable li .actions i {
        font-size: 14px; }
        #main-menu.ui-sortable li .actions i:before {
          margin: 0; }
        #main-menu.ui-sortable li .actions i.icon-ellipsis-vert {
          font-size: 18px;
          line-height: 40px;
          cursor: move; }
      #main-menu.ui-sortable li .actions .page-status {
        color: #ffffff;
        border-radius: 3px;
        padding: 3px 5px;
        margin-left: 25px;
        cursor: default; }
        #main-menu.ui-sortable li .actions .page-status.page-published {
          background: #44ad3d;
          /* fallback */
          background: -moz-linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
          background: -webkit-linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
          background: linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bottom-left-color', endColorstr='$top-right-color', GradientType=1); }
        #main-menu.ui-sortable li .actions .page-status.page-unpublished {
          background: #cfd0d2;
          /* fallback */
          background: -moz-linear-gradient(45deg, #cfd0d2 0%, #cfd0d2 100%);
          background: -webkit-linear-gradient(45deg, #cfd0d2 0%, #cfd0d2 100%);
          background: linear-gradient(45deg, #cfd0d2 0%, #cfd0d2 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bottom-left-color', endColorstr='$top-right-color', GradientType=1); }
    #main-menu.ui-sortable li.ui-sortable-placeholder {
      border: 1px dashed #b0b0b0;
      display: block;
      height: 40px;
      visibility: visible !important;
      background-color: #ebebec;
      color: #939598;
      text-align: center;
      line-height: 40px; }
      #main-menu.ui-sortable li.ui-sortable-placeholder + li > span.clearleff {
        border-top: none !important; }
  #main-menu.ui-sortable ul.sub-menu {
    margin: 0 0 0 20px;
    padding: 0; }
  #main-menu.ui-sortable .item-hovered {
    min-height: 40px;
    border: 1px dashed #b0b0b0;
    margin-top: 1px !important; }

/*
 * v2.2.8
 * - UX improvements: .dd-content box-shadow, hover bg color & pencil icon on hover (denied)
 * v2.2.7
 * New drag & drop library modified for our needs
 * Original source: https://github.com/dbushell/Nestable
 */
.dd {
  max-width: 910px; }
  .dd .dd-item,
  .dd .dd-empty,
  .dd .dd-placeholder {
    display: block;
    position: relative;
    margin-top: -1px;
    padding: 0;
    min-height: 40px;
    line-height: 38px; }
  .dd .dd-item .dd-placeholder > .lvl-1 {
    display: none; }
  .dd .dd-item .dd-placeholder > .lvl-2 {
    display: inline; }
  .dd .dd-handle {
    display: block;
    width: 38px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #d1d3d4;
    cursor: move;
    text-align: center;
    background: #ffffff;
    /* fallback */
    background: -webkit-linear-gradient(top, #ffffff 0%, #e6e7e8 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e6e7e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e7e8', GradientType=0); }
    .dd .dd-handle i {
      position: relative;
      top: -1px;
      font-size: 18px; }
  .dd .dd-content {
    display: block;
    height: 40px;
    padding: 0 0 0 37px;
    border: 1px solid #d1d3d4;
    background-color: #fff;
    box-shadow: 1px 3px 14px -3px rgba(0, 0, 0, 0.4); }
    .dd .dd-content .page-edit-zone {
      display: inline-block;
      line-height: 1;
      width: 100%;
      color: inherit;
      text-decoration: none;
      padding-left: 14px;
      position: relative; }
      .dd .dd-content .page-edit-zone:hover {
        background-color: #f7f7f7;
        /*&::after {
        @extend %iconic-icon-before;
        content: '\e827';
        position: absolute;
        top: -10px;
        right: -12px;
        background-color: #ffcf5b;
        padding: 2px;
        border-radius: 4px;
        width: auto;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.12);
      }*/ }
        .dd .dd-content .page-edit-zone:hover + .page-controls {
          background-color: #f7f7f7; }
      .dd .dd-content .page-edit-zone + .page-controls {
        background: #fff; }
    .dd .dd-content .page-name,
    .dd .dd-content .page-master {
      display: inline-block;
      overflow: hidden;
      line-height: 36px;
      position: relative;
      top: 1px; }
    .dd .dd-content .page-name {
      text-transform: uppercase;
      font-weight: 600;
      max-width: 280px;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .dd .dd-content .page-master {
      color: #c2c2c2;
      margin-left: 14px; }
      .dd .dd-content .page-master:hover, .dd .dd-content .page-master:focus {
        color: #32b7e9; }
      .dd .dd-content .page-master > i {
        margin-right: 4px; }
    .dd .dd-content .page-controls {
      display: inline-block;
      line-height: 38px;
      position: absolute;
      top: 1px;
      right: 1px;
      padding-left: 10px;
      padding-right: 10px; }
      .dd .dd-content .page-controls a {
        color: #000; }
        .dd .dd-content .page-controls a:hover {
          color: #32b7e9;
          text-decoration: none; }
        .dd .dd-content .page-controls a + a {
          margin-left: 5px; }
      .dd .dd-content .page-controls i {
        font-size: 14px; }
    .dd .dd-content .page-status {
      text-align: center;
      color: #fff;
      padding: 4px;
      display: inline-block;
      min-width: 80px;
      line-height: 1;
      border-radius: 2px;
      margin-left: 6px;
      overflow: hidden;
      position: relative;
      top: 6px;
      transition: all 230ms; }
      .dd .dd-content .page-status::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: inherit; }
      .dd .dd-content .page-status .status-text {
        position: relative;
        left: 0;
        transition: inherit; }
      .dd .dd-content .page-status:hover {
        cursor: pointer; }
        .dd .dd-content .page-status:hover::before {
          width: 25%; }
        .dd .dd-content .page-status:hover.page-published .status-text {
          left: -25%; }
        .dd .dd-content .page-status:hover.page-unpublished .status-text {
          left: 25%; }
      .dd .dd-content .page-status.page-published {
        background-color: #50b848; }
        .dd .dd-content .page-status.page-published::before {
          background-color: #c7c8ca;
          left: auto;
          right: 0; }
      .dd .dd-content .page-status.page-unpublished {
        background-color: #c7c8ca; }
        .dd .dd-content .page-status.page-unpublished::before {
          background-color: #50b848; }
  .dd .dd-placeholder {
    border: 1px dashed #56d1ff;
    background-color: #ddf5ff;
    height: 40px !important;
    text-align: center;
    color: #32b7e9;
    font-weight: 600;
    margin-bottom: 1px; }
    .dd .dd-placeholder + .dd-fake {
      border: 1px dashed #56d1ff;
      background-color: #ddf5ff;
      border-top: 0; }
    .dd .dd-placeholder > .lvl-2 {
      display: none; }

.dd-handle {
  cursor: move; }
  .order_disabled .dd-handle {
    opacity: 0.25;
    cursor: default; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 1;
  transition: opacity 5s ease-in; }
  .dd-list .dd-list {
    padding-left: 37px; }
    .dd-list .dd-list .dd-content {
      box-shadow: inset 0 6px 9px -6px rgba(0, 0, 0, 0.1);
      background-color: #fdfeff; }
    .dd-list .dd-list .dd-child .page-controls {
      background: #fdfeff;
      box-shadow: inset 0 6px 9px -6px rgba(0, 0, 0, 0.1); }
    .dd-list .dd-list .dd-placeholder:before {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      background: url(../images/dd-child-indicator.png) no-repeat;
      position: absolute;
      top: 5px;
      left: -28px; }
  .dd-list.dd-fade-out {
    opacity: 0; }

.dd-dragel {
  /* Dragged items display inside .dd-dragel */
  position: absolute;
  pointer-events: none;
  z-index: 9999; }
  .dd-dragel > .dd-item > .dd-handle {
    border: 1px solid #32b7e9; }
  .dd-dragel > .dd-item > .dd-content {
    border: 1px solid #32b7e9;
    box-shadow: 0 5px 4.75px 0.25px rgba(0, 105, 145, 0.1);
    margin: 0 0 1px; }
  .dd-dragel > .dd-item > .dd-list {
    display: none; }
  .dd-dragel .dd-item {
    display: block;
    position: relative;
    margin-top: -1px;
    padding: 0;
    min-height: 40px;
    line-height: 38px; }
  .dd-dragel .dd-handle {
    display: block;
    width: 38px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #d1d3d4;
    cursor: move;
    text-align: center;
    background: #ffffff;
    /* fallback */
    background: -webkit-linear-gradient(top, #ffffff 0%, #e6e7e8 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e6e7e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e7e8', GradientType=0); }
    .dd-dragel .dd-handle i {
      position: relative;
      top: -1px;
      font-size: 18px; }
  .dd-dragel .dd-content {
    display: block;
    height: 40px;
    padding: 0 0 0 37px;
    border: 1px solid #d1d3d4;
    background-color: #fff;
    box-shadow: 1px 3px 14px -3px rgba(0, 0, 0, 0.4); }
    .dd-dragel .dd-content .page-edit-zone {
      display: inline-block;
      line-height: 1;
      width: 100%;
      color: inherit;
      text-decoration: none;
      padding-left: 14px;
      position: relative; }
      .dd-dragel .dd-content .page-edit-zone:hover {
        background-color: #f7f7f7;
        /*&::after {
        @extend %iconic-icon-before;
        content: '\e827';
        position: absolute;
        top: -10px;
        right: -12px;
        background-color: #ffcf5b;
        padding: 2px;
        border-radius: 4px;
        width: auto;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.12);
      }*/ }
        .dd-dragel .dd-content .page-edit-zone:hover + .page-controls {
          background-color: #f7f7f7; }
      .dd-dragel .dd-content .page-edit-zone + .page-controls {
        background: #fff; }
    .dd-dragel .dd-content .page-name,
    .dd-dragel .dd-content .page-master {
      display: inline-block;
      overflow: hidden;
      line-height: 36px;
      position: relative;
      top: 1px; }
    .dd-dragel .dd-content .page-name {
      text-transform: uppercase;
      font-weight: 600;
      max-width: 280px;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .dd-dragel .dd-content .page-master {
      color: #c2c2c2;
      margin-left: 14px; }
      .dd-dragel .dd-content .page-master:hover, .dd-dragel .dd-content .page-master:focus {
        color: #32b7e9; }
      .dd-dragel .dd-content .page-master > i {
        margin-right: 4px; }
    .dd-dragel .dd-content .page-controls {
      display: inline-block;
      line-height: 38px;
      position: absolute;
      top: 1px;
      right: 1px;
      padding-left: 10px;
      padding-right: 10px; }
      .dd-dragel .dd-content .page-controls a {
        color: #000; }
        .dd-dragel .dd-content .page-controls a:hover {
          color: #32b7e9;
          text-decoration: none; }
        .dd-dragel .dd-content .page-controls a + a {
          margin-left: 5px; }
      .dd-dragel .dd-content .page-controls i {
        font-size: 14px; }
    .dd-dragel .dd-content .page-status {
      text-align: center;
      color: #fff;
      padding: 4px;
      display: inline-block;
      min-width: 80px;
      line-height: 1;
      border-radius: 2px;
      margin-left: 6px;
      overflow: hidden;
      position: relative;
      top: 6px;
      transition: all 230ms; }
      .dd-dragel .dd-content .page-status::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: inherit; }
      .dd-dragel .dd-content .page-status .status-text {
        position: relative;
        left: 0;
        transition: inherit; }
      .dd-dragel .dd-content .page-status:hover {
        cursor: pointer; }
        .dd-dragel .dd-content .page-status:hover::before {
          width: 25%; }
        .dd-dragel .dd-content .page-status:hover.page-published .status-text {
          left: -25%; }
        .dd-dragel .dd-content .page-status:hover.page-unpublished .status-text {
          left: 25%; }
      .dd-dragel .dd-content .page-status.page-published {
        background-color: #50b848; }
        .dd-dragel .dd-content .page-status.page-published::before {
          background-color: #c7c8ca;
          left: auto;
          right: 0; }
      .dd-dragel .dd-content .page-status.page-unpublished {
        background-color: #c7c8ca; }
        .dd-dragel .dd-content .page-status.page-unpublished::before {
          background-color: #50b848; }

.dd-fake {
  min-height: 5px !important;
  line-height: 5px !important; }
  .dd-fake:nth-last-child(2) {
    border: 1px dashed #56d1ff;
    background-color: #ddf5ff; }
  .dd-fake + .dd-placeholder {
    border-top: 0;
    min-height: 35px;
    height: 35px !important;
    line-height: 32px; }
    .dd-fake + .dd-placeholder:after {
      display: none; }

.dd > .dd-list > .dd-placeholder:nth-last-child(2) {
  border-bottom: 0;
  min-height: 35px;
  height: 35px !important; }

/*
 * Taken from UI 1.0 - File: loader.css
 */
#backgroundBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  visibility: hidden;
  display: none;
  background: transparent url(../images/halftransparent.gif) repeat scroll 0 0; }

#loadingBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3500;
  visibility: hidden;
  display: none;
  background: transparent url(../images/ajax-loader_2.gif) no-repeat center center;
  width: 150px;
  height: 120px;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  margin: 0 auto; }

.nav-steps-container {
  background-color: #f4f3f3;
  padding-top: 20px;
  padding-bottom: 13px;
  margin-bottom: 13px;
  border-bottom: 1px solid #c7c8ca; }
  .nav-steps-container .nav-steps {
    list-style: none;
    counter-reset: steps-counter;
    padding: 0;
    margin: 0;
    position: relative;
    top: 27px; }
  .nav-steps-container .step {
    display: inline-block;
    width: calc(20% - 3px);
    text-align: center;
    position: relative;
    counter-increment: steps-counter; }
    .nav-steps-container .step.active:before {
      background-color: #000; }
    .nav-steps-container .step.active .step-box {
      background-color: #fafafb; }
    .nav-steps-container .step.active ~ .step .step-box {
      color: #acb0b5; }
    .nav-steps-container .step.active ~ .step:before {
      background-color: #d8d8da; }
    .nav-steps-container .step.active ~ .step:after {
      border-top-color: #d8d8da; }
    .nav-steps-container .step:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #04b8ed;
      border-radius: 50%;
      position: absolute;
      top: -27px;
      left: 50%;
      margin-left: -6px;
      z-index: 2; }
    .nav-steps-container .step + .step:after {
      content: '';
      display: inline-block;
      border-top: 4px solid #04b8ed;
      position: absolute;
      top: -23px;
      right: 50%;
      left: -50%;
      z-index: 1; }
  .nav-steps-container .step-box {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1px 14px;
    background-color: #f4f3f3;
    border: 1px solid #c7c8ca;
    border-radius: 2em; }
    .nav-steps-container .step-box:before {
      content: counter(steps-counter) ".";
      margin-right: 4px; }

/* Responsive is extremely fragile */
/*@media (max-width: 1100px) {
  .nav-steps-container .step-box {
    &:before {
      content: counter(steps-counter);
      margin-right: 0;
    }
    .step-title {
      display: none;
    }
  }
}*/
.dropzone {
  display: table;
  height: 185px;
  width: 400px;
  padding: 8px;
  background: url(../images/dropzone_border.png);
  border: 0;
  text-align: center; }
  .dropzone.dz-browser-not-supported .dz-message {
    display: none; }
  .dropzone.dz-browser-not-supported .dz-fallback {
    height: 169px;
    background: #fafafb; }
    .dropzone.dz-browser-not-supported .dz-fallback p {
      padding: 5px 50px 0; }
    .dropzone.dz-browser-not-supported .dz-fallback input[type="file"] {
      text-align: center;
      margin: 0 auto 14px; }
  .dropzone.dz-drag-hover {
    background: url(../images/dropzone_border_hover.png); }
    .dropzone.dz-drag-hover .dz-message {
      background: #ddf5ff url(../images/ico_export_50x46.png) no-repeat 50% 32px;
      opacity: 1; }
  .dropzone .dz-message {
    background: #fafafb url(../images/ico_export_50x46.png) no-repeat 50% 32px;
    color: #252525;
    font-size: 15px;
    line-height: 20px;
    display: table-cell;
    padding-top: 88px; }
  .dropzone .dz-preview {
    margin: 0;
    width: 100%;
    height: 169px;
    background: #ddf5ff url(../images/ico_export_50x46.png) no-repeat 50% 32px; }
    .dropzone .dz-preview.dz-file-preview .dz-image {
      background: none; }
    .dropzone .dz-preview.dz-image-preview {
      background: none; }
    .dropzone .dz-preview.dz-error .dz-error-message {
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      bottom: auto;
      width: 100%;
      padding: 14px;
      border-radius: 3px;
      color: #c4161c;
      background: #fcd3c1;
      border: 1px solid #c4161c;
      opacity: 1; }
      .dropzone .dz-preview.dz-error .dz-error-message:after {
        display: none; }
    .dropzone .dz-preview.dz-error .dz-error-mark {
      opacity: 0;
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview .dz-image img {
      display: none; }
    .dropzone .dz-preview .dz-size {
      display: none; }
    .dropzone .dz-preview .dz-details {
      color: #252525;
      font-size: 15px;
      line-height: 20px;
      top: 90px;
      padding: 0; }
      .dropzone .dz-preview .dz-details .dz-filename span {
        border: 0 !important;
        background: none !important; }
    .dropzone .dz-preview .dz-progress {
      top: 75%; }
      .dropzone .dz-preview .dz-progress .dz-upload {
        background: #44ad3d;
        /* fallback */
        background: -moz-linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
        background: -webkit-linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
        background: linear-gradient(45deg, #5cc353 0%, #44ad3d 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$bottom-left-color', endColorstr='$top-right-color', GradientType=1); }
    .dropzone .dz-preview.dz-complete .dz-remove {
      display: block; }
    .dropzone .dz-preview .dz-remove {
      display: none;
      color: #e23c47;
      font-size: 13px;
      text-decoration: none !important;
      margin-top: 12px; }
      .dropzone .dz-preview .dz-remove:before {
        content: '\e80b';
        margin-right: 5px;
        font-size: 14px; }
      .dropzone .dz-preview .dz-remove:hover {
        opacity: 0.6; }
    .dropzone .dz-preview .dz-success-mark path {
      fill: #44ad3d;
      stroke: #44ad3d; }
    .dropzone .dz-preview .dz-error-mark path {
      fill: #c4161c;
      stroke: #c4161c; }
  .dropzone:hover, .dropzone:focus, .dropzone.dz-started {
    background: url(../images/dropzone_border_hover.png); }
    .dropzone:hover .dz-message, .dropzone:focus .dz-message, .dropzone.dz-started .dz-message {
      background: #ddf5ff url(../images/ico_export_50x46.png) no-repeat 50% 32px; }

/* IMPORT */
.import-mapping-container [class*="col-"]:first-child {
  margin-bottom: 18px; }

.import-mapping-container .mapping-header {
  background-color: #000;
  border: 1px solid #000;
  padding: 13px 19px; }

.import-mapping-container .mapping-body {
  background-color: #fff;
  border: 1px solid #d5d7d8;
  padding: 19px; }
  .import-mapping-container .mapping-body.mapping-columns {
    padding-top: 12px; }
    .import-mapping-container .mapping-body.mapping-columns .dd-item {
      margin-top: 0 !important; }

.import-mapping-container .mapping-columns-label {
  font-weight: 600;
  margin-bottom: 6px; }

.import-mapping-container .mapping-header-title {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-transform: uppercase; }

.import-mapping-container .mapping-key-label,
.import-mapping-container .mapping-toggle-container {
  height: 40px;
  display: table;
  width: 100%; }
  .import-mapping-container .mapping-key-label .mapping-key-label-text,
  .import-mapping-container .mapping-toggle-container .mapping-key-label-text {
    color: #727176; }
  .import-mapping-container .mapping-key-label .checkbox.toggle,
  .import-mapping-container .mapping-toggle-container .checkbox.toggle {
    line-height: 0; }

.import-mapping-container .mapping-form > .row + .row {
  margin-top: 10px; }

.import-mapping-container .mapping-form .droppable {
  height: 40px;
  background-color: #fbfbfb;
  border: 1px solid #d1d3d4;
  box-shadow: 1px 1px 7px -1px rgba(0, 0, 0, 0.2) inset; }
  .import-mapping-container .mapping-form .droppable .hover-text {
    display: none; }
  .import-mapping-container .mapping-form .droppable.droppable-hover {
    border: 1px dashed #56d1ff;
    background-color: #ddf5ff;
    color: #32b7e9;
    box-shadow: none; }
    .import-mapping-container .mapping-form .droppable.droppable-hover .hover-text {
      display: block;
      font-weight: 600;
      text-align: center;
      line-height: 36px; }

.import-mapping-container .mapping-dropdown-container {
  margin-bottom: 20px; }
  .import-mapping-container .mapping-dropdown-container select.form-control {
    width: 100%;
    height: 40px; }
  .import-mapping-container .mapping-dropdown-container label {
    min-height: 26px; }

.import-mapping-container .dd {
  width: auto; }
  .import-mapping-container .dd .dd-item + .dd-item {
    margin-top: 10px; }
  .import-mapping-container .dd .dd-content .page-name {
    text-transform: none;
    margin-left: 14px; }

.import-mapping-container .dd .dd-item.ui-draggable {
  width: auto !important;
  cursor: move; }

.import-mapping-container .wrapper-type-one {
  background-color: #fff; }

.import-messages {
  text-align: center;
  margin: 62px 0; }
  .import-messages h3 {
    margin-top: 15px;
    margin-bottom: 5px; }
  .import-messages .btn, .import-messages .btn-group.btn-group__primary > .dropdown-toggle {
    margin-top: 5px; }

.progress-bar-wrapper {
  background-color: #fff;
  border: 1px solid #c7c8ca;
  border-radius: 1.3em;
  padding: 4px;
  text-align: center; }
  .progress-bar-wrapper .progress-state-wrapper {
    background-color: #f4f3f3;
    border-radius: 1.3em;
    height: 23px;
    overflow: hidden;
    position: relative; }
  .progress-bar-wrapper .progress-state-current {
    background-color: #04b9ee;
    height: 100%; }
  .progress-bar-wrapper .progress-amount {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    position: relative;
    top: -21px; }
  .import-messages .progress-bar-wrapper {
    max-width: 518px;
    margin: 0 auto 35px; }

.popover {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }
  .popover.tour {
    min-width: 317px;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.57); }
    .popover.tour .popover-title,
    .popover.tour .popover-content,
    .popover.tour .popover-navigation {
      padding-left: 17px;
      padding-right: 17px; }
    .popover.tour .popover-title {
      background-color: transparent;
      border: 0;
      margin: 0;
      padding-top: 23px;
      padding-bottom: 10px; }
    .popover.tour .popover-content {
      font-size: 14px;
      line-height: 1.35714286;
      padding-top: 9px;
      padding-bottom: 11px; }
    .popover.tour .popover-navigation {
      padding-top: 10px;
      padding-bottom: 25px; }
      .popover.tour .popover-navigation .btn + .btn, .popover.tour .popover-navigation .btn-group.btn-group__primary > .dropdown-toggle + .btn, .popover.tour .popover-navigation .btn-group.btn-group__primary > .btn + .dropdown-toggle, .popover.tour .popover-navigation .btn-group.btn-group__primary > .dropdown-toggle + .dropdown-toggle {
        margin-left: 10px; }
      .popover.tour .popover-navigation *[data-role="end"].btn-link {
        padding-left: 0;
        padding-right: 0; }
    .popover.tour.tour-newFeature {
      text-align: center; }
      .popover.tour.tour-newFeature .popover-tag {
        display: block;
        font-weight: 800;
        font-size: 11px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
        background-color: #00A572; }
      .popover.tour.tour-newFeature .popover-title {
        padding-bottom: 4px; }
      .popover.tour.tour-newFeature .btn-link {
        float: none;
        color: #00A572; }
      .popover.tour.tour-newFeature .popover-navigation {
        padding-top: 0px;
        padding-bottom: 14px; }

.tour-backdrop {
  background-color: #000000;
  opacity: 0.5; }

.cms-guided__wrapper {
  width: 563px; }

.cms-guided__brand-colors > .o-layout-row {
  margin-left: -15px;
  margin-right: -15px; }
  .cms-guided__brand-colors > .o-layout-row > .o-layout-item {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%; }

.cms-guided__brand-colors .o-layout-wrapper__grid .o-layout-row {
  margin-top: -10px;
  margin-left: -5px;
  margin-right: -5px; }

.cms-guided__brand-colors .o-layout-wrapper__grid .o-layout-item {
  width: 33.3333333333%;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px; }
  .cms-guided__brand-colors .o-layout-wrapper__grid .o-layout-item .color-block {
    width: 100%;
    transition: all 90ms ease-in; }
    .cms-guided__brand-colors .o-layout-wrapper__grid .o-layout-item .color-block:hover {
      cursor: pointer;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5); }

.cms-guided__brand-colors__selections .color-block {
  width: 100%;
  margin-bottom: 11px; }

.form-group__colorpicker .input-group-addon {
  width: 32px; }
  .form-group__colorpicker .input-group-addon:hover {
    cursor: pointer; }

.color-block {
  width: 83px;
  height: 60px; }

.guided-font__choice {
  color: #000000;
  font-style: normal;
  line-height: normal;
  font-size: 21px; }
  .guided-font__choice--bold {
    font-weight: bold; }
  .guided-font__choice--normal {
    font-weight: normal; }

.templateContener {
  display: inline-block;
  margin: 0 20px 20px 0;
  position: relative; }
  .templateContener .viewTemplate a {
    position: absolute;
    top: 24px;
    right: 0;
    width: 43px;
    height: 43px;
    text-indent: -9999px;
    background: url(../images/zoom-icon.png) no-repeat top left; }
  .templateContener .thumbnailContener {
    border: 1px solid #d7d7d7;
    margin-bottom: 10px;
    display: block; }

.templateNavigation {
  margin-bottom: 20px; }
  .templateNavigation .title {
    display: block;
    margin-bottom: 10px; }
  .templateNavigation .prev,
  .templateNavigation .next {
    display: none; }

.contentTemplate iframe {
  margin: -8px; }

.template-container {
  border-bottom: 1px solid #d8d8d9;
  padding-bottom: 20px;
  margin-bottom: 28px; }
  .template-container .template-actions {
    text-align: center;
    width: 440px; }
    @media (min-width: 992px) {
      .template-container .template-actions {
        width: 400px; } }
    @media (min-width: 1200px) {
      .template-container .template-actions {
        width: 440px; } }
    .template-container .template-actions img {
      max-width: 100%;
      margin-bottom: 13px;
      box-shadow: 0 6px 13px -6px rgba(0, 0, 0, 0.7); }
    .template-container .template-actions p {
      font-size: 14px;
      margin-bottom: 0; }
      .template-container .template-actions p.modules {
        color: #1fb9eb; }
    .template-container .template-actions .btn, .template-container .template-actions .btn-group.btn-group__primary > .dropdown-toggle {
      margin-top: 10px;
      text-transform: uppercase; }
      .template-container .template-actions .btn + .btn, .template-container .template-actions .btn-group.btn-group__primary > .dropdown-toggle + .btn, .template-container .template-actions .btn-group.btn-group__primary > .btn + .dropdown-toggle, .template-container .template-actions .btn-group.btn-group__primary > .dropdown-toggle + .dropdown-toggle {
        margin-left: 10px; }
  .template-container .template-details {
    margin-top: 25px; }
    @media (min-width: 992px) {
      .template-container .template-details {
        margin-top: 0;
        width: calc(100% - 400px); } }
    @media (min-width: 1200px) {
      .template-container .template-details {
        width: calc(100% - 440px); } }
    .template-container .template-details h3 {
      margin-bottom: 18px; }

.modal-cms__crm-creation img {
  width: 180px;
  height: auto;
  margin-bottom: 23px; }

.modal-loader__base-styles .modal-dialog {
  width: 727px; }
  .modal-loader__base-styles .modal-dialog .modal-content {
    text-align: center;
    width: 727px;
    height: 404px; }
    .modal-loader__base-styles .modal-dialog .modal-content .modal-body.modal_message {
      width: 100%;
      height: 100%;
      padding: 40px 78px; }
      .modal-loader__base-styles .modal-dialog .modal-content .modal-body.modal_message .computer {
        width: 240px;
        height: auto;
        margin-bottom: 23px; }
      .modal-loader__base-styles .modal-dialog .modal-content .modal-body.modal_message .event {
        width: 220px;
        height: auto;
        margin-bottom: 23px; }
      .modal-loader__base-styles .modal-dialog .modal-content .modal-body.modal_message .modal-loader__image {
        width: 210px;
        height: auto;
        margin: 15px 0 40px; }
    .modal-loader__base-styles .modal-dialog .modal-content .progress-bar-striped {
      float: left;
      width: 0;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #0bd893;
      border-radius: 20px;
      box-shadow: none;
      -o-transition: width .6s ease;
      transition: width .6s ease; }
    .modal-loader__base-styles .modal-dialog .modal-content .ui-widget-content {
      border: none;
      width: 100%;
      box-shadow: none;
      background: #dde2e4;
      border-radius: 20px;
      color: inherit;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: inherit;
      padding: 0;
      height: 32px; }
    .modal-loader__base-styles .modal-dialog .modal-content .ui-progressbar .ui-progressbar-value {
      margin: 0; }
    .modal-loader__base-styles .modal-dialog .modal-content h3 {
      margin-bottom: 10px; }
    .modal-loader__base-styles .modal-dialog .modal-content h4 {
      margin-bottom: 30px;
      font-weight: 400; }
    .modal-loader__base-styles .modal-dialog .modal-content .progress-bar.active {
      animation-direction: reverse; }
    .modal-loader__base-styles .modal-dialog .modal-content .progress-bar-striped {
      background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.modal-loader__base-styles.modal-cms__formbuilder-creation .modal-dialog .modal-content .modal-body.modal_message .modal-loader__image {
  /* @TODO#MEMBOGO-6498 Form Builder loader image last minute fix. Ajdust properly for the future. */
  width: 170px; }

div#modal-nps .modal-dialog {
  margin: 115px auto 0 auto;
  width: 650px; }
  @media only screen and (min-width: 1000px) {
    div#modal-nps .modal-dialog {
      width: 843px; } }
  div#modal-nps .modal-dialog .modal-content {
    width: 650px; }
    @media only screen and (min-width: 1000px) {
      div#modal-nps .modal-dialog .modal-content {
        width: 843px; } }
    div#modal-nps .modal-dialog .modal-content .modal-body.modal_message {
      width: 100%;
      height: 100%;
      padding: 42px 55px 85px 55px; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote img {
        width: 206px;
        height: 37px;
        margin: 0 auto 25px auto;
        display: block; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote h2 {
        border-bottom: none;
        font-weight: bold;
        line-height: 30px;
        font-size: 25px;
        text-align: center;
        color: #3F403F;
        margin: 0 auto;
        width: 341px;
        padding-bottom: 25px; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline {
        margin-bottom: 15px;
        text-align: center; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .form-group.input-required {
          margin-left: 0;
          margin-right: 0; }
        @media only screen and (min-width: 1000px) {
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline {
            margin-bottom: 25px; } }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline span.fg-label {
          line-height: 26px;
          font-size: 19px;
          text-align: center;
          color: #696969;
          font-weight: normal;
          margin-bottom: 27px; }
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline span.fg-label:after {
            display: none; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio {
          margin-left: 0;
          padding: 0;
          margin-right: -1px; }
          @media only screen and (min-width: 1000px) {
            div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio {
              margin-right: 6px; } }
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio:last-child {
            margin-right: 0; }
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio label {
            font-weight: 500;
            font-size: 25px;
            color: white !important;
            background: #FAA81A;
            border-radius: 40px;
            padding-top: 11px;
            padding-left: 0;
            height: 42px;
            width: 42px; }
            @media only screen and (min-width: 1000px) {
              div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio label {
                padding-top: 16px;
                padding-left: 1px;
                height: 53px;
                width: 53px; } }
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio input[type="radio"] + label:before {
            display: none; }
          div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio input[type="radio"]:checked + label {
            background: #A3A3A3; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .checkbox input[type="checkbox"],
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .checkbox input[type="radio"],
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio input[type="checkbox"],
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-inline .radio input[type="radio"] {
          margin-left: 0 !important; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .ml25 {
        margin-left: unset !important; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.ml25 {
        margin-left: -15px !important;
        text-align: center; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.ml25 .control-label {
          font-weight: normal;
          line-height: 26px;
          font-size: 19px;
          text-align: center;
          color: #696969;
          margin-bottom: 22px; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.ml25 textarea {
          background: #FFFFFF;
          border: 1px solid #BFBFBF;
          border-radius: 0;
          box-shadow: none;
          min-height: 127px;
          width: 100%;
          margin: 0 auto 22px auto;
          max-width: 539px !important; }
          @media only screen and (min-width: 1000px) {
            div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.ml25 textarea {
              max-width: 732px !important; } }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.ml25 textarea#comment {
          font-size: 17px; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .form-group.input-required {
        width: 100%; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .section.comments-line {
        margin-top: 10px;
        margin-bottom: 22px;
        display: flex;
        justify-content: space-between; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote .section.comments-line p {
          font-style: italic;
          font-weight: normal;
          line-height: 22px;
          font-size: 16px;
          text-align: right;
          color: #696969; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote input#send {
        padding: 16px 30px;
        font-weight: 900;
        line-height: normal;
        font-size: 15px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none;
        background: linear-gradient(180deg, #F6B73F 0%, #FAA81A 100%);
        text-shadow: none;
        letter-spacing: 0.5px; }
        div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote input#send:hover {
          text-shadow: none;
          box-shadow: none; }
      div#modal-nps .modal-dialog .modal-content .modal-body.modal_message form#nps_form_vote button#cancel_nps {
        font-weight: normal;
        padding: 16px 0;
        line-height: 22px;
        font-size: 16px;
        color: #9C9C9C; }

/* Horizontal */
.thermo-horiz-container {
  position: relative;
  width: 361px;
  height: 79px;
  border-radius: 39.5px;
  text-align: center;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 24px; }

.thermo-bg {
  width: 100%;
  height: 100%;
  background: #dcdcdc; }

.thermo-color {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffce02;
  background: -moz-linear-gradient(left, #ffce02 0%, #fcaf15 100%);
  background: -webkit-linear-gradient(left, #ffce02 0%, #fcaf15 100%);
  background: linear-gradient(to right, #ffce02 0%, #fcaf15 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffce02', endColorstr='#fcaf15', GradientType=1); }

/* @deprecated
.thermo-marker {
  display: inline-block;
  position: relative;
  top: -48px;
  left: 161px;
  width: 14px;
  height: 14px;
  background-color: #72cb35;
  border-radius: 50%;
}

.thermo-marker:before {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  background-color: #72cb25;
  opacity: 0.25;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -7px;
}

.thermo-marker:after {
  content: '';
  display: block;
  width: 28px;
  height: 1px;
  background-color: #72cb25;
  position: absolute;
  top: 6px;
  left: 6px;
}
*/
.thermo-component {
  font-family: inherit;
  padding: 8px 0; }

.thermo-part {
  display: inline-block;
  vertical-align: middle; }

.thermo-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000; }

.thermo-currency {
  font-size: 34px;
  font-style: italic; }

.thermo-goal {
  text-align: right; }

.thermo-objective {
  position: relative; }

.thermo-objective:after {
  content: '';
  display: none;
  width: 157px;
  height: 95px;
  background: url(../images/thermo-stars.png) no-repeat 50% 50%;
  position: absolute;
  top: -22px;
  left: -24px; }

/* @deprecated
.thermo-complete .thermo-marker {
  display: none;
}
*/
.thermo-complete .thermo-objective:after {
  display: block; }

/* Circular */
.thermometer-circular-container {
  display: inline-block;
  padding-bottom: 26px;
  text-align: center;
  position: relative; }

.thermometer-circular-container::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 223px;
  margin-left: -1.5px;
  width: 3px;
  height: 70px;
  background: #fdbb0e;
  /*box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.4);*/ }

.thermometer-circular {
  display: inline-block;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  overflow: hidden;
  position: relative; }

.thermometer-circular::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fdbb0e;
  border-radius: 50%; }

.thermometer-circular .circle .mask,
.thermometer-circular .circle .fill {
  width: 270px;
  height: 270px;
  position: absolute;
  border-radius: 50%; }

.thermometer-circular .mask,
.thermometer-circular .fill {
  transition: transform 1s;
  border-radius: 50%; }

.thermometer-circular .mask {
  clip: rect(0px, 270px, 270px, 135px); }

.thermometer-circular .mask .fill {
  clip: rect(0px, 135px, 270px, 0px);
  background-color: #dcdcdc;
  /*box-shadow: inset 0 0 16px -5px rgba(0, 0, 0, 0.4);*/ }

.thermometer-circular .inset {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -110px;
  margin-top: -110px;
  background-color: #fff;
  border-radius: 50%; }

.thermometer-circular .amount {
  overflow: hidden;
  position: absolute;
  top: 77px;
  left: 0;
  right: 0;
  line-height: 1;
  text-align: center;
  color: #fdbb0e; }

.thermometer-circular-container .text,
.thermometer-circular-container .objective .numbers {
  display: inline-block;
  font-size: 22px;
  font-weight: 700; }

.thermometer-circular-container .numbers {
  font-size: 40px;
  font-weight: 700; }

/* @deprecated
.thermometer-circular .marker:before {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  background-color: #72cb25;
  opacity: 0.25;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -7px;
}

.thermometer-circular .marker:after {
  content: '';
  display: block;
  width: 2px;
  height: 82px;
  background-color: #72cb25;
  position: absolute;
  top: 6px;
  left: 6px;
}
*/
.thermometer-circular-container .objective {
  text-align: center;
  margin-top: 30px;
  position: relative; }

.thermometer-circular-container .objective:after {
  content: '';
  display: none;
  width: 169px;
  height: 94px;
  background: url(../images/thermo-circ-stars.png) no-repeat 50% 50%;
  position: absolute;
  top: -22px;
  left: 23px; }

.thermo-circ-complete .objective:after {
  display: block; }

.thermometer-circular-container.thermo-circ-complete .objective .numbers {
  display: block; }

/***************/
/*  Header V2  */
/*  April 2018 */
/***************/
.header__icon {
  font-size: 23px;
  color: #000; }
  .header__icon-apps {
    font-size: 20px; }
  .header__icon-help {
    font-size: 18px; }
  .header__icon-wizardOpen {
    font-size: 24px; }
  .header__icon-toolbox {
    font-size: 20px; }

.app-selector {
  display: inline-block; }
  .app-selector .btn-group {
    background: none; }
    .app-selector .btn-group .dropdown-toggle {
      padding: 0; }
      .app-selector .btn-group .dropdown-toggle > i,
      .app-selector .btn-group .dropdown-toggle > span {
        display: inline-block;
        vertical-align: middle; }
      .app-selector .btn-group .dropdown-toggle .header__icon-apps {
        height: 45px;
        line-height: 45px; }
      .app-selector .btn-group .dropdown-toggle .app-selector__text {
        color: #000;
        font-size: 14px;
        margin-left: 2px;
        height: 45px;
        line-height: 45px; }
      .app-selector .btn-group .dropdown-toggle .app-selector__caret {
        color: #000;
        margin: 0;
        border-top-width: 4px;
        border-left-width: 4px;
        border-right-width: 4px; }
      .app-selector .btn-group .dropdown-toggle .module-icon {
        margin-left: 8px;
        margin-right: 2px;
        position: relative; }
        .app-selector .btn-group .dropdown-toggle .module-icon::after {
          content: '';
          border-left: 1px solid #c7c8ca;
          position: absolute;
          left: -6px;
          top: 0;
          bottom: 0; }
    .app-selector .btn-group .dropdown-menu {
      z-index: 9999;
      border: 1px solid #c7c8ca;
      box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.2);
      top: 45px;
      left: -5px; }
      .app-selector .btn-group .dropdown-menu::before {
        left: 9px;
        top: -9px; }
      .app-selector .btn-group .dropdown-menu .row {
        width: 540px;
        margin: 0; }
        .app-selector .btn-group .dropdown-menu .row .col-xs-3 {
          padding: 0; }
        .app-selector .btn-group .dropdown-menu .row a {
          padding: 21px 6px 17px;
          text-align: center;
          display: block;
          font-family: "Montserrat", Arial, Helvetica, sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #000000;
          border-right: 1px solid #c7c8ca;
          border-bottom: 1px solid #c7c8ca;
          line-height: 1em; }
          .app-selector .btn-group .dropdown-menu .row a:hover {
            text-decoration: none;
            background-color: #f4f3f3; }
          .app-selector .btn-group .dropdown-menu .row a .module-icon {
            margin: 0 auto 10px; }
          .app-selector .btn-group .dropdown-menu .row a .app-dropdown__app-title {
            display: table;
            width: 100%;
            height: 30px; }
            .app-selector .btn-group .dropdown-menu .row a .app-dropdown__app-title > span {
              display: table-cell;
              vertical-align: middle; }
        .app-selector .btn-group .dropdown-menu .row > div:nth-child(4) a {
          border-right: none; }
        .app-selector .btn-group .dropdown-menu .row:last-child a {
          border-bottom: none; }

.header__app-info {
  height: 45px;
  background-color: #fcfcfc;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  padding-left: 9px;
  padding-right: 12px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 0;
  margin-left: 1px;
  /*box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.15);*/
  /*box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);*/
  transition: all 66ms ease-in-out;
  /* @TODO V3 */
  /*&:hover {
    cursor: pointer;
    background-color: #fff;
  }*/ }
  .header__app-info__name {
    font-size: 15px;
    margin: 0 0 0 8px; }

.nav__bottom__tabs {
  z-index: 1;
  /* @TODO V3 */
  /*white-space: nowrap;
  overflow: hidden;
  max-width: 280px;
  @include for(lg) {
    max-width: 480px;
  }
  @include for(ultrabook) {
    max-width: 625px;
  }*/ }

.header__link-onlineHelp {
  display: inline-block;
  color: #000; }
  .header__link-onlineHelp:hover, .header__link-onlineHelp:focus {
    color: #000;
    text-decoration: none; }
  .header__link-onlineHelp > i,
  .header__link-onlineHelp > span {
    display: inline-block;
    vertical-align: middle; }
  .header__link-onlineHelp__text {
    font-size: 13px;
    height: 45px;
    line-height: 45px; }

.header__btn-wizard {
  height: 45px;
  background-color: #FBAE17;
  color: #000;
  padding: 0 10px;
  margin-right: -17px;
  display: inline-flex;
  flex-direction: row;
  align-items: center; }
  .header__btn-wizard:hover, .header__btn-wizard:focus {
    color: #000;
    text-decoration: none; }
  .header__btn-wizard .header__icon-wizardOpen {
    position: relative;
    top: -1px; }
  .header__btn-wizard__text {
    font-size: 13px;
    position: relative;
    top: -1px; }

.app__header {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 972px;
  width: 100%;
  z-index: 1012;
  box-shadow: 0 -3px 10px -1px #000; }
  .app__header .nav__top {
    padding-left: 23px;
    border-bottom: 1px solid #E5E5E5;
    background: #fff; }
    .app__header .nav__top > ul {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .app__header .nav__top > ul > li.nav__top__spacer {
        flex-grow: 1; }
    .app__header .nav__top .header-logo {
      display: block; }
      .app__header .nav__top .header-logo img {
        display: block;
        height: 27px; }
    .app__header .nav__top .btn-group {
      line-height: 60px;
      border-left: 1px solid #E5E5E5; }
      .app__header .nav__top .btn-group .dropdown-menu {
        min-width: 100%;
        z-index: 16000004;
        max-height: 500px;
        overflow: auto;
        box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.2); }
  .app__header .nav__bottom {
    padding-left: 17px;
    padding-right: 17px;
    background: #F4F3F3; }
    .app__header .nav__bottom > ul {
      height: 45px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .app__header .nav__bottom > ul > li {
        line-height: 1em; }
        .app__header .nav__bottom > ul > li.nav__bottom__applicationsSelect {
          line-height: inherit; }
        .app__header .nav__bottom > ul > li.nav__bottom__spacer {
          flex-grow: 1; }
        .app__header .nav__bottom > ul > li + li {
          margin-left: 17px; }
    .app__header .nav__bottom .header__icon-toolbox {
      height: 45px;
      line-height: 45px; }
    .app__header .nav__bottom a:hover, .app__header .nav__bottom a:focus {
      text-decoration: none; }
    .app__header .nav__bottom a[data-toggle="tooltip"] {
      display: inline-block;
      margin: 2px 0; }
    .app__header .nav__bottom .tooltip.top {
      margin-top: -1px; }

.btn-group__account span,
.btn-group__lang span {
  color: #727176;
  font-weight: 600;
  margin: 0 0 0 8px; }

.btn-group__account .dropdown-toggle,
.btn-group__lang .dropdown-toggle {
  color: inherit; }

.btn-group__account .dropdown-toggle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px; }
  @media (min-width: 1200px) {
    .btn-group__account .dropdown-toggle {
      max-width: 600px; } }
  @media (min-width: 1340px) {
    .btn-group__account .dropdown-toggle {
      max-width: 767px; } }

@media (max-width: 1000px) {
  .btn-group__account .dropdown-menu {
    max-width: 400px; }
    .btn-group__account .dropdown-menu > li > a {
      white-space: pre-wrap; } }

.btn-group__account.open {
  z-index: 2; }

.btn-group__userProfile .dropdown-toggle > .icon-user {
  color: #3b3b3b; }
  .btn-group__userProfile .dropdown-toggle > .icon-user::before {
    background-color: #f1f2f2;
    border: 1px solid #cacbcd;
    border-radius: 50%;
    margin-right: 13px;
    width: 28px;
    height: 28px;
    line-height: 26px;
    font-size: 16px;
    text-align: center; }

.btn-group__userProfile .dropdown-toggle .username {
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  color: #3b3b3b;
  line-height: 1; }

.btn-group__userProfile .dropdown-menu > li > a {
  color: #000; }
  .btn-group__userProfile .dropdown-menu > li > a:hover {
    color: #000; }

/***************/
/* @DEPRECATED */
/*  Header V1  */
/***************/
header.app-header {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 972px;
  width: 100%;
  z-index: 1012;
  box-shadow: 0 -3px 10px -1px #7d7d7d; }

header .top-nav {
  line-height: 35px;
  background-color: #f4f3f3;
  border-bottom: 1px solid #c7c8ca; }
  header .top-nav ul.menu-items {
    padding: 0;
    margin: 0;
    list-style: none; }
    header .top-nav ul.menu-items > li {
      display: inline-block;
      float: left; }
      header .top-nav ul.menu-items > li:nth-last-child(1), header .top-nav ul.menu-items > li:nth-last-child(2) {
        background-color: #fff;
        border-left: 1px solid #cccdcd; }
      header .top-nav ul.menu-items > li span.account {
        color: #727176;
        font-weight: 600;
        margin: 0 0 0 8px; }
      header .top-nav ul.menu-items > li .wizard-btn,
      header .top-nav ul.menu-items > li .configuration-general,
      header .top-nav ul.menu-items > li .membogo-premium,
      header .top-nav ul.menu-items > li .helpcenter-link {
        font-weight: 600;
        color: #000000;
        padding: 0 15px;
        display: block; }
        header .top-nav ul.menu-items > li .wizard-btn:hover,
        header .top-nav ul.menu-items > li .configuration-general:hover,
        header .top-nav ul.menu-items > li .membogo-premium:hover,
        header .top-nav ul.menu-items > li .helpcenter-link:hover {
          text-decoration: none; }
      header .top-nav ul.menu-items > li .membogo-premium,
      header .top-nav ul.menu-items > li .helpcenter-link {
        padding: 0;
        margin: 0 11px; }
      header .top-nav ul.menu-items > li .configuration-general {
        padding: 0;
        margin: 0 11px; }
        header .top-nav ul.menu-items > li .configuration-general:before {
          font-size: 16px;
          position: relative;
          top: 1px;
          margin-right: 6px; }
      header .top-nav ul.menu-items > li #wizard-btn {
        padding: 0;
        margin: 0 11px; }
        header .top-nav ul.menu-items > li #wizard-btn:before {
          font-size: 15px;
          position: relative;
          top: 1px;
          margin-right: 8px; }
      header .top-nav ul.menu-items > li .helpcenter-link:before {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-right: 7px; }
    header .top-nav ul.menu-items .btn-group {
      vertical-align: top; }
      header .top-nav ul.menu-items .btn-group.lang-toggle .active {
        display: none; }
      header .top-nav ul.menu-items .btn-group .dropdown-toggle {
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 175px; }
        @media (min-width: 1200px) {
          header .top-nav ul.menu-items .btn-group .dropdown-toggle {
            max-width: 233px; } }
    header .top-nav ul.menu-items .icon-custom-premium-star:before {
      position: relative;
      top: 6px;
      margin-right: 6px; }
  header .top-nav .btn-group .dropdown-toggle span {
    font-weight: 600; }
  header .top-nav .btn-group .dropdown-menu {
    min-width: 100%;
    z-index: 16000004;
    max-height: 500px;
    overflow: auto;
    box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.2); }

header .brand-wrapper {
  background: #ffffff;
  height: 50px;
  padding-right: 0 !important;
  position: relative;
  box-shadow: 0 3px 4.75px 0.25px rgba(0, 0, 0, 0.05);
  line-height: 1; }
  header .brand-wrapper .left-part {
    display: table;
    height: 100%; }
    header .brand-wrapper .left-part > div {
      display: table-cell;
      vertical-align: middle; }
      header .brand-wrapper .left-part > div.brand {
        padding-right: 25px; }
        header .brand-wrapper .left-part > div.brand .brand-logo {
          width: 156.4px;
          height: 25.5px; }
      header .brand-wrapper .left-part > div.appLogo {
        padding-right: 8px; }
      header .brand-wrapper .left-part > div.applicationsSelect {
        padding-right: 16px; }
  header .brand-wrapper .right-part {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    display: table; }
    header .brand-wrapper .right-part .btn-toolbox {
      font-size: 13px;
      font-weight: 600;
      color: #494949;
      text-decoration: none;
      padding: 6px 5px 5px;
      display: table-cell;
      vertical-align: middle; }
      header .brand-wrapper .right-part .btn-toolbox::before {
        font-size: 17px;
        color: #3b3b3b;
        vertical-align: middle;
        margin-right: 9px; }
  header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle {
    padding: 6px 0;
    min-width: 0; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle span [class*='icon-'] {
      color: #000000;
      font-size: 15px;
      font-size: 20px;
      margin-right: 0;
      padding: 8px 12px;
      padding: 5px 10px;
      border: 1px solid transparent;
      border-radius: 2px;
      transition: all 0.06s ease-in-out; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle span.applications {
      display: inline-block;
      vertical-align: middle;
      margin-top: 1px; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .caret {
      vertical-align: middle;
      color: #ffb000;
      border-width: 5px;
      margin-top: 1px; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .appLogo {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-right: 2px; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .appLogo .module-icon {
        margin-right: 0; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .appLogo:before {
        content: '';
        position: absolute;
        left: -9px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #c7c8ca; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .appName {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      top: 1px; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle .appName span {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #000000;
        margin: 0;
        transition: all 0.06s ease-in-out; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle[aria-expanded="false"] {
      /* Hover effect only when the dropdown is closed. */ }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle[aria-expanded="false"] span [class*='icon-']:hover {
        border: 1px solid #c7c8ca;
        box-shadow: 0 0 5.7px 0.3px rgba(0, 0, 0, 0.2); }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-toggle[aria-expanded="false"] .appInfoWrapper:hover .appName span {
        color: #525252; }
  header .brand-wrapper .applicationsSelect .btn-group.open .dropdown-toggle span [class*='icon-'] {
    background: #f1f2f2;
    border: 1px solid #c7c8ca;
    border-radius: 2px; }
  header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu {
    z-index: 9999;
    border: 1px solid #c7c8ca;
    box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.2);
    top: 32px;
    left: 2px; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu:before {
      left: 9px;
      top: -9px; }
    header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row {
      width: 540px;
      margin: 0; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row a {
        padding: 22px 6px;
        text-align: center;
        display: block;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #000000;
        border-right: 1px solid #c7c8ca;
        border-bottom: 1px solid #c7c8ca;
        line-height: 1em; }
        header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row a:hover {
          text-decoration: none;
          background-color: #f4f3f3; }
        header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row a .module-icon {
          margin: 0 auto 22px; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row > div:nth-child(4) a {
        border-right: none; }
      header .brand-wrapper .applicationsSelect .btn-group .dropdown-menu .row:last-child a {
        border-bottom: none; }
  header .brand-wrapper .profileBox {
    display: table-cell;
    vertical-align: middle;
    padding-left: 6px; }
    header .brand-wrapper .profileBox .btn-group .dropdown-toggle span {
      font-weight: 400;
      font-size: 16px;
      font-style: italic;
      color: #3b3b3b;
      vertical-align: top; }
    header .brand-wrapper .profileBox .btn-group .dropdown-toggle [class*='icon-']:first-child {
      color: #3b3b3b;
      vertical-align: middle; }
      header .brand-wrapper .profileBox .btn-group .dropdown-toggle [class*='icon-']:first-child:before {
        background-color: #f1f2f2;
        border: 1px solid #cacbcd;
        border-radius: 50%;
        margin-right: 13px;
        width: 28px;
        height: 28px;
        line-height: 26px;
        font-size: 16px;
        text-align: center; }
    header .brand-wrapper .profileBox .btn-group .dropdown-toggle:hover span {
      color: #757679; }
    header .brand-wrapper .profileBox .btn-group .dropdown-toggle .toggle {
      vertical-align: middle; }
    header .brand-wrapper .profileBox .btn-group .dropdown-menu {
      right: 40px;
      left: auto; }
      header .brand-wrapper .profileBox .btn-group .dropdown-menu > li > a {
        color: #000000; }

.uiDemo,
.uiDescription {
  border: 1px solid #dddddd;
  padding: 45px 15px 15px;
  position: relative; }

.uiDescription {
  border-radius: 4px 4px 0 0; }
  .uiDescription:after {
    color: #959595;
    content: "Description";
    font-size: 14px;
    font-weight: 700;
    left: 15px;
    position: absolute;
    text-transform: uppercase;
    top: 15px; }
  .uiDescription + .uiDemo {
    border-top: 0; }

.uiDesign:after {
  content: "Design"; }

.uiDemo + pre {
  border-radius: 0 0 4px 4px;
  border-top: 0;
  background-color: #ffffff;
  padding: 45px 15px 15px;
  position: relative; }
  .uiDemo + pre:after {
    color: #959595;
    content: "HTML";
    font-size: 14px;
    font-weight: 700;
    left: 15px;
    position: absolute;
    text-transform: uppercase;
    top: 15px; }

.uiDemo:after {
  color: #959595;
  content: "D\00e9mo";
  font-size: 14px;
  font-weight: 700;
  left: 15px;
  position: absolute;
  text-transform: uppercase;
  top: 15px; }

.uiDemo.uiPHP + pre:after {
  content: 'PHP'; }

.uiDemo h2 {
  margin-top: 40px; }

/* Variables */
.ui-login-form__wrapper {
  text-align: center;
  margin-top: 114px; }
  @media screen and (min-height: 769px) {
    .ui-login-form__wrapper {
      margin-top: 224px; } }
  .ui-login-form__wrapper > .wrapper {
    display: inline-block;
    vertical-align: top; }
    .ui-login-form__wrapper > .wrapper + .wrapper {
      margin-left: 24px; }

.wrapper-login__ad-box {
  border-radius: 3px;
  overflow: hidden;
  max-width: 500px; }
  .wrapper-login__ad-box img {
    max-width: 100%; }
  @media (max-width: 991px) {
    .wrapper-login__ad-box {
      display: none !important; } }
  @media (min-width: 992px) {
    .wrapper-login__ad-box {
      max-width: 500px; } }
  @media (min-width: 1200px) {
    .wrapper-login__ad-box {
      max-width: none; } }

.wrapper-signup,
.wrapper-login {
  border-radius: 3px;
  position: relative; }

/* @TODO Remove once new login.membogo.com is launched officially. */
  #page-login .wrapper-signup::before, #page-login
  .wrapper-login::before {
    content: '';
    display: inline-block;
    width: 87px;
    height: 87px;
    background: url(../images/signup_rocket@2x.png) no-repeat center center;
    background-size: cover;
    position: absolute;
    top: -44px;
    left: 50%;
    margin-left: -44px; }
  .wrapper-signup .title,
  .wrapper-login .title {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 35px;
    font-weight: 400;
    text-transform: none;
    line-height: 1.2;
    margin: 0 0 12px 0;
    text-align: center; }
  .wrapper-signup .sub-title,
  .wrapper-login .sub-title {
    font-size: 14px;
    margin-bottom: 30px; }
  .wrapper-signup form,
  .wrapper-login form {
    margin-bottom: 15px; }
    .wrapper-signup form .form-control,
    .wrapper-login form .form-control {
      font-size: 14px; }
    .wrapper-signup form:last-child,
    .wrapper-login form:last-child {
      margin-bottom: 0; }
  .wrapper-signup p,
  .wrapper-login p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center; }
  .wrapper-signup hr,
  .wrapper-login hr {
    border-color: #d8d9da;
    margin: 23px 15px 10px; }

.wrapper-signup {
  width: 573px;
  padding: 40px 70px 45px;
  background: #ffffff url(../images/signup_banner_bg.png) no-repeat top right;
  margin: 40px auto; }
  .wrapper-signup:before {
    display: none; }
  .wrapper-signup .title {
    font-family: "Gotham A", "Gotham B", "Roboto", Arial, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    padding: 0 30px;
    margin: 0 0 22px 0; }
  .wrapper-signup .corner-banner {
    position: absolute;
    right: -28px;
    top: 39px;
    transform: rotate(45deg); }
    .wrapper-signup .corner-banner p {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-weight: 400;
      color: #ffffff; }
      .wrapper-signup .corner-banner p.top-text {
        font-size: 23px;
        text-transform: uppercase;
        min-width: 180px; }
      .wrapper-signup .corner-banner p.bottom-text {
        font-size: 15px; }
      .wrapper-signup .corner-banner p.smacl-banner-text {
        font-family: "Open Sans", Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: -0.01em;
        position: relative;
        top: 9px;
        min-width: 107px; }
  .wrapper-signup label.control-label {
    margin-bottom: 5px;
    font-size: 13px; }
  .wrapper-signup form .inline-form-group,
  .wrapper-signup form > .form-group {
    margin-bottom: 15px; }
  .wrapper-signup form .form-control {
    width: 100%; }
    .wrapper-signup form .form-control.first-name {
      float: left;
      width: 48%; }
    .wrapper-signup form .form-control.last-name {
      float: right;
      width: 48%; }
  .wrapper-signup form .checkbox {
    margin-top: 0;
    margin-bottom: 0; }
    .wrapper-signup form .checkbox label {
      font-weight: 600;
      color: #6b6b6b; }
  .wrapper-signup form .checkbox-multi-wrapper {
    text-align: center;
    padding-top: 2px;
    margin-bottom: 12px; }
  .wrapper-signup form .needs-options-wrapper > [class*='col-'] {
    padding-left: 2px;
    padding-right: 2px; }
  .wrapper-signup form .label-needs,
  .wrapper-signup form .smacl-number-title .fg-label {
    color: #3f3e3e;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 2px; }
  .wrapper-signup form .creditagricole-number-title .fg-label {
    font-size: 16px !important;
    padding-left: 51px;
    padding-right: 45px;
    padding-top: 7px; }
  .wrapper-signup form .label-checkall {
    color: #505050;
    font-size: 13px;
    line-height: 20px;
    font-style: italic; }
  .wrapper-signup form .submit-wrapper {
    padding-top: 3px;
    padding-left: 20px;
    padding-right: 20px; }
  .wrapper-signup .company-logo {
    display: block;
    width: 117px;
    height: 20px;
    background: url(../images/logo_small_grey.png) no-repeat center center;
    margin: 18px auto 0; }

.smacl-number-title {
  padding-top: 5px; }
  .smacl-number-title .fg-label {
    padding-bottom: 4px;
    font-size: 14px !important; }

.smacl-number-wrapper {
  padding-top: 8px;
  padding-bottom: 30px;
  position: relative; }
  .smacl-number-wrapper img {
    position: absolute;
    top: -10px;
    left: 8px;
    width: 93px; }
  .smacl-number-wrapper > [class*='col-'] {
    width: auto; }
    .smacl-number-wrapper > [class*='col-']:last-child {
      width: 100%;
      padding-left: 120px;
      padding-top: 18px; }

.box-creditAgricole__signup {
  background: #f7f7f5;
  display: flex;
  padding: 7px 11px 7px 14px;
  border: 1px solid #009597;
  margin-bottom: 16px;
  margin-top: 23px; }
  .box-creditAgricole__signup .box-creditAgricole__logo {
    display: flex;
    align-items: center;
    padding-right: 14px;
    margin-right: 13px;
    border-right: 1px solid #c1e1e0; }
    .box-creditAgricole__signup .box-creditAgricole__logo img {
      width: 89px; }
  .box-creditAgricole__signup .box-creditAgricole__text {
    color: #009597;
    padding: 12px 0;
    font-family: "Gotham A", "Gotham B", "Roboto", Arial, Helvetica, sans-serif; }
    .box-creditAgricole__signup .box-creditAgricole__text h3 {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 6px; }
    .box-creditAgricole__signup .box-creditAgricole__text p {
      font-size: 13px;
      text-align: unset;
      font-weight: normal; }

.creditagricole-number-wrapper {
  padding-top: 4px;
  padding-bottom: 18px; }
  .creditagricole-number-wrapper img {
    top: -44px;
    left: 19px; }
  .creditagricole-number-wrapper > [class*='col-']:last-child {
    padding-left: 117px;
    padding-top: 0;
    top: -16px; }

.signup__become-member {
  margin-top: 24px;
  padding-bottom: 14px;
  font-size: 13px !important;
  line-height: 18px !important;
  margin-bottom: 0;
  text-align: center;
  font-style: italic;
  font-weight: normal !important; }

.wrapper-login {
  width: 410px;
  padding: 56px 31px 54px;
  background: #ffffff; }
  .wrapper-login .input-group {
    margin-bottom: 15px; }
  .wrapper-login .row {
    margin-bottom: 15px;
    font-size: 14px; }
    .wrapper-login .row .checkbox {
      margin: 0; }
    .wrapper-login .row a {
      line-height: 20px; }

.sign-up-banner {
  background: url(/templates/default/images/sign_up_rocket.png) no-repeat center;
  background-size: cover !important;
  position: relative;
  left: 18px;
  width: 700px;
  margin: 50px auto 50px;
  left: 41px;
  width: 872px;
  height: 749px; }
  @media screen and (max-height: 768px) {
    .sign-up-banner {
      background: url(/templates/default/images/sign_up_rocket.png) no-repeat center bottom;
      height: 437px; } }
  @media (min-width: 1200px) {
    .sign-up-banner {
      left: 41px;
      width: 990px; } }
  .sign-up-banner::before {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: -1;
    box-shadow: 0 0px 12px 0px rgba(0, 0, 0, 0.16);
    left: 0;
    top: 0;
    right: 56px;
    bottom: 251px; }
    @media (min-width: 992px) {
      .sign-up-banner::before {
        right: 70px;
        bottom: 103px; } }
    @media (min-width: 1200px) {
      .sign-up-banner::before {
        right: 80px;
        bottom: 2px; } }
  .sign-up-banner .progress-bar {
    background-color: #0bd893;
    box-shadow: none; }
    .sign-up-banner .progress-bar.active {
      animation-direction: reverse; }
  .sign-up-banner .progress-bar-striped {
    background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }
  .sign-up-banner .ui-widget-content {
    height: 32px;
    border: none;
    box-shadow: none;
    border-radius: 30px;
    width: 433px;
    margin-top: 40px; }
    @media screen and (max-height: 768px) {
      .sign-up-banner .ui-widget-content {
        border: 1px solid #deeaec; } }
    @media (min-width: 992px) {
      .sign-up-banner .ui-widget-content {
        margin-top: 44px;
        margin-left: 101.5px;
        width: 603px; } }
    @media (min-width: 1200px) {
      .sign-up-banner .ui-widget-content {
        width: 708px; } }
  .sign-up-banner .ui-progressbar .ui-progressbar-value {
    margin: 0;
    border-radius: 30px; }
  .sign-up-banner h3 {
    padding-top: 81px;
    font-size: 35px;
    line-height: 42px; }
  .sign-up-banner .subtitle {
    font-weight: normal;
    font-size: 35px;
    line-height: 42px;
    color: black; }
  .sign-up-banner .description {
    color: black;
    font-size: 15px;
    margin-top: 33px; }
    @media (min-width: 992px) {
      .sign-up-banner .description {
        margin-top: 66px; } }
  .sign-up-banner .text {
    text-align: center;
    margin-right: 56px; }
    @media (min-width: 992px) {
      .sign-up-banner .text {
        margin-right: 70px; } }
    @media (min-width: 1200px) {
      .sign-up-banner .text {
        margin-right: 80px; } }
  .sign-up-banner .top-text {
    width: 500px;
    margin: 0 auto; }

/* Variables */
.dashboard-container {
  min-height: calc(100vh - 106px - 50px); }
  .has-banner .dashboard-container {
    min-height: calc(100vh - 106px - 41px - 50px); }
  .dashboard-container .dashboard {
    padding-top: 18px;
    padding-bottom: 50px; }
    @media (max-width: 991px) {
      .dashboard-container .dashboard > .container {
        width: auto; } }

@media (min-height: 620px) {
  .dashboard-simple__wrapper.widget {
    margin-top: 33px; } }

@media (min-height: 765px) {
  .dashboard-simple__wrapper.widget {
    margin-top: 80px; } }

@media (min-height: 830px) {
  .dashboard-simple__wrapper.widget {
    margin-top: 111px; } }

.dashboard-simple__wrapper .row {
  margin-right: -4px;
  margin-left: -4px; }
  .dashboard-simple__wrapper .row > div {
    padding-right: 4px;
    padding-left: 4px; }
  .dashboard-simple__wrapper .row + .row {
    margin-top: 8px; }

.dashboard-simple__title {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 23px; }

.widget {
  background: #F4F3F3;
  margin-top: 20px;
  padding: 12px 8px 8px;
  width: 360px; }
  .widget:first-child {
    margin-top: 0; }
  .dashboard .widget {
    width: auto; }

.widget__header {
  position: relative; }
  .widget__header .widget__header-title {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    margin-top: 0;
    padding-bottom: 8px;
    margin-bottom: 11px; }
  .widget__header .widget__header-actions {
    position: absolute;
    top: -2px;
    right: -6px; }
    .widget__header .widget__header-actions .btn-group {
      background: none;
      line-height: 1; }
      .widget__header .widget__header-actions .btn-group .dropdown-toggle {
        padding: 0;
        border: 0;
        background: none; }
    .widget__header .widget__header-actions i {
      font-size: 17px;
      color: #0F0F0F; }
      .widget__header .widget__header-actions i::before {
        margin: 0; }

.widget__item {
  display: table;
  width: 100%;
  line-height: 1;
  background: #FFFFFF;
  border: 1px solid #CBCCCE;
  padding: 7px 10px;
  margin-top: 0.4em; }
  .widget__item:first-child {
    margin-top: 0; }
  .widget__item .widget__item-left,
  .widget__item .widget__item-right {
    display: table-cell;
    vertical-align: top; }
  .widget__item .widget__item-left {
    padding-top: 2px; }
  .widget__item .widget__item-right {
    width: 10000px;
    padding-left: 6px; }
    .widget__item .widget__item-right .widget__item-right__footer {
      margin-top: 8px;
      margin-bottom: 1px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between; }
  .widget__item .widget__item__title > a {
    color: inherit; }
    .widget__item .widget__item__title > a:hover, .widget__item .widget__item__title > a:focus {
      color: #00addc; }
  .widget__item--notice {
    background-color: #FBE5B9;
    border-color: #FBAE17; }
  .widget__item--confSuccess {
    text-align: center;
    background-image: url(../images/rocket-launch.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 210px;
    /* Height of the SVG background. */ }
    .widget__item--confSuccess .widget-promo__title {
      display: inline-block;
      margin-top: 14px; }
    .widget__item--confSuccess .widget-promo__text {
      max-width: 220px;
      margin: 8px auto 12px; }
    .widget__item--confSuccess .widget-shortcuts__action-later {
      visibility: visible;
      display: inline-block;
      margin-bottom: 16px; }

.widget-apps {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -0.4em;
  margin-left: -0.4em; }
  .widget-apps .widget-apps__item {
    display: inline-block;
    text-align: center;
    margin-top: 0.4em;
    margin-left: 0.4em;
    padding: 2.15em 0.5em;
    width: 13.65em;
    transition: all 90ms ease-in;
    transform: translate3d(0, 0, 0);
    /* @TODO#DEBUGING Sur chargement, border-right disparu. Previously `translateZ(0)`. */ }
    @media (min-width: 1200px) {
      .widget-apps .widget-apps__item {
        padding-top: 1em;
        padding-bottom: 0.8em;
        width: 32.15%; } }
    .widget-apps .widget-apps__item:hover, .widget-apps .widget-apps__item:focus {
      text-decoration: none;
      transform: translate3d(0, -2px, 0); }
  .widget-apps .widget-apps__item-img {
    display: inline-block;
    vertical-align: top; }
  .widget-apps .widget-apps__item-title {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 0.2em;
    vertical-align: top;
    display: table;
    width: 100%;
    height: 30px; }
    .widget-apps .widget-apps__item-title > span {
      display: table-cell;
      vertical-align: middle; }

.widget-promo {
  padding: 22px 18px 27px; }
  .widget-promo .widget-promo__title {
    display: block;
    margin: 0 0 17px; }
    @media (max-width: 1199px) {
      .widget-promo .widget-promo__title {
        font-size: 19px; } }
  .widget-promo .widget-promo__btn {
    margin-top: 15px; }
  .widget-promo.widget-promo__premium {
    background: #3D3D3D;
    color: #fff; }

.widget-promo__text {
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  font-style: normal; }

.widget-stats .widget-stats__item {
  height: 57px;
  padding-left: 15px;
  padding-right: 15px; }

.widget-stats .widget-stats__item-left,
.widget-stats .widget-stats__item-right {
  display: table-cell;
  vertical-align: middle; }

.widget-stats .widget-stats__item-right {
  text-align: right;
  padding-left: 15px; }

.widget-stats .widget-stats__value {
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: #FBAE17;
  white-space: nowrap; }

.widget-stats .mgo-loader {
  color: #FBAE17; }

.widget-newfeatures .widget-newfeatures__date {
  line-height: 13px;
  font-size: 10px;
  color: #000000; }

.widget-shortcuts {
  max-height: 692px;
  overflow-y: auto;
  /*@include for(desktop-height) {
    max-height: 474px;
  }*/ }
  .widget-shortcuts__item.alert {
    border-color: #D68217;
    background-color: #fcf8e3;
    margin-bottom: 0; }
  .widget-shortcuts__action-start {
    font-size: 13px;
    font-weight: 700; }
    .widget-shortcuts__action-start::after {
      content: '\e87d';
      width: auto;
      vertical-align: baseline;
      margin-left: 3px;
      text-decoration: none; }
  .widget-shortcuts .widget-shortcuts__action-start {
    float: right;
    /* IE10 fallback */ }
  .widget-shortcuts__action-later {
    visibility: hidden;
    /* @TODO Seulement dans DASHBOARD V2.  */
    font-size: 12px;
    color: #808080;
    font-weight: normal; }
    .widget-shortcuts__action-later:hover, .widget-shortcuts__action-later:focus {
      color: #909090; }

/*.widget-shortcuts__action-later {
  visibility: hidden; !* @TODO Seulement dans DASHBOARD V2.  *!
  font-size: 12px;
  color: #808080;
  font-weight: normal;
  &:hover,
  &:focus {
    color: #909090;
  }
}*/
.widget-conf .widget__item-right {
  vertical-align: middle;
  padding-left: 10px; }

.widget-conf .widget-conf__action-wrapper {
  display: table-cell;
  vertical-align: middle; }
  .widget-conf .widget-conf__action-wrapper .widget-shortcuts__action-start {
    float: none;
    white-space: nowrap; }

.widget-conf__progress-text {
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 4px; }
  .widget-conf__progress-text__percentage {
    font-size: 12px; }

.widget-conf__progress-bar {
  margin-left: -4px;
  margin-right: -4px;
  margin-top: 6px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .widget-conf__progress-bar__tick {
    height: 6px;
    background-color: #EAEAEA;
    border-radius: 1em;
    margin-left: 4px;
    margin-right: 4px;
    flex-grow: 1; }
    .widget-conf__progress-bar__tick.isDone {
      background-color: #00A572; }

.widget-twitter {
  max-height: 350px;
  overflow-y: auto; }

.widget-simple {
  text-align: center;
  height: 227px;
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 1200px) {
    .widget-simple {
      padding-left: 40px;
      padding-right: 40px; } }
  .widget-simple__icon {
    margin: 20px auto; }
  .widget-simple__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; }
  .widget-simple__text {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 32px; }
  .widget-simple__action-start {
    display: inline-block;
    font-size: 25px;
    margin-top: 17px; }
    .widget-simple__action-start::after {
      margin-left: 6px; }
  .widget-simple__full {
    display: flex;
    align-items: center;
    height: 105px; }
    .widget-simple__full > * {
      padding: 0;
      margin: 0; }
    .widget-simple__full .widget-simple__icon {
      flex-grow: 1;
      text-align: right;
      position: relative; }
      .widget-simple__full .widget-simple__icon .widget-simple__icon-plus {
        display: inline-block;
        width: 32px;
        height: 32px;
        background: #2CA6DE;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: 9px;
        right: 5px; }
        .widget-simple__full .widget-simple__icon .widget-simple__icon-plus > i::before {
          color: #FFFFFF;
          font-size: 18px;
          position: relative;
          top: 6px;
          left: -2px; }
    .widget-simple__full .widget-simple__title {
      text-align: left;
      padding-left: 15px;
      padding-right: 50px; }
    .widget-simple__full .widget-simple__action-start {
      flex-grow: 1;
      text-align: left; }

/*
// pages/invoice.css
.page {
  .section {
    &:first-child {
      [class*='col-'] {
        p {
          line-height: 1;
          &:first-child {
            margin-bottom: 15px;
          }
        }
      }
    }
    &:last-child {
      .table > thead > tr > th {
        width: calc(100% / 6);
      }
    }
  }
}
*/
#invoice-detail {
  width: 845px; }
  #invoice-detail h2 {
    margin-bottom: 13px; }
  #invoice-detail p {
    line-height: 21px;
    margin-bottom: 0; }
    #invoice-detail p.h2 {
      font-size: inherit;
      text-transform: uppercase;
      margin-bottom: 9px; }
  #invoice-detail .invoice-brand-logo {
    display: inline-block;
    margin: 0 0 0 10px; }
  #invoice-detail hr {
    border-color: #e5e5e5;
    border-width: 3px;
    margin-top: 17px;
    margin-bottom: 17px; }
  #invoice-detail .wrapper-type-one {
    margin-bottom: 10px; }
  #invoice-detail .payed {
    font-size: 24px;
    text-transform: uppercase;
    color: red;
    margin-top: 24px; }
    #invoice-detail .payed .btn, #invoice-detail .payed .btn-group.btn-group__primary > .dropdown-toggle {
      margin-top: -6px; }
    #invoice-detail .payed .btn-preview {
      margin-left: 20px; }

.page-logged-out {
  min-width: 0; }
  .page-logged-out header {
    text-align: center; }
  .page-logged-out .brand-wrapper {
    display: table;
    width: 100%; }
    .page-logged-out .brand-wrapper .brand {
      display: table-cell;
      vertical-align: middle; }

@media (max-width: 767px) {
  #page-login {
    /* @TODO Remove once new login.membogo.com is launched officially. */ }
    #page-login .wrapper-login {
      width: 100%;
      padding: 56px 20px 54px; }
    #page-login .ui-login-form__wrapper {
      margin-top: 25%;
      margin-bottom: 25%; }
    #page-login footer .support .links > li {
      margin-right: 10px;
      line-height: 15px; }
    #page-login .checkbox input[type="checkbox"] + label:not(:empty) {
      padding: 0; }
    #page-login .page-logged-out footer .support, #page-login .page-logged-out footer .copyright {
      line-height: 25px; }
    #page-login #page-login .col-xs-6 {
      width: 100%;
      text-align: center !important; }
      #page-login #page-login .col-xs-6 .checkbox {
        line-height: 30px; } }

@media screen and (min-height: 769px) {
  #page-login footer {
    position: fixed;
    width: 100%;
    bottom: 0; } }

#page-signup .body {
  padding-bottom: 50px; }
  @media (max-width: 767px) {
    #page-signup .body {
      padding-bottom: 80px; } }
  #page-signup .body > .container-fluid > .row > div {
    min-height: 0; }

#page-signup footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.header-has-btn {
  position: relative;
  margin-top: 12px; }
  .header-has-btn .btn, .header-has-btn .btn-group.btn-group__primary > .dropdown-toggle {
    position: absolute;
    right: 0;
    top: -12px; }

label[for="activity_add"] {
  margin-top: 13px; }

.participant-wrapper {
  margin-bottom: 4px; }
  .participant-wrapper .btn.btn-icon, .participant-wrapper .btn-group.btn-group__primary > .btn-icon.dropdown-toggle {
    vertical-align: top;
    line-height: 18px; }

.participant-add-wrapper {
  margin: 8px 0; }

.maintenance-container {
  max-width: 640px;
  margin: 0 auto;
  padding: 35px 15px 0 15px; }
  .maintenance-container .maintenance-title {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #333333;
    text-transform: none;
    margin: 0 auto 25px auto;
    text-align: center;
    max-width: 400px; }
    .maintenance-container .maintenance-title.m-t-fr {
      margin-top: 50px; }
  .maintenance-container .maintenance-msg {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
    margin: 0;
    text-align: center;
    max-width: 580px;
    margin: 0 auto; }
    .maintenance-container .maintenance-msg span.reduce-margin {
      position: relative;
      bottom: 24px; }

#page-maintenance {
  background: #f4f4f4;
  display: flex;
  flex-direction: column; }
  #page-maintenance header .brand-wrapper {
    background: transparent;
    box-shadow: none; }
    #page-maintenance header .brand-wrapper .brand-logo {
      margin-top: 73px; }
  #page-maintenance .body {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1; }
  #page-maintenance .maintenance-background-div {
    margin: 0 auto;
    max-width: 100%;
    width: 579px; }
    #page-maintenance .maintenance-background-div img.maintenance-background-img {
      max-width: 100%;
      width: 579px; }
    @media only screen and (max-height: 947px) and (min-height: 860px) {
      #page-maintenance .maintenance-background-div {
        width: 486px; }
        #page-maintenance .maintenance-background-div img.maintenance-background-img {
          width: 486px; } }
    @media only screen and (max-height: 860px) and (min-height: 799px) {
      #page-maintenance .maintenance-background-div {
        width: 421px; }
        #page-maintenance .maintenance-background-div img.maintenance-background-img {
          width: 421px; } }
    @media only screen and (max-height: 799px) and (min-height: 701px) {
      #page-maintenance .maintenance-background-div {
        width: 359px; }
        #page-maintenance .maintenance-background-div img.maintenance-background-img {
          width: 359px; } }
    @media only screen and (max-height: 700px) and (min-height: 601px) {
      #page-maintenance .maintenance-background-div {
        width: 280px; }
        #page-maintenance .maintenance-background-div img.maintenance-background-img {
          width: 280px; } }
    @media only screen and (max-height: 600px) {
      #page-maintenance .maintenance-background-div {
        width: 200px; }
        #page-maintenance .maintenance-background-div img.maintenance-background-img {
          width: 200px; } }
  #page-maintenance .error-background-div {
    width: 749px;
    margin: 0 auto;
    max-width: 100%; }
    #page-maintenance .error-background-div img.error-background-img {
      width: 749px;
      max-width: 100%; }
    @media only screen and (max-height: 860px) and (min-height: 800px) {
      #page-maintenance .error-background-div {
        width: 649px; }
        #page-maintenance .error-background-div img.error-background-img {
          width: 649px; } }
    @media only screen and (max-height: 799px) and (min-height: 741px) {
      #page-maintenance .error-background-div {
        width: 549px; }
        #page-maintenance .error-background-div img.error-background-img {
          width: 549px; } }
    @media only screen and (max-height: 740px) {
      #page-maintenance .error-background-div {
        width: 346px; }
        #page-maintenance .error-background-div img.error-background-img {
          width: 346px; } }

.import-wizard-intro {
  margin-bottom: 40px; }

.import-wizard-codes {
  margin-bottom: 80px; }
  .import-wizard-codes select.form-control {
    width: 100%;
    max-width: 400px;
    margin-bottom: 18px; }
  .import-wizard-codes .alert-warning {
    max-width: 400px; }

.form-import-members-result h2 {
  margin-bottom: 0; }

.form-import-members-result .form-group {
  margin: 0;
  padding: 2px 0; }
  .form-import-members-result .form-group + .form-group {
    border-top: 1px solid #d5d7d8; }
  .form-import-members-result .form-group .control-label {
    padding-left: 0; }
  .form-import-members-result .form-group .form-control-static {
    padding-right: 0; }

.form-import-members-result .control-label {
  width: 30%; }

.form-import-members-result .form-control-static {
  width: 70%; }

.form-import-members-result label[for="error"],
.form-import-members-result p#error {
  color: #ff0000; }

#value-code-table {
  max-width: 400px; }

.nav-import-wizard.nav-steps-container {
  padding-top: 59px;
  margin: -75px -20px 44px; }

.page-footer .page-controls.import-wizard {
  float: none !important; }
  .page-footer .page-controls.import-wizard .import-wizard-next {
    float: right; }

/* Variables */
.c-plans .o-layout-row {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -20px;
  margin-left: -2px;
  margin-right: -2px; }
  .c-plans .o-layout-row .o-layout-item {
    margin-top: 20px;
    padding-left: 2px;
    padding-right: 2px; }

.c-plans__footer {
  color: #333;
  margin-top: 22px; }

.c-plans__condition {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0; }
  .c-plans__condition + .c-plans__condition {
    margin-top: 6px; }

.c-plans__onlinehelp {
  font-size: 14px;
  margin-top: 25px; }

.c-plan {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #d1d3d4;
  min-width: 250px;
  max-width: 260px;
  min-height: 560px;
  /* If content is changed regularly, not sure how maintainable this will be. */ }
  .c-plan.c-plan--isActive {
    background-color: #e0e0e0; }
  .c-plan > :last-child {
    margin-top: auto; }

.c-plan__header {
  background-color: #1fb9eb;
  color: #fff;
  border-bottom: 1px solid #d1d3d4;
  text-align: center;
  padding: 28px 0; }
  .c-plan--isActive .c-plan__header {
    background-color: rgba(31, 185, 235, 0.7); }

.c-plan__body {
  padding: 17px 17px; }

.c-plan__footer {
  padding: 14px 10px;
  text-align: center; }

.c-plan__title {
  margin: 0 0 20px; }

.c-plan__subtitle {
  display: block;
  margin: 0;
  font-size: 12px; }

.c-plan__price {
  font-size: 26px; }

.c-plan__price-discount {
  font-size: 19px;
  opacity: 0.8;
  margin-right: 10px;
  padding: 0 6px;
  vertical-align: top;
  position: relative; }
  .c-plan__price-discount::after {
    content: '';
    display: inline-block;
    border-top: 1px solid;
    border-color: inherit;
    width: 100%;
    position: absolute;
    top: 13px;
    left: 0;
    transform: rotate(-19deg); }

.c-plan__price-desc {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: -6px;
  left: 6px; }

.c-plan__features {
  font-size: 13px;
  line-height: 23px;
  color: #333;
  list-style: disc;
  padding: 0;
  margin: 0 0 7px 16px; }

.c-plan__feature + .c-plan__feature {
  margin-top: 10px; }

.c-plan__feature.c-plan__feature--disabled {
  color: #a2a2a2; }

.c-plan__readmore {
  font-size: 14px;
  color: #1fb9eb; }

.c-plan__btn-activate {
  width: 100%; }

.c-plan__status {
  color: #7b7b7b;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 43px; }

.c-contactus .o-layout-row {
  flex-wrap: nowrap;
  align-items: center;
  margin-left: -12px;
  margin-right: -12px; }
  .c-contactus .o-layout-row .o-layout-item {
    padding-left: 12px;
    padding-right: 12px; }

.c-contactus__icn::before {
  width: 63px;
  height: 63px; }

.c-contactus__title {
  font-weight: 700;
  border: 0;
  padding: 0;
  margin: 3px 0 14px; }

.c-contactus_text {
  color: #383838;
  font-size: 13px;
  line-height: 19px; }

.c-contactus__btn.btn, .btn-group.btn-group__primary > .c-contactus__btn.dropdown-toggle {
  /* Instead of using !1important */
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 6px;
  margin-left: 27px; }

.page-creditAgricole__ressources {
  text-align: center; }
  .page-creditAgricole__ressources h3.credit-agricole-head-title {
    margin-bottom: 35px; }
  .page-creditAgricole__ressources .credit-agricole-top-box {
    border: 1px solid #d8d8d9;
    padding: 20px 25px; }
    .page-creditAgricole__ressources .credit-agricole-top-box .credit-agricole-logo {
      margin-bottom: 24px; }
    .page-creditAgricole__ressources .credit-agricole-top-box h2 {
      margin-bottom: 23px; }
    .page-creditAgricole__ressources .credit-agricole-top-box .credit-agricole-icons {
      margin-bottom: 35px;
      display: flex;
      justify-content: center; }
      .page-creditAgricole__ressources .credit-agricole-top-box .credit-agricole-icons .credit-agricole-icons-item {
        display: flex;
        align-items: center;
        margin-right: 34px; }
        .page-creditAgricole__ressources .credit-agricole-top-box .credit-agricole-icons .credit-agricole-icons-item:last-child {
          margin-right: 0; }
        .page-creditAgricole__ressources .credit-agricole-top-box .credit-agricole-icons .credit-agricole-icons-item img {
          margin-right: 13px; }
    .page-creditAgricole__ressources .credit-agricole-top-box .btn.btn-primary, .page-creditAgricole__ressources .credit-agricole-top-box .btn-group.btn-group__primary > .dropdown-toggle {
      width: 236px;
      padding: 15px 0px;
      font-weight: bold; }
    .page-creditAgricole__ressources .credit-agricole-top-box .btn-credit-agricole {
      background: #86ce00;
      color: #FFFFFF;
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      font-size: 15px;
      margin-left: 5px; }
      .page-creditAgricole__ressources .credit-agricole-top-box .btn-credit-agricole:hover, .page-creditAgricole__ressources .credit-agricole-top-box .btn-credit-agricole:active {
        color: white;
        background: linear-gradient(180deg, #C4C4C4 0%, #86CE00 0.01%, #76B500 100%); }
  .page-creditAgricole__ressources h3.credit-agricole-body-title {
    margin-bottom: 20px;
    margin-top: 36px; }
  .page-creditAgricole__ressources .o-layout-row {
    justify-content: center; }
    .page-creditAgricole__ressources .o-layout-row .c-plan {
      min-height: 390px; }
      .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__header {
        padding: 0;
        height: 124px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__header h3.c-plan__title {
          margin: 0; }
      .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__icons {
        height: 51px;
        display: flex;
        align-items: center; }
      .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__body {
        padding: 17px 20px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__body .c-plan__icons {
          justify-content: center; }
          .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__body .c-plan__icons h5 {
            text-align: left;
            margin-left: 12px;
            line-height: normal; }
        .page-creditAgricole__ressources .o-layout-row .c-plan .c-plan__body p {
          line-height: normal; }

/* CSS to be placed in a more appropriate file goes here */
.guide-container .guide-slave {
  display: inline-block; }

.guide-container .guide-master {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 50px;
  line-height: 2.7rem; }
  .guide-container .guide-master p {
    margin: 0; }
  .guide-container .guide-master > div {
    display: inline-block; }
    .guide-container .guide-master > div.guide-keys {
      font-weight: 700;
      text-align: right;
      margin-right: 5px; }

/* <!--
 * Site web
 */
a.toggleLink.open {
  display: block; }

.toggle .toggle-icon {
  display: none; }

#original-css {
  margin-bottom: 18px; }

/*
 * -->
 */
.honey-pot {
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important; }

.zopim {
  transition: all 200ms ease-in-out;
  z-index: 992 !important;
  border-radius: 3px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) !important; }
  .zopim.zopim--bump {
    bottom: 53px !important; }
  .zopim.zopim--slide-left {
    right: 531px !important; }
  .zopim.wizard--active {
    margin-right: 531px !important;
    position: absolute !important; }

/* @DEPRECATED with UX4
.tooltip {
  z-index: $tooltip-zIndex;

  &.in {
    @include opacity(1);
  }

  > .tooltip-inner {
    border-radius: $input-border-radius;
    box-shadow: 0 0 0 1px $app-content-bg-color, 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  }

  &.top {
    margin-top: 4px;
    padding: 5px;
  }
}
*/
.onlinehelp-linkout {
  padding: 1px;
  font-size: 13px; }
  h2 > .onlinehelp-linkout {
    position: relative;
    top: -7px; }
  .checkbox > .onlinehelp-linkout,
  .radio > .onlinehelp-linkout {
    margin-left: 6px; }

.icon-custom-premium-star:before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 20px;
  background: url(../images/ico_star@2x.png) no-repeat center center;
  background-size: cover; }

/* Admin dashboard */
.user-disabled {
  color: #db3a38; }

.quick-login {
  color: #000;
  margin-left: 3px; }

/* Firefox bug -- MGO-4920 */
#memberAdhAddFirstStepForm .form-control {
  position: relative;
  z-index: 2; }

#memberAdhAddFirstStepForm .radio {
  z-index: 1; }

/* MGO-5061 -- Formulaire de création d'un champ personnalisé */
.form-add-custom-field .radio {
  margin-top: 15px; }

/* CKEditor's width forces a help block button underneath */
.cke {
  max-width: 100%; }

.cke-size-default,
textarea.cke-size-default {
  width: 700px;
  height: 335px; }

/* CKEditor's - Keyword selection visual bug when a label is super long. */
select.cke_dialog_ui_input_select {
  max-width: 500px; }

/* Guided Event - May 2018*/
#map.map:empty {
  width: auto !important;
  height: auto !important; }

/* FMSQ */
.xrm_element_form [class*="col-sm-6"] input,
.xrm_element_form [class*="col-sm-6"] select,
.xrm_element_form [class*="col-sm-6"] textarea,
.xrm_element_form [class*="col-sm-6"] .chosen-container {
  width: 100%; }

.xrm_element_form .fg-label, .xrm_element_form .form-group label {
  width: 100%;
  padding: 0; }

.xrm_element_form .form-control-static {
  padding: 0; }

.xrm_element_form .form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0; }

/* Multi-file */
.field_file__draggablecomplete {
  list-style: none;
  padding: 0;
  margin: 0; }
  .field_file__draggablecomplete > li {
    margin-bottom: 10px; }

.multifile__remove-file {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer; }
  .multifile__remove-file:hover {
    opacity: 0.7;
    filter: alpha(opacity=70); }

.field_file__draggable {
  display: table;
  width: 400px;
  padding: 8px;
  background: url(../images/dropzone_border.png);
  border: 0;
  text-align: center; }
  .field_file__draggable.fs-light .fs-upload-target {
    display: table-cell;
    vertical-align: middle;
    color: #252525;
    font-size: 15px;
    line-height: 20px;
    background: #fafafb;
    border: 0;
    padding: 18px 0;
    transition: none; }
    .field_file__draggable.fs-light .fs-upload-target .field_file__icon {
      margin: 0 0 8px;
      display: inline-block;
      font-size: 28px; }
  .field_file__draggable:hover, .field_file__draggable:focus, .field_file__draggable.fs-upload-dropping {
    background: url(../images/dropzone_border_hover.png); }
    .field_file__draggable:hover.fs-light .fs-upload-target, .field_file__draggable:focus.fs-light .fs-upload-target, .field_file__draggable.fs-upload-dropping.fs-light .fs-upload-target {
      background: #ddf5ff; }

.billing-table th:nth-child(2),
.billing-table th:nth-child(4),
.billing-table .billing-value,
.billing-table .billing-total-value,
.billing-table tfoot {
  text-align: right; }

/* MEMBOGO-6251 -- Hero Banner (module cms) */
.wrapper-bck-upload > .input-file {
  float: none;
  padding-left: 0;
  padding-right: 0; }

.wrapper-bck-upload .field_file__draggable {
  background: none;
  padding: 0; }

.wrapper-bck-upload .field_file__draggable.fs-light .fs-upload-target {
  border-color: #90e1ff;
  color: #000; }

.wrapper-bck-upload .field_file__draggable:hover > .fs-upload-target, .wrapper-bck-upload .field_file__draggable:focus > .fs-upload-target, .wrapper-bck-upload .field_file__draggable.fs-upload-dropping > .fs-upload-target {
  background: #ddf5ff !important;
  border-color: #90e1ff !important;
  color: #000 !important; }

/*
 * Targets Firefox browsers only
 * http://stackoverflow.com/questions/952861/targeting-only-firefox-with-css/953491#953491
 */
@-moz-document url-prefix() {
  /*
   * File upload input height bug
   * http://stackoverflow.com/questions/22049739/fix-for-firefox-file-input-using-bootstrap-3-1
   */
  /* Firefox renders box-shadow differently than Chrome */
  input[type="file"].form-control {
    height: auto;
    padding: 4px 8px; }
  #wizard {
    box-shadow: 10px 10px 13px 1px #272727; }
  header.app-header {
    box-shadow: 0 -7px 10px -1px #7d7d7d; } }

/*
 * Targets IE10+
 * http://stackoverflow.com/questions/952861/targeting-only-firefox-with-css/953491#953491
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #pagenameinfo .disp-ib:not(.has-error) + .disp-ib.form-inline {
    position: relative;
    top: 28px; }
  .wizard-btn-open .wizard-close .icon-lifebuoy.mgo-loader {
    transform-origin: 50% 43%; } }

#pagenameinfo .disp-ib:not(.has-error) + .disp-ib.form-inline {
  position: relative \9;
  top: 28px \9; }

/*
 * Targets Edge (Microsoft)
 * https://stackoverflow.com/questions/32940965/how-to-target-microsoft-edge-with-css
 */
@supports (-ms-ime-align: auto) {
  .wizard-btn-open .wizard-close .icon-lifebuoy.mgo-loader {
    transform-origin: 50% 47%; } }

/**
 * Member --> Configuration -> Memberships -> Membership types
 * To improve the UI for the 'Membership type' part of the form.
 * JIRA: MGO-4682
 */
#adhesion_group_type_corporate {
  margin-bottom: -5px; }
  #adhesion_group_type_corporate + .checkbox {
    margin-bottom: 5px; }

#adhesion_group_type_regular {
  margin-bottom: -5px; }
  #adhesion_group_type_regular .form-group {
    margin-bottom: 18px; }

.ml25 {
  margin-left: 25px !important; }

.ml50 {
  margin-left: 50px !important; }

.disp-ib {
  display: inline-block !important; }
  .disp-ib + .disp-ib {
    margin-left: 13px; }
    .section > .disp-ib + .disp-ib {
      vertical-align: top; }
  .disp-ib.has-error + .disp-ib:not(.form-inline) {
    vertical-align: top; }
  .disp-ib.has-error + .disp-ib.form-inline {
    position: relative;
    vertical-align: top;
    top: 20px; }

.hide-soft {
  display: none; }

.vcenter {
  display: table-cell;
  vertical-align: middle; }

.ul-reset {
  padding: 0;
  margin: 0;
  list-style: none; }

@media print {
  a[href]:after {
    content: ''; }
  .app-main-nav,
  .app-second-nav,
  header .brand-wrapper .left-part,
  header .top-nav ul.menu-items > li,
  .page-header .page-controls,
  .page-footer .page-controls,
  footer .support,
  .zopim {
    display: none !important; }
  .app-content {
    width: 100%; }
  header .top-nav ul.menu-items > li.account-selector {
    display: inline-block !important; } }

.applications-configuration table > tbody > tr > td {
  white-space: normal; }

.applications-configuration table.table-has-toggle > tbody > tr > td:last-child {
  width: auto;
  padding-left: 50px; }

/*
 * Site Web / CMS
 * Sélecteur de module de la zone.
 * Les boutons Appliquer et Réinitialiser se retrouve dessous le champ avec un margin-left.
 * Fix pour un affichage plus optimal.
 */
@media (max-width: 1435px) {
  .edit-intelligent-template .help-block.hint {
    display: block;
    margin-left: 0;
    margin-top: 12px; } }

/*
 * Site Web / CMS
 * Le module "Annuaire des membres" a introduit des options ajoutables.
 * (Select + icone pour suprimmer + bouton Ajouter)
 * Fix pour la règle suivante: .row [class*='col-'] .has-help-right select {width:auto;}
 */
.directory_bloc.has-help-right select {
  width: 400px !important; }

/*
 * MEMBOGO-302
 * Mode de paiement par chèque - Problème d'affichage.
 */
.comment-wrapper {
  margin-top: 18px; }

/*
 * Dans une communication, les checkboxes "Envoyer une copie à/aux XXX".
 */
#non-admin-copies + .checkbox {
  margin-top: -14px; }

/*
 * (-, - )…zzzZZZ
 */
.section-content > .checkbox ~ .ml25,
.section-content > .radio ~ .ml25,
.section-content > .ml25 ~ .checkbox,
.section-content > .ml25 ~ .radio {
  margin-top: -5px; }

.section > .section-title + p {
  margin-top: -5px; }
